// TODO: should be split into specific const files
export const MAX_RETRY_REFRESH_TOKEN_COUNT = 5;

export const SESSION_POLLING_INTERVAL_IN_MILLISECONDS = 30 * 1000;

export const DOMINANT_SPEAKER_DELAY = 1500;
export const DOMINANT_SPEAKER_THRESHOLD = 0.4;
export const MIN_DELAY_BETWEEN_SUBSCRIBE_TRIES_IN_MILLISECONDS = 1000;
export const MAX_DELAY_BETWEEN_SUBSCRIBE_TRIES_IN_MILLISECONDS = 5000;
export const TIME_TO_WAIT_BEFORE_SHOWING_LONG_TIME_INIT_PUBLISHER_MESSAGE = 7000;
export const TIME_TO_WAIT_BEFORE_SHOWING_LONG_TIME_INIT_VB_MESSAGE = 7000;
export const TIME_TO_WAIT_BEFORE_TRYING_TO_FIX_BLACK_STREAMS_AGAIN = 1500;
export const TIME_TO_WAIT_FOR_DEVICES_CHANGED_RECENTLY_TO_BE_SET = 1000;

export const LARGE_WHITEBOARD_SESSION_THRESHOLD = 20;

export const USER_MODES = {
  UNSET: 'unset',
  GUEST: 'guest',
  APP: 'app'
};

export const DEVICES_TYPES = {
  CAMERA: 'camera',
  MICROPHONE: 'microphone',
  SCREEN: 'screen'
};

export const OS_TYPES = Object.freeze({
  UNKNOWN: 'Unknown',
  WINDOWS: 'Windows',
  MAC: 'MacOS',
  UNIX: 'UNIX',
  LINUX: 'Linux',
  ANDROID: 'Android',
  IOS: 'iOS',
  IPADOS: 'iPadOS',
  MOBILE_UNKNOWN: 'Mobile-Unknown'
});

export const STREAM_TYPE = Object.freeze({
  AVATAR: 'avatar',
  CAMERA: 'camera',
  WEB_SCREENSHARE: 'screen',
  NATIVE_SCREENSHARE: 'custom'
});

export const PARTICIPANT_STATE_TYPES = Object.freeze({
  JOINED: 'Joined',
  JOINING: 'Joining',
  INVITED: 'Invited',
  LEFT: 'Left',
  DECLINED: 'Declined',
  KICKED: 'Kicked',
  ABOUT_TO_BE_KICKED: 'About To Be Kicked'
});

export const PUBLISHER_STATE = Object.freeze({
  NOT_INITIALIZED: 'Not Initialized',
  INITIALIZING: 'Initializing',
  FAILED: 'Failed',
  PUBLISHED: 'Published',
  UNPUBLISHED: 'Unpublished'
});

export const SUBSCRIBER_STATUS = Object.freeze({
  NOT_SUBSCRIBED: 'Not Subscribed',
  SUBSCRIBING: 'Subscribing',
  FAILED: 'Failed',
  SUBSCRIBED: 'Subscribed',
  RETRYING: 'Retrying'
});

export const LAYOUT_MODE_TYPES = Object.freeze({
  GRID: 'grid',
  SPEAKER: 'speaker',
  DOMINANT: 'dominant'
});

export const REACTION_OPTIONS_NEW_YEAR = Object.freeze({
  SPARKLES: {
    value: 'SPARKLES',
    path: '/gifs/sparkles.gif',
    allowFloatingAnimation: true
  },
  PARTY_POPPER: {
    value: 'PARTY_POPPER',
    path: '/gifs/party_popper.gif',
    allowFloatingAnimation: true
  },
  FIREWORKS: {
    value: 'FIREWORKS',
    path: '/gifs/fireworks.gif',
    allowFloatingAnimation: true
  },
  CLINKING_GLASSES: {
    value: 'CLINKING_GLASSES',
    path: '/gifs/clinking_glasses.gif',
    allowFloatingAnimation: true
  },
  BOTTLE_WITH_POPPING_CORK: {
    value: 'BOTTLE_WITH_POPPING_CORK',
    path: '/gifs/bottle_with_popping_cork.gif',
    allowFloatingAnimation: true
  }
});

export const REACTION_OPTIONS = Object.freeze({
  THUMBS_UP: {
    value: 'THUMBS_UP',
    path: '/gifs/thumbs_up.gif',
    allowFloatingAnimation: true,
    isAllowedInCounter: true
  },
  THUMBS_DOWN: {
    value: 'THUMBS_DOWN',
    path: '/gifs/thumbs_down.gif',
    allowFloatingAnimation: true,
    isAllowedInCounter: true
  },
  CLAP: {
    value: 'CLAP',
    path: '/gifs/clapping_hands.gif',
    allowFloatingAnimation: true,
    isAllowedInCounter: false
  },
  PEACE_HAND: {
    value: 'PEACE_HAND',
    path: '/gifs/peace_hand.png',
    allowFloatingAnimation: true,
    isAllowedInCounter: false
  },
  PARTY: {
    value: 'PARTY',
    path: '/gifs/partying_face.gif',
    allowFloatingAnimation: true,
    isAllowedInCounter: false
  },
  SMILE: {
    value: 'SMILE',
    path: '/gifs/rolling_on_the_floor_laughing.gif',
    allowFloatingAnimation: true,
    isAllowedInCounter: false
  },
  LOVE: {
    value: 'LOVE',
    path: '/gifs/smiling_face_with_heart_eyes.gif',
    allowFloatingAnimation: true,
    isAllowedInCounter: true
  },
  SHOCKED: {
    value: 'SHOCKED',
    path: '/gifs/face_screaming_in_fear.gif',
    allowFloatingAnimation: true,
    isAllowedInCounter: true
  },
  CRYING: {
    value: 'CRYING',
    path: '/gifs/loudly_crying_face.gif',
    allowFloatingAnimation: true,
    isAllowedInCounter: false
  },
  ALIEN: {
    value: 'ALIEN',
    path: '/gifs/alien.gif',
    allowFloatingAnimation: true,
    isAllowedInCounter: false
  }
});

export const REACTION_COUNTER_OPTIONS = Object.values(REACTION_OPTIONS).filter(
  (reaction) => reaction.isAllowedInCounter
);

// Change this according to the special date you have
export const ALL_REACTIONS_OPTIONS = {
  ...REACTION_OPTIONS_NEW_YEAR,
  ...REACTION_OPTIONS
};

export const COPIED_STATE_TIMEOUT = 2000;
export const MUTE_DEVICES_UNPUBLISH_DELAY = 90 * 1000;
export const NUMBER_OF_VIDEO_STREAMS_ALLOWED = 25;
export const NUMBER_OF_PUBLISHERS_ALLOWED = 50;
export const LARGE_MEETING_SIZE = 10;
export const NUMBER_OF_PARTICIPANTS_TO_CHANGE_TO_AUDIENCE = 25;
export const NUMBER_OF_PARTICIPANTS_FOR_PUBLISHERS_OPTIMIZATION = 40;

export const PUBLISHER_ERROR = Object.freeze({
  CHROME_MICROPHONE_ERROR: 'chromeMicrophoneAcquisitionError',
  SYSTEM_PERMISSIONS: 'systemPermissions',
  BROWSER_PERMISSIONS: 'browserPermissions',
  USED_BY_ANOTHER_PROCESS: 'usedByAnotherProcess',
  TIMEOUT: 'timeout',
  OTHER: 'other'
});

export const OT_ERROR = Object.freeze({
  HARDWARE_UNAVAILABLE: 'OT_HARDWARE_UNAVAILABLE',
  USER_MEDIA_ACCESS_DENIED: 'OT_USER_MEDIA_ACCESS_DENIED',
  CHROME_MICROPHONE_ACQUISITION_ERROR: 'OT_CHROME_MICROPHONE_ACQUISITION_ERROR',
  SCREEN_SHARING_NOT_SUPPORTED: 'OT_SCREEN_SHARING_NOT_SUPPORTED',
  NO_DEVICES_FOUND: 'OT_NO_DEVICES_FOUND',
  TIMEOUT: 'OT_TIMEOUT',
  UNABLE_TO_CAPTURE_MEDIA: 'OT_UNABLE_TO_CAPTURE_MEDIA',
  NOT_SUPPORTED: 'OT_NOT_SUPPORTED',
  NOT_CONNECTED: 'OT_NOT_CONNECTED'
});

export const MEDIA_ACCESS_STATUS = Object.freeze({
  UNKNOWN: 'unknown',
  NOT_DETERMINED: 'not-determined',
  GRANTED: 'granted',
  DENIED: 'denied',
  RESTRICTED: 'restricted'
});

export const SYSTEM_PRIVACY_PATH = {
  [OS_TYPES.WINDOWS]: 'Start > Settings > Privacy',
  [OS_TYPES.MAC]: 'System Preferences > Security & Privacy > Privacy'
};

export const SIDEBARS = Object.freeze({
  PARTICIPANTS: 'participants',
  CHAT: 'chat',
  INFO: 'info',
  QUESTIONS_AND_ANSWERS: 'qna',
  LIVE_TRANSCRIPTION: 'live_transcription'
});

export const ROOM_SERVICE_ERROR_MESSAGES = Object.freeze({
  SESSION_IS_LOCKED: 'sessionIsLocked',
  SESSION_IS_FULL: 'sessionIsFull',
  CANNOT_JOIN_BEFORE_HOST: 'cannotJoinBeforeHost',
  WAITING_ROOM_APPROVAL_REQUIRED: 'waitingRoomApprovalRequired',
  PARTICIPANT_IS_KICKED_FROM_SESSION: 'participantIsKickedFromSession',
  INVALID_TOKEN: 'invalidToken',
  DIFFERENT_ACCOUNT_ROOM: 'differentAccount',
  OTHER: 'other'
});

export const MUTE_FORCED_TYPE = Object.freeze({
  // session event type will be raised for all the participant if the owner muted all participant
  SESSION_MUTE: 'sessionMute',
  // publisher event type will for muted participant that had connected publisher
  // (either specific participant mute or mute all)
  PUBLISHER_MUTE: 'publisherMute'
});

export const JOIN_APPROVAL_LEVEL = Object.freeze({
  NONE: 'none',
  AFTER_OWNER_ONLY: 'after_owner_only',
  EXPLICIT_APPROVAL: 'explicit_approval'
});

export const TIMEOUTS = Object.freeze({
  GET_SESSION_REQUEST_TIMEOUT: 3000
});

export const DISPLAY_GUEST_NAME_MAX_LENGTH = 64;
export const EMAIL_MAX_LENGTH = 50;
export const ROOM_TOKEN_MAX_LENGTH = 30;

export const VONAGE_APP_GOOGLE_PLAY_STORE_URL = 'http://bit.ly/2ybkPxw';
export const VONAGE_APP_APP_STORE_URL = 'https://apple.co/2niIVD4';

// electron's feature flags
export const FEATURE_FLAGS = Object.freeze({
  PRESENTER_MODE: 'presenter_mode',
  WAITING_ROOM_IN_PRESENTER_MODE: 'waiting_room_in_presenter_mode'
});

export const CHECK_NETWORK_INTERVAL = 5000;

export const LOGGLY_URL_COMPONENTS = {
  BASE_URL: 'https://webil.loggly.com/search',
  SOURCE_GROUP: 'source_group=37525' //Meetings
};

export const INSPECTOR_URL_COMPONENTS = {
  BASE_URL: 'https://tokbox.com/developer/tools/inspector',
  ACCOUNT: '3580182',
  PROJECT: '46339892'
};

export const KIBANA_RS_URL_COMPONENTS = {
  BASE_URL:
    'https://search-vbc-centralized-logging-oftzxqlpixchq27gx7ly2hhn7u.us-east-1.es.amazonaws.com/_plugin/kibana/app/discover#/',
  FILTERS:
    "?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))&_a=(columns:!(_source),filters:!(),index:'2a722e50-593b-11ea-914c-9b41352fd0f9',interval:auto,",
  QUERY_START: 'query:(language:kuery,query:%22',
  QUERY_END: '%22),sort:!())'
};

export const APP_SIGNALS = Object.freeze({
  STANDUP: 'standup',
  WATCH_TOGETHER: 'watchTogether',
  REACTION: 'reaction',
  RAISE_HAND: 'raiseHandStatus',
  HIJACK_SCREEN_SHARE: 'hijackScreenShare',
  ACK_HIJACK_SCREEN_SHARE: 'ackHijackedScreenShare',
  BE_RIGHT_BACK: 'beRightBackStatus',
  QNA: 'qna',
  QNA_REFRESH: 'qnaRefresh',
  REACTIONS_COUNTER: {
    START: 'startReactionsCounter',
    STOP: 'stopReactionsCounter',
    RESET: 'resetReactionsCounter',
    UPDATE: 'updateReactionsCounter'
  }
});

export const WAITING_ROOM_PARTICIPANT_STATES = Object.freeze({
  APPROVED: 'approved',
  DENIED: 'denied',
  PENDING: 'pending'
});

export const ANALYTICS = Object.freeze({
  PARTICIPANT_INVITED: 'Meetings - Participants Invited',
  INFO_COPIED: 'Meetings - Info Copied',
  SELECT_COUNTRIES: 'Meetings - Select countries click',
  EXCLUSIVE_EXPERIMENTAL_MODE: 'Meetings - New Experimental Mode',
  REPORT_AN_ISSUE: 'Meetings - Report an Issue',
  WINDOW_SIZE_TOGGLED: 'Meetings - Window Size Toggled',
  PARTICIPANT_SOUND_INDICATION: 'Meetings - Participants Sound Indication',
  WAITING_ROOM_SOUND_INDICATION: 'Meetings - Waiting room Sound Indication',
  PINNED_PARTICIPANT: 'Meetings - Pinned participant',
  CAMERA_SETTINGS_UPDATED: 'Meetings - Meetings Camera Settings Updated',
  MICROPHONE_SETTINGS_UPDATED:
    'Meetings - Meetings Microphone Settings Updated',
  PREJOIN: 'Meetings - Pre-join',
  SETTINGS_UPDATED: 'Meetings - Settings Updated',
  OWNER_MUTE: 'Meetings - Owner Mute',
  KICKED_OUT_PARTICIPANT: 'Meetings - Kicked Out Participant',
  TURN_OFF_INCOMING_VIDEO: 'Meetings - Turn off incoming video',
  VOLUME_BAR: 'Meetings - Volume Bar',
  MAX_PARTICIPANT_ERROR: 'Meetings - Max participants error',
  VBC_USER_JOINING_AS_A_GUEST: 'Meetings - VBC User joining as a guest',
  MAX_VIDEOS_ERROR: 'Meetings - Max videos error',
  CHROME_ERROR: 'Meetings - Chrome Error',
  MIC_TOGGLED: 'Meetings - Mic Toggled',
  VIDEO_TOGGLED: 'Meetings - Video Toggled',
  LOCK: 'Meetings - Lock',
  LEAVE_FEEDBACK: 'Meetings - Leave feedback',
  RATE_US: 'Meetings - Rate Us',
  DEVICE_CHANGED: 'Meetings - device changed',
  THANK_YOU_SCREEN: 'Meetings - Thank you screen',
  MEETING_LAUNCHED: 'Meetings - Meeting Launched',
  SEND_HIJACK_SCREENSHARE_SIGNAL: 'Meetings - Send Hijack Screenshare signal',
  SEND_ACK_ON_HIJACK_SCREENSHARE_SIGNAL:
    'Meetings - Send Ack on Hijack Screenshare signal',
  BE_RIGHT_BACK_STATUS: 'Meetings - BRB - Be Right Back Status',
  I_AM_BACK_STATUS: 'Meetings - BRB - I am back Status',
  SIDEBAR_TOGGLED: 'Meetings - Sidebar Toggled',
  DISMISS_MUTE_INDICATION: 'Meetings - Dismiss Mute Indication',
  MESSAGE_SENT: 'Meetings - Message Sent',
  CAMERA_FLIPPED: 'Meetings - Camera flipped',
  RAISE_HAND: 'Meetings - Raise hand',
  REACTION_SENT: 'Meetings - Reaction sent',
  REACTIONS_COUNTER: 'Meetings - Reactions Counter Modal',
  RECORDING_TOGGLED: 'Meetings - Recording Toggled',
  VIRTUAL_BACKGROUND: 'Meetings - Virtual Background',
  SCREEN_SHARED: 'Meetings - Screen Shared',
  ROUND_TABLE: 'Meetings - Round Table',
  WATCH_TOGETHER: 'Meetings - Watch Together',
  WHITEBOARD_SHARED: 'Meetings - Whiteboard Shared',
  WHITEBOARD_TOOLBAR: 'Meetings - Whiteboard Toolbar',
  ALWAYS_OPEN_IN_APP: 'Meetings - always open in app',
  OPEN_MEETING_LINK: 'Meetings - Open meeting link',
  APP_DOWNLOAD: 'Meetings - App Download',
  INFO_SHARED: 'Meetings - Info shared',
  JOINED: 'Meetings - Joined',
  WAITING_ROOM_RESPONSES: 'Meetings - Waiting room responses',
  WAITING_ROOM_JOINER_FINISH: 'Meetings - Waiting room - Joiner finish',
  WAITING_ROOM_GENERAL_ACTIVATION: 'Meetings - waiting room general activation',
  WAITING_ROOM_SESSION_ACTIVATION: 'Meetings - waiting room session activation',
  WAITING_ROOM_DISABLE_NOTIFICATION:
    'Meetings - waiting room disable notification',
  PREVIEW_VIDEO: 'Meetings - Preview Video',
  LOCALIZATION: 'Meetings - Localization',
  QUESTIONS_AND_ANSWERS: 'Meetings - Q&A',
  VIRTUAL_BACKGROUND_LONG_TIME_ERROR:
    'Meetings - Virtual Background Long Time Error',
  VIRTUAL_BACKGROUND_NOT_SUPPORTED_ERROR:
    'Meetings - Virtual Background Not Supported Error',
  VIRTUAL_BACKGROUND_GENERAL_ERROR:
    'Meetings - Virtual Background General Error',
  REFERRAL_PROGRAM_PAGE_OPENED: 'Meetings - Referral Program Page Opened',
  REFERRAL_PROGRAM_READ_MORE_BUTTON_CLICKED:
    'Meetings - Referral Program Read More Button Clicked',
  KEYBOARD_SHORTCUTS: 'Meetings - Keyboard Shortcuts',
  END_OR_LEAVE_MEETING_MODAL: 'Meetings - End or leave meeting modal'
});

export const KEYBOARD_SHORTCUTS = Object.freeze({
  TOGGLE_CAMERA: 'Toggle Camera',
  TOGGLE_MICROPHONE: 'Toggle Microphone',
  TOGGLE_BE_RIGHT_BACK: 'Toggle BRB',
  TOGGLE_RAISE_HAND: 'Toggle Raise Hand',
  TOGGLE_SHARESCREEN: 'Toggle Sharescreen',
  HOST_MUTE_ALL: 'Host Mute All',
  TOGGLE_LEAVE_MEETING_MODAL: 'Toggle Leave Meeting Modal',
  PUSH_TO_TALK: 'Push To Talk',
  TOGGLE_PARTICIPANTS_TAB: 'Toggle Participants Tab',
  TOGGLE_CHAT_TAB: 'Toggle Chat Tab',
  TOGGLE_INFO_TAB: 'Toggle Info Tab',
  TOGGLE_FULL_SCREEN_MODE: 'Toggle Full Screen Mode',
  TOGGLE_RECORDINGS: 'Toggle Recordings',
  COPY_MEETINGS_INFO: 'Copy Meetings Info',
  SWITCH_LAYOUTS: 'Switch Layouts'
});

export const SETTING_ANALYTIC_TYPE = Object.freeze({
  MICROPHONE: 'Microphone',
  SPEAKER: 'Speaker',
  CAMERA: 'Camera'
});

export const ANALYTICS_SOURCE = Object.freeze({
  BOTTOM_BAR: 'Bottom Bar',
  PRE_JOIN: 'Pre-Join',
  SETTINGS_MODAL: 'Settings Modal',
  VIRTUAL_BACKGROUND_MODAL: 'Virtual Background Modal',
  INVITE_PARTICIPANT_MODAL: 'Invite Participants Modal',
  EXPERIMENTAL_INDICATION_POPUP: 'Experimental Indication Popup'
});

export const MESSAGE_SCREEN_REASON = Object.freeze({
  LOCKED_SESSION: 'locked_session',
  FULL_SESSION: 'full_session',
  CANNOT_JOIN_BEFORE_HOST: 'cannot_join_before_host',
  PARTICIPANT_KICKED: 'participant_kicked',
  SESSION_ALREADY_ENDED: 'session_already_ended',
  OPEN_SESSION_IN_NATIVE_APP: 'open_session_in_native_app'
});

export const WAITING_ROOM_ARTIFICIAL_TRANSITION_TIME_IN_MILLISECONDS = 3000;

export const INVITE_DISABLED_REASON = Object.freeze({
  LOCKED: 'locked',
  WAITING_ROOM_ENABLED: 'waiting room enabled'
});

export const SUPPORTED_LOCALES = Object.freeze({
  ENGLISH: 'en',
  HEBREW: 'he',
  SPANISH: 'es',
  PORTUGUESE: 'pt-BR',
  PORTUGUESE_PLACEHOLDER: 'pt',
  ITALIAN: 'it',
  CATALAN: 'ca',
  FRENCH: 'fr',
  GERMAN: 'de',
  ARABIC: 'ar',
  CHINESE_MAINLAND: 'zh-cn',
  CHINESE_TAIWAN: 'zh-tw'
});

export const VIRTUAL_BACKGROUND_TYPE = Object.freeze({
  NONE: 'none',
  BLUR: 'blur',
  IMAGE: 'image',
  UPLOADED: 'uploaded'
});

export const MESSAGE_SCREEN_ILLUSTRATIONS = Object.freeze({
  JOIN_BEFORE_HOST: '/illustrations/message-screen/img-host-missing.svg',
  MEETING_IS_LOCKED: '/illustrations/message-screen/img-locked.svg',
  MAX_CAPACITY_REACHED: '/illustrations/message-screen/img-max-capacity.svg',
  HOST_REMOVED_YOU:
    '/illustrations/message-screen/img-removed-from-meeting.svg',
  TECHNICAL_DIFFICULTIES:
    '/illustrations/message-screen/img-technical-difficulties.svg',
  MEETING_ENDED: '/illustrations/message-screen/img-meeting-ended.svg',
  NETWORK_ISSUES: '/illustrations/message-screen/img-network-issues.svg',
  OPEN_IN_APP: '/illustrations/message-screen/img-open-in-app.svg',
  OPEN_IN_APP_CSP: '/illustrations/message-screen/img-open-in-app-csp.svg'
});

export const LEAVE_FEEDBACK_TAGS_OPTIONS = Object.freeze({
  ISSUE: 'Issue',
  IDEA: 'Idea',
  COMMENT: 'Comment'
});

export const LEAVE_FEEDBACK_TYPES_OPTIONS = Object.freeze({
  REPORT_AN_ISSUE: 'Report an issue',
  SUGGEST_AN_IDEA: 'Suggest an idea',
  SEND_A_COMMENT: 'Send a comment'
});

export const LEAVE_FEEDBACK_LOCATIONS = Object.freeze({
  THANK_YOU_SCREEN: 'Thank you screen',
  LEAVE_FEEDBACK_MODAL: 'Leave feedback'
});

export const MAC_BUILT_IN_CAMERA_NAME = 'FaceTime HD Camera';

export const CONNECTION_STATUS = Object.freeze({
  ONLINE: 'online',
  OFFLINE: 'offline'
});

export const TOGGLE_MIC_SOURCES = Object.freeze({
  BOTTOM_BAR: 'bottom bar',
  PARTICIPANTS_TAB: 'participants tab',
  KEYBOARD_SHORTCUT: 'keyboard shortcut',
  PTT: 'push to talk',
  PRESENTER_MODE: 'presenter mode',
  TOUCHE_BAR: 'touch bar',
  MOBILE_WEB_CARD: 'mobile web card'
});

export const TOGGLE_CAMERA_SOURCES = Object.freeze({
  BOTTOM_BAR: 'bottom bar',
  PARTICIPANTS_TAB: 'participants tab',
  KEYBOARD_SHORTCUT: 'keyboard shortcut',
  PRESENTER_MODE: 'presenter mode',
  TOUCHE_BAR: 'touch bar',
  MOBILE_WEB_CARD: 'mobile web card'
});
