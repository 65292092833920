import store from '@/store';
import * as tokbox from '@/apis/opentok-api';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import throttle from 'lodash.throttle';
import debounce from 'lodash.debounce';
import { sort } from 'timsort';
import {
  ANALYTICS,
  APP_SIGNALS,
  DEVICES_TYPES,
  DOMINANT_SPEAKER_DELAY,
  DOMINANT_SPEAKER_THRESHOLD,
  LARGE_MEETING_SIZE,
  LAYOUT_MODE_TYPES,
  MAX_DELAY_BETWEEN_SUBSCRIBE_TRIES_IN_MILLISECONDS,
  MEDIA_ACCESS_STATUS,
  MESSAGE_SCREEN_ILLUSTRATIONS,
  MESSAGE_SCREEN_REASON,
  MIN_DELAY_BETWEEN_SUBSCRIBE_TRIES_IN_MILLISECONDS,
  MUTE_FORCED_TYPE,
  NUMBER_OF_PARTICIPANTS_FOR_PUBLISHERS_OPTIMIZATION,
  NUMBER_OF_PUBLISHERS_ALLOWED,
  NUMBER_OF_VIDEO_STREAMS_ALLOWED,
  OS_TYPES,
  OT_ERROR,
  PARTICIPANT_STATE_TYPES,
  PUBLISHER_ERROR,
  PUBLISHER_STATE,
  ROOM_SERVICE_ERROR_MESSAGES,
  SESSION_POLLING_INTERVAL_IN_MILLISECONDS,
  SUBSCRIBER_STATUS,
  SYSTEM_PRIVACY_PATH,
  TIME_TO_WAIT_BEFORE_SHOWING_LONG_TIME_INIT_PUBLISHER_MESSAGE,
  TIMEOUTS,
  USER_MODES,
  VIRTUAL_BACKGROUND_TYPE,
  WAITING_ROOM_ARTIFICIAL_TRANSITION_TIME_IN_MILLISECONDS,
  LEAVE_FEEDBACK_LOCATIONS,
  MAC_BUILT_IN_CAMERA_NAME,
  TIME_TO_WAIT_BEFORE_SHOWING_LONG_TIME_INIT_VB_MESSAGE,
  TIME_TO_WAIT_BEFORE_TRYING_TO_FIX_BLACK_STREAMS_AGAIN,
  TOGGLE_MIC_SOURCES
} from '@/consts/global-consts';
import { HOST_OUTBOUND_EVENTS } from '@/store/embedded/consts';
import { ALPHA_PREFIX } from '@/consts/capabilities';
import * as utils from '@/helpers/meeting-helpers';
import * as vbcGw from '@/apis/vbc-gw';
import TelemetriesHandler from '@/services/telemetries-service';
import {
  compareVersions,
  isElectron,
  isMinimalNativeVersion,
  isMobileDevice,
  OS,
  wait
} from '@/helpers/global-helpers';
import {
  getDevices,
  getPermissions,
  hasDevicesNames
} from '@/helpers/detectrtc';
import { versionSteps } from '@/helpers/tour-steps';
import * as usersApi from '@/apis/users-api';
import * as roomService from '@/apis/room-service-api';
import * as roomServicePublic from '@/apis/room-service-public-api';
import MeetingManager from '@/services/meeting-manager-service';
import analytics from '@/services/analytics-service';
import SoundsPlayer from '@/services/sounds-player';
import router from '@/router';
import AuthClient from '@/services/auth-client-service';
import * as SplitAudioDetector from '@/services/split-audio-detector';
import StreamsStats from '@/services/streams-stats-service';
import * as cachingService from '@/services/caching-service';
import featureFlagService from '@/services/native-feature-flags';
import { stopPreventingMobileLock } from '@/helpers/mobile-utils';
import { LONG_TOAST_DURATION, TOAST_TYPE } from '@/consts/mobile-consts';
import { createHash } from 'crypto-browserify';
import { sessionStorageService } from '@/services/storage-service';
import { localStorageService } from '@/services/storage-service';
import i18n from '@/i18n';
import {
  getChangedSelectedDevicesTypes,
  getDefaultDevice,
  isDefaultDeviceId,
  isSelectedDeviceRemoved
} from '@/helpers/devices-utils';
import soundsPlayer from '@/services/sounds-player';

let sessionPollingInterval;

let lastDominantSpeakerUpdate = 0;

let publisherStatsInterval = 0;
let subscriberStatsInterval = 0;
let publisherAudioTimer = null;
let virtualBackgroundTimeout = null;
// TODO: move to consts file
const STATS_FRAME_SIZE_MS = 240;
const AUDIO_LEVEL_UI_UPDATE_MS = 60;
const AUDIO_LEVEL_UI_ITERATIONS =
  STATS_FRAME_SIZE_MS / AUDIO_LEVEL_UI_UPDATE_MS;
const SEND_STATS_INTERVAL = Math.floor((1000 * 10) / STATS_FRAME_SIZE_MS + 0.5);
const PUBLISHER_AUDIO_PACKET_LOSS_THRESHOLD = 0.03;
const MAX_AUDIO_PACKET_LOSS_FRAMES = 3;
const MAX_TIME_BETWEEN_AUDIO_PACKET_LOSS_FRAMES = 60 * 1000;
const AUDIO_THROTTLE_INTERVAL = 120;
const MIC_VOLUME_MIN_THRESHOLD = 0.1;
const electronWebapp = isElectron() ? window.Electron.webApp : {};
const MAX_TIME_PARTICIPANTS_LOADING = 30 * 1000;

export default {
  preInitApp: async ({ state, commit, dispatch, getters }, initData) => {
    if (state.isAppPreInitialized) {
      return;
    }
    commit('SET_IS_EMBEDDED', window.top !== window.self);
    commit('SET_IS_LOADING_PRE_INIT_DATA', true);
    commit('SET_IS_LOADING_PRE_ENTRANCE_DATA', true);
    if (state.isEmbedded) {
      sessionStorageService.disable();
      localStorageService.disable();
    } else {
      const locale = sessionStorageService.getItem('locale');
      if (locale) {
        i18n.locale = locale;
      }
      AuthClient.init();
    }
    await dispatch('processInitData', initData);
    await dispatch('whitelabel/init');
    await dispatch('registerDocumentVisibilityChanges');

    if (getters.roomToken) {
      try {
        await dispatch('getGuestRoomDetails');
      } catch (error) {
        logger.log('Failed to fetch guest room details', {
          roomToken: getters.roomToken
        });
      }

      // We already fetched guest room details once, we don't want it to happen again before we gets to entrance screen
      commit('SET_IS_GUEST_ROOM_DETAILS_FETCH_NEEDED', false);
    }
    // We don't have a valid room token yet so we try to get theme by URL
    if (initData?.theme_url && !state.isBranded && !state.hasValidRoomDetails) {
      await dispatch('getThemeByUrl', initData.theme_url);
    }
    commit('SET_IS_APP_PRE_INITALIZED', true);
    commit('SET_IS_LOADING_PRE_ENTRANCE_DATA', false);
    commit('SET_IS_LOADING_PRE_INIT_DATA', false);
  },

  initApp: async ({ state, dispatch, commit, getters }, initData) => {
    if (location.pathname.length > 1 || location.search.length) {
      if (isMobileDevice && router.currentRoute.name !== 'MobileHome') {
        dispatch('replaceRoute', {
          name: 'MobileHome'
        });
      } else if (!isMobileDevice) {
        dispatch('replaceRoute', {
          name: !getters.isMobileWebMode ? 'Home' : 'MobileHome'
        });
      }
    }

    if (sessionStorageService.getItem('is_branded') === 'true') {
      commit('SET_IS_BRANDED', true);
    }

    // when we change route from global-message back to home, make sure initialization won't run again
    if (state.isAppInitialized) {
      return;
    }

    commit('SET_IS_EMBEDDED', window.top !== window.self);
    if (state.isEmbedded) {
      sessionStorageService.disable();
      localStorageService.disable();
      dispatch('embedded/init');
    }

    // electron feature flags
    if (isElectron()) {
      featureFlagService.initNativeFeatureFlags({
        isProduction: window.isProduction
      });
    }
    await dispatch('processInitData', initData);

    // select guest or app mode
    const accessToken = await AuthClient.retrieve('accessToken');
    const isGuest =
      !accessToken || sessionStorageService.getItem('guest_access_token');
    if (isGuest) {
      await dispatch('initGuestMode');
      if (state.initialJoinConfig.displayName) {
        await dispatch('setGuestUserInfo', {
          displayName: state.initialJoinConfig.displayName
        });
      }
    } else {
      commit('SET_IS_LOADING_PRE_ENTRANCE_DATA', true); // when we init the app for appUser we do some pre-loading
      await dispatch('initAppUserMode');
    }

    await dispatch('settings/readSettings');
    dispatch('setupUserDevices');

    dispatch('initAnalytics');
    dispatch('initAppcues');

    const hasParticipantInfo =
      !getters.isGuest ||
      sessionStorageService.getItem('guest_init_done') === 'true' ||
      state.initialJoinConfig.displayName;
    const participantShouldNotAddEmail =
      !isGuest ||
      !state.isEmailRequired ||
      sessionStorageService.getItem('guest_init_done') === 'true';

    if (
      (getters.roomToken || state.sessionId) &&
      hasParticipantInfo &&
      participantShouldNotAddEmail
    ) {
      dispatch('validateRoom').then(() => {
        commit('SET_IS_LOADING_PRE_ENTRANCE_DATA', false);
      });
    } else {
      dispatch('showEntranceScreen'); // SET_IS_LOADING_PRE_ENTRANCE_DATA is set to false in this function
    }

    const themeUrl = sessionStorageService.getItem('theme_url');
    // Try to fetch theme by URL only if this it the way we get the theme before
    if (sessionStorageService.getItem('is_branded') === 'true' && themeUrl) {
      await dispatch('getThemeByUrl', themeUrl);
    }

    commit('SET_IS_APP_INITIALIZED', true);
  },

  processInitData: async (
    { commit, dispatch },
    {
      room_token,
      session_id,
      invitees,
      offnet_invite,
      participant_token,
      color,
      display_name,
      user_display_name,
      skip_prejoin,
      quiet_mode,
      layout,
      blur_on,
      background_disabled
    }
  ) => {
    const roomToken = room_token || sessionStorageService.getItem('room_token');
    if (roomToken) {
      await dispatch('setRoomToken', roomToken);
    }

    if (session_id) {
      commit('SET_SESSION_ID', session_id);
    }

    if (layout) {
      dispatch('setLayoutMode', layout.toLowerCase());
      dispatch('layout/setUserPreferredLayout', {
        layoutMode: layout.toLowerCase()
      });
    }

    if (invitees?.length) {
      commit('SET_INITIAL_INVITEES', invitees.split(','));
    }

    if (offnet_invite) {
      const offnetInvites = JSON.parse(offnet_invite);
      offnetInvites.to = offnetInvites.to.split(',');
      commit('SET_OFFNET_INVITE_DATA', offnetInvites);
    }

    if (participant_token) {
      commit('SET_PARTICIPANT_TOKEN', participant_token);
    }

    if (
      user_display_name ||
      display_name ||
      skip_prejoin ||
      blur_on ||
      background_disabled
    ) {
      commit('UPDATE_INITIAL_JOIN_CONFIG', {
        displayName: user_display_name || display_name,
        skipPreJoin: skip_prejoin === 'true',
        blurOn: blur_on === 'true',
        backgroundDisabled: background_disabled === 'true'
      });
      if (background_disabled === 'true') {
        sessionStorageService.setItem('background_disabled', true);
      }
    }

    if (!window.isProduction && color) {
      commit('whitelabel/SET_DEBUGGING_COLOR_FROM_QUERY_STRING', color);
      commit('SET_IS_BRANDED', true);
      await dispatch('whitelabel/setColor', color);
    }

    // Comparing to undefined to make sure quiet_mode is enabled even when it is not given a value (i.e. app.com/?quiet_mode should work)
    if (quiet_mode !== undefined) {
      console.warn(
        'quiet_mode is enabled. Muting the SoundsPlayer to prevent all sounds from playing'
      );
      soundsPlayer.muteAllSounds();
    }
  },

  setLayoutMode: ({ dispatch }, layout) => {
    if (layout === 'gallery') {
      dispatch('layout/setLayoutMode', {
        layoutMode: LAYOUT_MODE_TYPES.GRID
      });
    } else if (layout === 'audience') {
      dispatch('layout/setLayoutMode', {
        layoutMode: LAYOUT_MODE_TYPES.SPEAKER
      });
    } else if (layout === 'dominant') {
      dispatch('layout/setLayoutMode', {
        layoutMode: LAYOUT_MODE_TYPES.DOMINANT
      });
    }
  },

  initAppUserMode: async ({ state, getters, commit, dispatch }) => {
    commit('SET_APP_USER_MODE');
    logger.updateContext({
      isGuest: false
    });

    const accessToken = await AuthClient.retrieve('accessToken');
    const refreshToken = await AuthClient.retrieve('refreshToken');
    const externalId = await AuthClient.retrieve('externalId');
    const extension = await AuthClient.retrieve('extension');
    vbcGw.init({
      accessToken,
      refreshToken,
      externalId,
      extension
    });

    // we use it later to get my participant from the contacts list (to get profile image for PreJoin)
    const credentials = vbcGw.getCredentials();
    commit('SET_MY_PARTICIPANT_ID', credentials.externalId);

    if (!state.userInfo.userId || !state.userInfo.accountId) {
      try {
        const userInfo = await usersApi.readUserInfo();
        commit('SET_VBC_USER_INFO', userInfo);
      } catch (e) {
        // might be either because the session is in other account or maybe there's an authentication error (wrong credentials)
        return dispatch('initGuestMode');
      }
    }

    await cachingService.init(credentials.externalId);
    const extensionLocale = await AuthClient.retrieve('extensionLocale');
    commit('SET_EXTENSION_LOCALE', extensionLocale);
    logger.updateContext({
      accountId: state.userInfo.accountId.toString(),
      userId: state.userInfo.userId.toString(),
      username: state.userInfo.loginName,
      extension: credentials.extension,
      externalId: credentials.externalId,
      extensionLocale: state.extensionLocale,
      displayName: getters.userDisplayName
    });

    dispatch('readContacts');
  },

  initGuestMode: async ({ state, commit, dispatch }) => {
    if (state.userMode !== USER_MODES.UNSET) {
      logger.init();
      vbcGw.reset();
    }

    commit('SET_GUEST_USER_MODE');

    logger.updateContext({
      isGuest: true
    });

    cachingService.resetUser();
    await dispatch('clearContacts');
  },

  registerDocumentVisibilityChanges: ({ commit, dispatch }) => {
    commit('SET_DOCUMENT_VISIBILITY_STATE', document.visibilityState);

    document.addEventListener('visibilitychange', () => {
      commit('SET_DOCUMENT_VISIBILITY_STATE', document.visibilityState);

      // There is a bug in Safari (both desktop and mobile versions) - when the browser is unfocused for some time,
      // when it gains focus back, the video streams can become black.
      // We call rebindAllVideos to fix this issue
      if (document.visibilityState === 'visible' && window.isSafari) {
        dispatch('rebindAllVideos');

        // We add another call for rebindAllVideos since it does not always work on the first try
        setTimeout(() => {
          dispatch('rebindAllVideos');
        }, TIME_TO_WAIT_BEFORE_TRYING_TO_FIX_BLACK_STREAMS_AGAIN);
      }
    });
  },

  getThemeByUrl: async ({ state, commit, dispatch }, themeUrl) => {
    try {
      const theme = await roomServicePublic.getThemeByUrl(themeUrl);
      commit('SET_IS_BRANDED', true);
      await dispatch('whitelabel/setTheme', theme);
      sessionStorageService.setItem('is_branded', state.isBranded);
      sessionStorageService.setItem('theme_url', themeUrl);
    } catch (error) {
      await dispatch('whitelabel/setThemeUrlInLocationBar');
      logger.log('Failed to fetch theme by URL', {
        theme_url: themeUrl
      });
    }
  },

  getRoomDetailsBySessionId: async ({ state }) => {
    const roomDetails = await roomService.getRoomDetailsBySessionId(
      state.sessionId
    );
    const dialInNumbers = await roomService.getDialInNumbers(
      roomDetails.domain
    );

    return { ...roomDetails, dial_in_numbers: dialInNumbers };
  },

  getGuestRoomDetails: async ({ state, commit, getters, dispatch }) => {
    try {
      const roomDetails = await roomServicePublic.getGuestRoomDetails(
        getters.roomToken,
        true
      );

      dispatch('setRoomDetails', {
        name: roomDetails.name,
        roomToken: roomDetails.guest_token,
        displayName: roomDetails.display_name,
        pinCode: roomDetails.pin_code,
        domain: roomDetails.domain,
        dialInNumbers: roomDetails.dial_in_numbers,
        roomOwner: roomDetails.owner,
        isMobileWebSupported: roomDetails.is_mobile_web_supported,
        isAutoRecorded: roomDetails.auto_record,
        isInitialJoinOptionsMicrophoneState:
          roomDetails.initial_join_options?.microphone_state,
        allowUsersToRecord: roomDetails.allow_users_to_record,
        joinApprovalLevel: roomDetails.join_approval_level,
        autoRecord: roomDetails.auto_record,
        recordOnlyOwner: roomDetails.record_only_owner,
        availableFeatures: {
          isRecordingAvailable:
            roomDetails.available_features?.is_recording_available ||
            !!roomDetails.is_recording_available,
          isChatAvailable:
            roomDetails.available_features?.is_chat_available ||
            !!roomDetails.is_chat_available,
          isWhiteboardAvailable:
            roomDetails.available_features?.is_whiteboard_available ||
            !!roomDetails.is_whiteboard_available,
          isLocaleSwitcherAvailable:
            roomDetails.available_features?.is_locale_switcher_available ||
            !!roomDetails.is_locale_switcher_available,
          isCaptionsAvailable:
            roomDetails.available_features?.is_captions_available ||
            !!roomDetails.is_captions_available
        }
      });

      if (roomDetails.additional_attributes) {
        sessionStorageService.removeItem('theme_url');
        if (
          !roomDetails.additional_attributes.room_theme &&
          !state.whitelabel.debuggingColorFromQueryString
        ) {
          commit('SET_IS_BRANDED', false);
          await dispatch('whitelabel/resetTheme');
        } else {
          let debuggingTheme = null;
          if (
            state.whitelabel.debuggingColorFromQueryString &&
            !roomDetails.additional_attributes.room_theme
          ) {
            debuggingTheme = {
              main_color: state.whitelabel.debuggingColorFromQueryString,
              brand_text: 'Debug',
              short_company_url: 'debug'
            };
          }

          commit('SET_IS_BRANDED', true);
          await dispatch(
            'whitelabel/setTheme',
            roomDetails.additional_attributes.room_theme || debuggingTheme
          );
          await dispatch('whitelabel/setThemeUrlInLocationBar');
        }

        dispatch('localization/setDefaultLocale', {
          locale: roomDetails.additional_attributes.ui_settings?.language
        });

        if (roomDetails.additional_attributes.is_email_required) {
          commit('SET_IS_EMAIL_REQUIRED', true);
        }
      }

      sessionStorageService.setItem('is_branded', state.isBranded);
      commit('SET_HAS_VALID_ROOM_DETAILS', true);

      return roomDetails;
    } catch (error) {
      const roomError = utils.getRoomDetailsErrorType(error);
      throw new Error(roomError);
    }
  },

  getRoomDetails: async ({ state, getters, dispatch }) => {
    let roomError;
    let roomDetails;

    if (getters.roomToken) {
      roomDetails = await dispatch('getGuestRoomDetails');
    } else if (getters.isGuest) {
      // We are not supposed to get here, guests can't get room details without having room token
      throw new Error(ROOM_SERVICE_ERROR_MESSAGES.OTHER);
    }

    try {
      if (!getters.isGuest) {
        // These 2 roomService calls return the same result, we prefer to get room details by session-id only when we don't have room token as this function require 2 roomService
        // requests (one for room details and one for dial-in numbers), when we have room token we'll already get the dial-in numbers from the getGuestRoomDetails call
        roomDetails = await (state.sessionId && !getters.roomToken
          ? dispatch('getRoomDetailsBySessionId')
          : roomService.getRoomDetailsByRoomToken(getters.roomToken));
        dispatch('setRoomDetails', {
          name: roomDetails.name,
          roomToken: roomDetails.guest_token,
          displayName: roomDetails.display_name,
          pinCode: roomDetails.pin_code,
          domain: roomDetails.domain,
          dialInNumbers:
            roomDetails.dial_in_numbers || state.roomDetails.dialInNumbers,
          roomOwner: roomDetails.owner,
          isMobileWebSupported: roomDetails.is_mobile_web_supported,
          isAutoRecorded: roomDetails.auto_record,
          isInitialJoinOptionsMicrophoneState:
            roomDetails.initial_join_options?.microphone_state,
          allowUsersToRecord: roomDetails.allow_users_to_record,
          joinApprovalLevel: roomDetails.join_approval_level,
          type: roomDetails.type,
          autoRecord: roomDetails.auto_record,
          recordOnlyOwner: roomDetails.record_only_owner,
          availableFeatures: {
            isRecordingAvailable:
              roomDetails.available_features?.is_recording_available ||
              !!roomDetails.is_recording_available,
            isChatAvailable:
              roomDetails.available_features?.is_chat_available ||
              !!roomDetails.is_chat_available,
            isWhiteboardAvailable:
              roomDetails.available_features?.is_whiteboard_available ||
              !!roomDetails.is_whiteboard_available,
            isLocaleSwitcherAvailable:
              roomDetails.available_features?.is_locale_switcher_available ||
              !!roomDetails.is_locale_switcher_available,
            isCaptionsAvailable:
              roomDetails.available_features?.is_captions_available ||
              !!roomDetails.is_captions_available
          }
        });
      }

      // If the page was refreshed and we were part of the meeting already, we don't want to check the room's status
      if (!getters.isMeetingAlreadyInitialized) {
        roomError = utils.getRoomStatusError(
          roomDetails,
          getters.isGuest,
          state?.userInfo?.accountId
        );
      }
    } catch (error) {
      roomError = utils.getRoomDetailsErrorType(error);
    }

    if (roomError) {
      throw new Error(roomError);
    }
  },

  validateRoom: async ({ dispatch }) => {
    try {
      await dispatch('getRoomDetails');
      await dispatch('resetGlobalMessage');
      dispatch('showPreJoinScreen');
    } catch (err) {
      dispatch('handleRoomServiceError', {
        meetingError: err.message,
        retryCallback: async () => {
          await dispatch('validateRoom');
        }
        // Ronny: if guest we used to redirect him to entrance_screen, not sure why we needed the special handling
      });
    }
  },

  showEntranceScreen: async ({ getters, commit, state, dispatch }) => {
    if (
      getters.roomToken &&
      getters.roomDisplayName === '' &&
      state.isGuestRoomDetailsFetchNeeded
    ) {
      commit('SET_IS_LOADING_PRE_ENTRANCE_DATA', true);
      try {
        const roomDetails = await roomServicePublic.getGuestRoomDetails(
          getters.roomToken
        );
        commit('UPDATE_ROOM_DETAILS', {
          displayName: roomDetails.display_name
        });
        dispatch('localization/setDefaultLocale', {
          locale: roomDetails.additional_attributes?.ui_settings?.language
        });
      } catch (err) {
        // do nothing
      }
    }

    commit('SET_IS_GUEST_ROOM_DETAILS_FETCH_NEEDED', true);
    commit('SET_IS_LOADING_PRE_ENTRANCE_DATA', false);
    commit('SET_SHOW_ENTRANCE_SCREEN', true);
  },

  showPreJoinScreen: async ({ state, getters, commit, dispatch }) => {
    commit('SET_IS_LOADING_PRE_ENTRANCE_DATA', false);
    commit('SET_SHOW_ENTRANCE_SCREEN', false);
    await dispatch('applyInitialRoomJoinSettings');

    if (
      !state.settings.shouldShowPreJoinScreen ||
      state.initialJoinConfig.skipPreJoin ||
      getters.isMeetingAlreadyInitialized ||
      getters.isMobileWebMode
    ) {
      dispatch('joinMeeting');
      setTimeout(
        () =>
          dispatch(
            'waitingRoom/setHasFinishedArtificialTransitionToWaitingRoom',
            { hasFinishedArtificalTransitionToWaitingRoom: true }
          ),
        WAITING_ROOM_ARTIFICIAL_TRANSITION_TIME_IN_MILLISECONDS
      );
    } else {
      commit('SET_SHOW_PRE_JOIN_SCREEN', true);
    }
  },

  joinMeeting: ({ getters, dispatch }) => {
    if (getters.isGuest) {
      return dispatch('joinAsGuest');
    } else {
      return dispatch('joinAsAppUser');
    }
  },

  joinAsAppUser: async ({ state, getters, commit, dispatch }) => {
    let sessionId = sessionStorageService.getItem('session_id');

    // this code will run only on the first time entering a meeting - it will not run on a refresh
    if (!sessionId) {
      // TODO: we get session data twice, in here to create session if one doesnt exist and get sessionId by roomToken
      // and the next one to sync session data while solving race conditions with initializing the bus before making the api call
      try {
        const session = await roomService.getOrCreateSessionByRoomToken(
          vbcGw.getCredentials().externalId,
          getters.roomToken
        );
        sessionId = session.sessionId;
        dispatch('setSessionAnalytics');
        dispatch('trackMeetingLaunchedAnalyticEvent', sessionId);
      } catch (err) {
        return dispatch('handleRoomServiceError', {
          meetingError: utils.getSessionErrorType(err),
          retryCallback: () => dispatch('joinMeeting')
        });
      }
    }
    commit('SET_SESSION_ID', sessionId);
    commit('SET_IS_SESSION_INITIALIZED', true);
    logger.updateContext({
      sessionId,
      roomDisplayName: getters.roomDisplayName,
      domain: state.roomDetails.domain
    });

    if (state.initialInvitees.length > 0) {
      dispatch('inviteContacts', state.initialInvitees);
    }

    return dispatch('initMeeting', sessionId);
  },

  joinAsGuest: async ({ state, commit, getters, dispatch }) => {
    let sessionId;
    let vbcAccessToken;
    let guestId;
    let accountId;
    let displayName =
      state.userInfo.loginName || state.initialJoinConfig.displayName;

    if (!sessionStorageService.getItem('guest_init_done')) {
      let response;
      try {
        response = await roomServicePublic.joinAsGuest(
          getters.roomToken,
          displayName,
          state.participantToken,
          state.userInfo.email
        );
      } catch (err) {
        return dispatch('handleRoomServiceError', {
          meetingError: utils.getSessionErrorType(err),
          retryCallback: () => dispatch('joinMeeting')
        });
      }

      localStorageService.setItem('guestDisplayName', displayName);

      sessionId = response.sessionId;
      vbcAccessToken = response.vbcAccessToken;
      guestId = response.guestId;
      accountId = response.accountId;

      sessionStorageService.setItem('guest_access_token', vbcAccessToken);
      sessionStorageService.setItem('account_id', accountId);
      sessionStorageService.setItem('external_id', guestId);
      sessionStorageService.setItem('extension', guestId);
      sessionStorageService.setItem('guest_display_name', displayName);
      sessionStorageService.setItem('guest_init_done', true);

      dispatch('setSessionAnalytics');
      dispatch('trackMeetingLaunchedAnalyticEvent', sessionId);
    } else {
      guestId = sessionStorageService.getItem('external_id');
      sessionId = sessionStorageService.getItem('session_id');
      vbcAccessToken = sessionStorageService.getItem('guest_access_token');
      accountId = sessionStorageService.getItem('account_id');
      displayName = sessionStorageService.getItem('guest_display_name');
    }

    commit('SET_SESSION_ID', sessionId);
    commit('SET_IS_SESSION_INITIALIZED', true);
    vbcGw.init({
      accessToken: vbcAccessToken,
      externalId: guestId,
      extension: guestId
    });

    dispatch('setGuestUserInfo', { accountId, guestId, displayName });

    logger.updateContext({
      accountId: accountId.toString(),
      guestId,
      roomDisplayName: getters.roomDisplayName,
      sessionId,
      displayName,
      domain: state.roomDetails.domain
    });

    return dispatch('initMeeting', sessionId);
  },

  initMeeting: async ({ state, getters, commit, dispatch }, sessionId) => {
    commit('SET_SHOW_PRE_JOIN_SCREEN', false);
    commit('SET_IS_JOINING_A_SESSION', true);
    commit('SET_IS_LOADING_PRE_ENTRANCE_DATA', false);
    let session;
    // used to handle page refreshing
    sessionStorageService.setItem('session_id', sessionId);
    await dispatch('initBus', { domain: state.roomDetails.domain });

    try {
      session = await roomService.getSessionById(
        sessionId,
        null,
        'active_whiteboard'
      );
    } catch (err) {
      return dispatch('handleRoomServiceError', {
        meetingError: utils.getSessionErrorType(err),
        retryCallback: () => dispatch('joinMeeting')
      });
    }

    dispatch('setSessionAnalytics');
    const isActive = !!(session.start_time && !session.end_time);

    if (!isActive) {
      dispatch('leaveSession', { endForAll: false, closeWindow: false });
      return dispatch('showMeetingHasAlreadyEndedMessage');
    }

    const apiKey = session.api_key || process.env.VUE_APP_API_KEY;
    MeetingManager.initSession(apiKey, sessionId);

    if (session.capabilities) {
      // Capabilities prefixed with "alpha-" are capabilities that should only work for Alpha users
      const capabilities = window.isProduction
        ? session.capabilities.filter(
            (capability) => !capability.startsWith(ALPHA_PREFIX)
          )
        : session.capabilities.map((capability) =>
            capability.replace(ALPHA_PREFIX, '')
          );
      commit('SET_CAPABILITIES', [...new Set(capabilities)]);
    }

    dispatch('messaging/init', {
      groupId: session.messaging_id,
      startTime: session.start_time,
      domain: state.roomDetails.domain
    });
    dispatch('updateSession', session);
    dispatch('enableBus');
    dispatch('initSessionPolling');
    dispatch('registerGlobalHandlers');
    dispatch('registerMainProcessEventHandlers');
    dispatch('screenshare/setScreenshareCapability');
    dispatch('initPublisherOptimizer');
    dispatch('initAppReadyWatcher');
    dispatch('updateParticipantVolumeInterval');
    dispatch('initMicAndCameraState');

    SplitAudioDetector.onSplitAudioAlert((streamId) =>
      dispatch('fixSplitAudioStream', streamId)
    );

    if (!state.isNoAudioMode) {
      SplitAudioDetector.initSplitAudioDetector();
    }

    dispatch('subscribeSessionEvents');
    if (getters.isMobileWebMode) {
      dispatch('mobile/init');
    } else {
      // Those apps are not supported on Mobile Web just yet
      dispatch('reactions/init');
      dispatch('standup/init');
      dispatch('watchTogether/ongoing/init');
    }
    dispatch('beRightBack/init');
    dispatch('raiseHand/init');
    dispatch('reactionsCounter/init');
    dispatch('whiteboard/init');
    commit('SET_SHOW_ENTRANCE_SCREEN', false);
    dispatch('initLayout');
    dispatch('qna/init');

    try {
      await dispatch('connectToSession', state.sessionId);
      if (state.offnetInviteData) {
        dispatch('inviteOffnetParticipant');
      }
    } catch (err) {
      return dispatch('showInitMeetingFailedMessage', {
        retryCallback: () => dispatch('reconnectToSession')
      });
    }
    // start a timer when session is connected and check how much time it took participants tab to load
    dispatch('initParticipantsLoadTimeAlertTimer');

    // init waiting room only after session connected
    dispatch('waitingRoom/init');

    try {
      session = await roomService.getSessionById(
        sessionId,
        null,
        'active_whiteboard'
      );
      dispatch('updateSession', session);
    } catch (err) {
      logger.warning('initMeeting', LOG_CATEGORIES.CLIENT_LOGIC, {
        message: 'Failed to get session data'
      });
    }

    MeetingManager.onSignal(APP_SIGNALS.HIJACK_SCREEN_SHARE, (event) => {
      dispatch('handleScreenshareHijackedSignal', event);
    });

    MeetingManager.onSignal(APP_SIGNALS.ACK_HIJACK_SCREEN_SHARE, () => {
      if (!state.didSendHijackScreenShare) {
        return;
      }
      dispatch('screenshare/toggleScreenshare');
      commit('SET_DID_SEND_HIJACK_SCREENSHARE', false);
    });
  },

  handleRoomServiceError: async (
    { dispatch },
    { meetingError, retryCallback = null, defaultCallback = null }
  ) => {
    let errorReason = 'other';
    switch (meetingError) {
      case ROOM_SERVICE_ERROR_MESSAGES.SESSION_IS_LOCKED:
        errorReason = 'session-is-locked';
        await dispatch('showLockedMessage');
        break;

      case ROOM_SERVICE_ERROR_MESSAGES.SESSION_IS_FULL:
        errorReason = 'session-is-full';
        analytics.trackEvent(ANALYTICS.MAX_PARTICIPANT_ERROR);
        await dispatch('showSessionIsFullMessage');
        break;

      case ROOM_SERVICE_ERROR_MESSAGES.PARTICIPANT_IS_KICKED_FROM_SESSION:
        errorReason = 'kicked';
        await dispatch('showParticipantIsKickedMessage');
        break;

      case ROOM_SERVICE_ERROR_MESSAGES.DIFFERENT_ACCOUNT_ROOM:
        errorReason = 'different-account-room';
        await dispatch('initGuestMode');
        analytics.trackEvent(ANALYTICS.VBC_USER_JOINING_AS_A_GUEST);
        await dispatch('showEntranceScreen');
        break;

      case ROOM_SERVICE_ERROR_MESSAGES.CANNOT_JOIN_BEFORE_HOST:
        errorReason = 'join-before-host';
        await dispatch('showJoinBeforeHostErrorMessage', { retryCallback });
        break;

      case ROOM_SERVICE_ERROR_MESSAGES.WAITING_ROOM_APPROVAL_REQUIRED:
        errorReason = 'waiting-room-approval-required';
        await dispatch('waitingRoom/joinWaitingRoom');
        break;

      case ROOM_SERVICE_ERROR_MESSAGES.INVALID_TOKEN:
        errorReason = 'invalid-token';
        await dispatch('showEntranceScreen');
        break;

      default:
        if (defaultCallback) {
          await defaultCallback(meetingError);
        } else {
          await dispatch('showInitMeetingFailedMessage', { retryCallback });
        }
        break;
    }
    dispatch('embedded/emitEventToHost', {
      eventName: HOST_OUTBOUND_EVENTS.JOIN_MEETING_ERROR,
      eventData: { reason: errorReason }
    });
  },

  setRoomToken: ({ state, commit }, roomToken) => {
    if (roomToken !== state.roomDetails.roomToken) {
      commit('UPDATE_ROOM_DETAILS', { roomToken });

      // use sessionStorage to handle refreshes
      sessionStorageService.setItem('room_token', roomToken);
    }
  },

  initSessionPolling: ({ state, dispatch }) => {
    if (!sessionPollingInterval) {
      sessionPollingInterval = setInterval(async () => {
        const session = await roomService.getSessionById(state.sessionId);
        dispatch('updateSession', session);
      }, SESSION_POLLING_INTERVAL_IN_MILLISECONDS);
    }
  },

  stopSessionPolling: () => {
    if (sessionPollingInterval) {
      clearInterval(sessionPollingInterval);
      sessionPollingInterval = null;
    }
  },

  clearStatsIntervals: () => {
    clearInterval(subscriberStatsInterval);
    subscriberStatsInterval = 0;
    clearInterval(publisherStatsInterval);
    publisherStatsInterval = 0;
  },

  unregisterSessionData: ({ dispatch }) => {
    dispatch('clearStatsIntervals');
    dispatch('stopSessionPolling');
    dispatch('messaging/stopMessagesPolling');
    dispatch('waitingRoom/clearIntervals');
    SplitAudioDetector.stopSplitAudioDetector();
    return dispatch('unregisterBus');
  },

  registerSessionData: ({ state, dispatch }) => {
    dispatch('initSessionPolling');
    dispatch('messaging/initMessagesPolling');

    if (!state.isNoAudioMode) {
      SplitAudioDetector.initSplitAudioDetector();
    }
    return dispatch('registerBus', { domain: state.roomDetails.domain });
  },

  updateSession: ({ state, commit, dispatch }, session) => {
    const isActive = !!(session.start_time && !session.end_time);
    if (!isActive) {
      dispatch('leaveSession', { endForAll: false, closeWindow: false });
    }
    dispatch('captions/setActiveCaptionsId', session.active_captions_id);
    commit('SET_LOCK_MEETING', session.is_locked);
    commit('SET_SESSION_JOIN_APPROVAL_LEVEL', session.join_approval_level);
    commit('SET_SESSION_ACCOUNT_ID', session.account_id);
    dispatch('setSessionOwner', session.session_owner);

    session.participants
      .map((participant) => ({
        participantId: participant.participant_id,
        state: participant.state,
        type: participant.type || 'Application',
        displayName: participant.display_name
      }))
      .forEach((participant) => {
        dispatch('updateParticipantData', participant);
      });
    dispatch('whiteboard/handleSessionUpdated', session);
    const updatedSessionRecordingOn = session.recording === 'on';
    const currentSessionRecordingOn = !!state.recordings?.recording;
    if (updatedSessionRecordingOn !== currentSessionRecordingOn) {
      dispatch('recordings/updateSessionRecording', session.session_id);
    }
  },

  reconnectToSession: async ({ state, dispatch }) => {
    const session = await roomService.getSessionById(state.sessionId);
    const isActive = !!(session.start_time && !session.end_time);

    if (!isActive) {
      dispatch('showMeetingHasAlreadyEndedMessage');
    } else {
      await dispatch('clearStateForReconnection');
      await dispatch('updateSession', session);
      logger.log('session-reconnect', LOG_CATEGORIES.TOKBOX, {
        message: 'Try to reconnect to session'
      });
      await dispatch('connectToSession', state.sessionId);
      await dispatch('registerSessionData');
      dispatch('resetGlobalMessage');
      dispatch('toggleLoudnessDetector');
    }
  },

  clearStateForReconnection: ({ commit }) => {
    commit('CLEAR_STATE_FOR_RECONNECTION');
    commit('recordings/SET_RECORDING', null);
    commit('recordings/SET_RECORDING_OWNER', null);
  },

  setIsAppRunning: ({ commit }, isRunning) => {
    commit('SET_IS_APP_RUNNING', isRunning);
  },

  connectToSession: async ({ dispatch, commit }, sessionId) => {
    let sessionToken;
    try {
      sessionToken = await dispatch('getSessionToken', sessionId);
    } catch (err) {
      return dispatch('handleRoomServiceError', {
        meetingError: utils.getSessionErrorType(err),
        retryCallback: () => dispatch('reconnectToSession')
      });
    }

    return new Promise((resolve, reject) => {
      // Connect to the session
      logger.log('tb_session-connect_try', LOG_CATEGORIES.TOKBOX, {
        tb_call: 'session.connect()'
      });

      MeetingManager.session.connect(sessionToken, (error) => {
        if (error) {
          logger.error('tb_session-connect_error', LOG_CATEGORIES.TOKBOX, {
            tb_call: 'session.connect()',
            error
          });

          return reject(error);
        }
        // Following the successful retrieval of the session token, we can hide pre-existing global messages
        dispatch('resetGlobalMessage');
        logger.log('tb_session-connect_success', LOG_CATEGORIES.TOKBOX, {
          tb_call: 'session.connect()'
        });
        dispatch('subscriberStats', false);
        commit('SET_HAS_SESSION_EVER_CONNECTED', true);
        MeetingManager.onSessionConnected(() =>
          commit('SET_IS_SESSION_CONNECTED', true)
        );
        window.Appcues?.track('Meetings - Session-connected');
        return resolve();
      });
    });
  },

  getSessionToken: async ({ getters }, sessionId) => {
    const credentials = vbcGw.getCredentials();
    if (getters.isGuest) {
      return roomServicePublic.getGuestSessionToken(
        credentials.externalId,
        getters.roomToken
      );
    } else {
      const { token } = await roomService.getSessionToken(
        credentials.externalId,
        sessionId
      );
      return token;
    }
  },

  updateStream: ({ state, commit }, streamUpdate) => {
    if (state.myScreenStreamId !== streamUpdate.streamId) {
      const streamIndex = state.streams.findIndex(
        (stream) => stream.streamId === streamUpdate.streamId
      );
      if (streamIndex > -1) {
        commit('UPDATE_STREAM', {
          streamIndex,
          streamData: streamUpdate.streamData,
          videoDimensions: streamUpdate.videoDimensions
        });
      } else {
        logger.error('stream-not-found', LOG_CATEGORIES.TOKBOX, {
          stream: streamUpdate
        });
      }
      // If the stream id has changed we want to update it.
      if (
        streamUpdate.streamData.streamId &&
        streamUpdate.streamId !== streamUpdate.streamData.streamId
      ) {
        if (streamUpdate.streamId === state.mainStreamId) {
          commit('SET_MAIN_STREAM_ID', streamUpdate.streamData.streamId);
        }
        if (streamUpdate.streamId === state.pinnedStreamId) {
          commit('PIN_STREAM', streamUpdate.streamData.streamId);
        }
      }
    }
  },

  subscriberStatsHandler: (
    { commit },
    { error, stats, subscriber, subscriberData, showStats, shouldReportStats }
  ) => {
    if (!error) {
      if (stats) {
        let audioBytesReceived = 0;
        let videoBytesReceived = 0;
        let timestamp = -1;
        let properties;
        const { streamId } = subscriber.stream;

        properties = TelemetriesHandler.getProperties(stats, showStats, false);

        if (shouldReportStats) {
          if (showStats) {
            audioBytesReceived = properties.audioBytesReceived;
            videoBytesReceived = properties.videoBytesReceived;
            timestamp = properties.timestamp;
          }
        } else {
          timestamp = stats?.timestamp || 0;
          if (stats.audio) {
            audioBytesReceived = stats?.audio?.bytesReceived || 0;
          }
          if (stats.video) {
            videoBytesReceived = stats?.video?.bytesReceived || 0;
          }
        }

        if (showStats) {
          subscriberData[streamId].push({
            audioBytesReceived,
            videoBytesReceived,
            timestamp
          });
          const streamData = subscriberData[streamId];
          if (streamData.length > 1) {
            // Calculate the seconds elapsed between the first and the last snapshot.
            const secondsElapsed =
              (streamData[1].timestamp - streamData[0].timestamp) / 1000;
            /**
              Calculate the diff between the audio and video bytes received from the first and the last snapshot.
              Finally the result is multiplied by 8 to convert it to bits.
             */
            const audioBitsReceived =
              (streamData[1].audioBytesReceived -
                streamData[0].audioBytesReceived) *
              8;
            const videoBitsReceived =
              (streamData[1].videoBytesReceived -
                streamData[0].videoBytesReceived) *
              8;
            /**
              Calculate the bitrate in kilobits per second (kbps) by dividing the audio/video bits by secondsElapsed,
              and finally dividing the result by 1000.
             */
            const audioBitrate = audioBitsReceived / secondsElapsed / 1000;
            const videoBitrate = videoBitsReceived / secondsElapsed / 1000;
            commit('UPDATE_SUBSCRIBER_STATS', {
              streamId,
              audioBitrate,
              videoBitrate
            });
            subscriberData[streamId].shift();
          }
        }
      }
    }
  },

  showSubscriberStats: ({ commit }, showStats) => {
    if (showStats) {
      commit('SHOW_SUBSCRIBER_STATS', true);
    } else {
      commit('SHOW_SUBSCRIBER_STATS', false);
      commit('RESET_SUBSCRIBER_STATS');
    }
  },

  subscriberStats: ({ dispatch }, showStats) => {
    let subscriberData = {};
    let subscriberStatCounter = {};

    dispatch('showSubscriberStats', showStats);
    if (!showStats) {
      subscriberData = {};
    }
    if (!subscriberStatsInterval) {
      subscriberStatsInterval = setInterval(() => {
        // TODO: replace with Meetings.subscribers
        tokbox.getSubscribers().forEach(async (subscriber) => {
          const { streamId } = subscriber.stream;
          if (!subscriberData[streamId]) {
            subscriberData[streamId] = [];
          }
          if (!subscriberStatCounter[streamId]) {
            subscriberStatCounter[streamId] = 0;
          }
          const shouldReportStats =
            subscriberStatCounter[streamId] % SEND_STATS_INTERVAL === 0;
          if (subscriber.stream.hasAudio || shouldReportStats) {
            subscriberStatCounter[streamId] = shouldReportStats
              ? 0
              : subscriberStatCounter[streamId];

            try {
              const stats = await subscriber.getRtcStatsReport();
              dispatch('subscriberStatsHandler', {
                undefined,
                stats,
                subscriber,
                subscriberData,
                showStats,
                shouldReportStats
              });
            } catch {
              // Ignore
            }
          }
          subscriberStatCounter[streamId]++;
        });
      }, STATS_FRAME_SIZE_MS);
    }
  },

  updateParticipantVolumeFromStatsJson: (
    _,
    { currAudioLevel, prevAudioLevel, streamId }
  ) => {
    // TODO Check if we can re-use streamsActiveVolumes[streamId] array
    // instead of re-creating it, can reduce GC.
    const audioLevelArray = [];
    for (let i = 0; i < AUDIO_LEVEL_UI_ITERATIONS; i++) {
      const audioLevel =
        (prevAudioLevel * (STATS_FRAME_SIZE_MS - i * AUDIO_LEVEL_UI_UPDATE_MS) +
          currAudioLevel * (i * AUDIO_LEVEL_UI_UPDATE_MS)) /
        STATS_FRAME_SIZE_MS;
      audioLevelArray.push(audioLevel);
    }

    if (streamId) {
      StreamsStats.setStreamAudioLevel(streamId, audioLevelArray);
    } else {
      StreamsStats.setMyCurrentAudioLevel(audioLevelArray);
    }
  },

  // TODO: break into helper functions
  // Do the actual processing of streamsActiveVolumes and myCurrentAudioLevel
  updateParticipantVolumeInterval: ({ state, commit, dispatch, getters }) => {
    function normalizeStreamVolume(audioLevel, volumeAvg) {
      const micVolume = Math.floor(audioLevel * 10);
      if (volumeAvg <= micVolume) {
        volumeAvg = micVolume;
      } else {
        volumeAvg = (7 * volumeAvg + 3 * micVolume) / 10;
      }
      return volumeAvg > MIC_VOLUME_MIN_THRESHOLD ? volumeAvg : 0;
    }

    setInterval(() => {
      const newParticipantsVolume = {};
      let maxAudioLevel = -1;
      let loudestStreamId;
      let stream;
      const streamMap = getters.streamsMap;
      const stateParticipantsVolumes = state.participantsVolumes;
      for (const currStreamId in StreamsStats.streamsActiveVolumes) {
        stream = streamMap[currStreamId];
        if (!stream) {
          // in case the participant had left within the throttle window and we have leftovers
          continue;
        }

        // Because we are taking the first element from the array,
        // check if we have one.
        const streamAudioLevel =
          StreamsStats.streamsActiveVolumes[currStreamId];
        if (!streamAudioLevel || streamAudioLevel.length === 0) {
          continue;
        }

        // Apply sqrt to amplify the visibility of weak ambience noises.
        const audioLevel = Math.sqrt(streamAudioLevel.shift());
        const volumeAvg = stateParticipantsVolumes[stream.participantId] || 0;

        newParticipantsVolume[stream.participantId] = normalizeStreamVolume(
          audioLevel,
          volumeAvg
        );

        if (audioLevel >= maxAudioLevel) {
          maxAudioLevel = audioLevel;
          loudestStreamId = stream ? stream.streamId : undefined;
        }
      }

      if (StreamsStats.myCurrentAudioLevel.length > 0) {
        const audioLevel = Math.sqrt(StreamsStats.myCurrentAudioLevel.shift());
        const volumeAvg = stateParticipantsVolumes[state.myParticipantId] || 0;

        newParticipantsVolume[state.myParticipantId] = normalizeStreamVolume(
          audioLevel,
          volumeAvg
        );
        if (getters.allowMyParticipantAsDominantSpeaker) {
          if (audioLevel >= maxAudioLevel) {
            maxAudioLevel = audioLevel;
            loudestStreamId = MeetingManager.publisherStreamId;
          }
        } else if (
          state.mainStreamId &&
          state.mainStreamId === MeetingManager.publisherStreamId
        ) {
          dispatch('setDefaultMainStream');
        }
      }

      if (loudestStreamId) {
        dispatch('updateDominantSpeaker', {
          streamId: loudestStreamId,
          audioLevel: maxAudioLevel
        });
      }
      commit('SET_PARTICIPANTS_VOLUME', newParticipantsVolume);
    }, AUDIO_THROTTLE_INTERVAL);
  },

  updateDominantSpeaker: ({ commit }, { streamId, audioLevel }) => {
    if (audioLevel > DOMINANT_SPEAKER_THRESHOLD) {
      const now = Date.now();
      if (now - lastDominantSpeakerUpdate > DOMINANT_SPEAKER_DELAY) {
        commit('SET_MAIN_STREAM_ID', streamId);
        lastDominantSpeakerUpdate = now;
      }
    }
  },

  setSessionOwner: ({ commit }, owner) => {
    commit('SET_SESSION_OWNER', owner);
  },

  updateParticipantData: ({ state, commit }, participantData) => {
    commit('UPDATE_PARTICIPANT', participantData);

    if (participantData.participantId === state.myParticipantId) {
      if (participantData.state === PARTICIPANT_STATE_TYPES.KICKED) {
        commit('SET_IS_PARTICIPANT_ABOUT_TO_GET_KICKED', true);
      } else if (state.isAboutToGetKicked) {
        commit('SET_IS_PARTICIPANT_ABOUT_TO_GET_KICKED', false);
      }
    }
  },

  setRoomDetails: ({ commit, dispatch }, roomDetails) => {
    commit('UPDATE_ROOM_DETAILS', roomDetails);

    // use sessionStorage to handle refreshes
    sessionStorageService.setItem('room_token', roomDetails.roomToken);
    dispatch('setDefaultSelectedCountries');
  },

  setDefaultSelectedCountries: async ({ getters, dispatch }) => {
    // set default selected country to be according to current locale, and if does not exist take the first option
    const locale = getters.userLocale;
    const dialInNumbers = getters.dialInNumbers;
    const myRegionNumber =
      dialInNumbers.find((number) => number.locale === locale) ||
      dialInNumbers.find((number) => number.locale === 'en-US');
    const selectedCountry = myRegionNumber || dialInNumbers[0];
    if (selectedCountry) {
      dispatch('setSelectedDialInNumbers', [
        {
          value: selectedCountry.number,
          label: selectedCountry.display_name,
          checked: selectedCountry.checked,
          locale: selectedCountry.locale
        }
      ]);
    }
  },

  addStream: ({ commit }, { stream, isPublisher = false }) => {
    commit('ADD_STREAM', utils.extractStreamData(stream, isPublisher));
  },

  removeStream: ({ state, commit, dispatch }, streamId) => {
    commit('REMOVE_STREAM', streamId);
    if (streamId === state.pinnedStreamId) {
      dispatch('pinVideoStream', '');
    }
    if (streamId === state.mainStreamId) {
      dispatch('setDefaultMainStream');
    }
  },

  setDefaultMainStream: ({ state, commit }) => {
    if (state.streams.length > 1) {
      commit('SET_MAIN_STREAM_ID', state.streams[1].streamId);
    } else {
      commit('SET_MAIN_STREAM_ID', '');
    }
  },

  subscribeStream: async (
    { state, getters, dispatch },
    { streamId, element, options, triesCounter = 1 }
  ) => {
    const stream = MeetingManager.getStreamById(streamId);
    if (stream) {
      const subscriberStatus = state.streamsSubscribersStatus[streamId];
      if (
        subscriberStatus === SUBSCRIBER_STATUS.SUBSCRIBED ||
        subscriberStatus === SUBSCRIBER_STATUS.SUBSCRIBING ||
        subscriberStatus === SUBSCRIBER_STATUS.RETRYING
      ) {
        const errMessage = 'Trying to subscribe the same stream more than once';
        logger.error('subscribe-stream', LOG_CATEGORIES.TOKBOX, {
          message: errMessage,
          streamId
        });
        throw new Error(errMessage);
      }
    } else {
      const errMessage = 'Stream not found';
      logger.error('subscribe-stream', LOG_CATEGORIES.TOKBOX, {
        message: errMessage,
        streamId
      });
      throw new Error(errMessage);
    }
    dispatch('setStreamSubscriberStatus', {
      streamId,
      status:
        triesCounter > 1
          ? SUBSCRIBER_STATUS.RETRYING
          : SUBSCRIBER_STATUS.SUBSCRIBING
    });

    const isShareScreen = utils.isScreenshareStream(stream);
    logger.log('tb_session-subscribe_try', LOG_CATEGORIES.TOKBOX, {
      tb_call: 'session.subscribe()',
      streamId,
      isShareScreen
    });

    let subscriber;
    try {
      subscriber = await MeetingManager.startSubscribing(
        stream,
        element,
        options,
        (videoElement) => {
          // Apply the selected speaker ID once the video element is created
          if (state.selectedSpeakerId && videoElement.setSinkId) {
            videoElement.setSinkId(state.selectedSpeakerId);
          }
        }
      );

      logger.log('tb_session-subscribe_success', LOG_CATEGORIES.TOKBOX, {
        tb_call: 'session.subscribe()',
        streamId,
        isShareScreen
      });

      dispatch('setStreamSubscriberStatus', {
        streamId,
        status: SUBSCRIBER_STATUS.SUBSCRIBED
      });
      dispatch('subscribeSubscriberEvents', subscriber);

      // Test - trying to detect split audio
      const streamParticipant = getters.getParticipantByStreamId(streamId);
      SplitAudioDetector.resetSubscriberAudioLevelTestCounter(streamId, {
        type: streamParticipant?.type,
        name: streamParticipant?.displayName
      });
      return subscriber;
    } catch (error) {
      const sessionSubscribeErrorLogData = {
        tb_call: 'session.subscribe()',
        streamId,
        isShareScreen,
        retryCounter: triesCounter,
        error
      };
      if (error.name === OT_ERROR.NOT_CONNECTED && !state.isSessionConnected) {
        logger.log(
          'tb_session-subscribe_error',
          LOG_CATEGORIES.TOKBOX,
          sessionSubscribeErrorLogData
        );
      } else {
        logger.error(
          'tb_session-subscribe_error',
          LOG_CATEGORIES.TOKBOX,
          sessionSubscribeErrorLogData
        );
      }

      dispatch('setStreamSubscriberStatus', {
        streamId,
        status: SUBSCRIBER_STATUS.FAILED
      });

      if (router.currentRoute.name === 'Home') {
        let delay =
          MIN_DELAY_BETWEEN_SUBSCRIBE_TRIES_IN_MILLISECONDS *
            (triesCounter - 1) +
          Math.random() * MIN_DELAY_BETWEEN_SUBSCRIBE_TRIES_IN_MILLISECONDS;

        delay = Math.min(
          delay,
          MAX_DELAY_BETWEEN_SUBSCRIBE_TRIES_IN_MILLISECONDS
        );
        await wait(delay);
        return dispatch('subscribeStream', {
          streamId,
          element,
          options,
          triesCounter: triesCounter + 1
        });
      }
    }
  },

  fixSplitAudioStream: ({ dispatch }, streamId) => {
    logger.log('trying-to-fix-split-audio', LOG_CATEGORIES.SPLIT_AUDIO, {
      streamId
    });

    const streamSubscriber = MeetingManager.getSubscriberByStreamId(streamId);
    if (streamSubscriber) {
      if (
        !SplitAudioDetector.isMaxRetriesReachedForStream(streamId) &&
        streamSubscriber.videoElement()
      ) {
        logger.log('split-audio-rebinding-video', LOG_CATEGORIES.SPLIT_AUDIO, {
          streamId
        });
        // Rebind the video element should fix the split audio.
        utils.rebindVideoElement(streamSubscriber);
        // Check if the rebind fixed the split audio
        SplitAudioDetector.resetSubscriberAudioLevelTestCounter(streamId);
      } else {
        logger.log(
          'split-audio-unsubscribing-stream',
          LOG_CATEGORIES.SPLIT_AUDIO,
          {
            streamId
          }
        );
        // In case we don't have video element, we unsubscribe the stream.
        // after unsubscribing the stream, the 'destroyed' event of the subscriber will be fired
        // and if we have a stream with no subscriber (which we do when we manually unsubscribing),
        // we try to resubscribe the stream again.
        MeetingManager.unsubscribeStream(streamId);
      }
    } else {
      logger.log(
        'split-audio-resubscribing-stream',
        LOG_CATEGORIES.SPLIT_AUDIO,
        {
          streamId
        }
      );
      dispatch('resubscribeStream', streamId);
    }
  },

  setSubscriberAudioVolume: (_, { streamId, level }) => {
    const streamSubscriber = MeetingManager.getSubscriberByStreamId(streamId);
    if (streamSubscriber) {
      streamSubscriber.setAudioVolume(level);
    }
  },

  resubscribeStream: ({ dispatch }, streamId) => {
    logger.log('resubscribe-stream', LOG_CATEGORIES.TOKBOX, { streamId });
    const subscriberOptions = MeetingManager.getSubscriberOptions(streamId);
    if (!subscriberOptions) {
      logger.error(
        'resubscribe-stream - could not find subscriber options',
        LOG_CATEGORIES.TOKBOX,
        { streamId }
      );
      return;
    }

    dispatch('setStreamSubscriberStatus', {
      streamId,
      status: SUBSCRIBER_STATUS.NOT_SUBSCRIBED
    });

    dispatch('subscribeStream', {
      streamId,
      element: subscriberOptions.element,
      options: subscriberOptions.options,
      triesCounter: 2
    });
  },

  createPublisher: ({ state, getters }, { element, options }) => {
    const publisherOptions = { ...options };
    publisherOptions.publishAudio =
      publisherOptions.publishAudio === undefined
        ? state.isMicEnabled
        : publisherOptions.publishAudio;
    publisherOptions.publishVideo =
      publisherOptions.publishVideo === undefined
        ? state.isVideoEnabled
        : publisherOptions.publishVideo;

    publisherOptions.mirror = false;

    publisherOptions.videoSource =
      state.selectedCameraId !== '' ? state.selectedCameraId : false;
    publisherOptions.audioSource =
      state.selectedMicrophoneId !== '' ? state.selectedMicrophoneId : false;

    // When entering in incognito mode we don't have the devices ids
    // so we just set the audio and video source to true
    publisherOptions.videoSource =
      publisherOptions.videoSource === undefined
        ? true
        : publisherOptions.videoSource;
    publisherOptions.audioSource =
      publisherOptions.audioSource === undefined
        ? true
        : publisherOptions.audioSource;

    // When the publisher initializes for the first time, we want to still try and create a publisher even if the camera/mic doesn't have access
    // so we can show an error message to the user that we can't access his mic or camera (or both) when the app starts.
    if (state.isPublisherInitialized || isElectron()) {
      publisherOptions.videoSource = getters.hasCameraAccess
        ? publisherOptions.videoSource
        : false;

      publisherOptions.audioSource = getters.hasMicrophoneAccess
        ? publisherOptions.audioSource
        : false;
    }

    const videoFilter = utils.getVideoFilter(state.virtualBackground);
    if (getters.isVirtualBackgroundSupported && videoFilter) {
      publisherOptions.videoFilter = videoFilter;
    }

    logger.log('create-publisher', LOG_CATEGORIES.TOKBOX, {
      message:
        !publisherOptions.videoSource && !publisherOptions.audioSource
          ? 'Could not get audio and video sources'
          : '',
      audioSource: publisherOptions.audioSource,
      videoSource: publisherOptions.videoSource,
      hasCameraDevices: publisherOptions.hasCameraDevices,
      hasCameraPermissions: publisherOptions.hasCameraPermissions,
      hasMicrophoneDevices: publisherOptions.hasMicrophoneDevices,
      hasMicrophonePermissions: publisherOptions.hasMicrophonePermissions,
      type: publisherOptions.videoFilter?.type || 'default'
    });

    if (publisherOptions.videoSource || publisherOptions.audioSource) {
      return MeetingManager.initPublisher(element, publisherOptions);
    }
  },

  showLongerThanUsualPublisherMessage: async ({
    dispatch,
    getters,
    commit
  }) => {
    if (!getters.isInitializingPublisher) {
      return;
    }

    dispatch('addFlashMessage', {
      type: 'critical',
      time: 6000,
      title: i18n.t('store_actions.longer_than_usual_flash_message_title'),
      text: i18n.t('store_actions.longer_than_usual_flash_message_text'),
      button: {
        text: i18n.t('store_actions.got_it_button'),
        actions: {
          name: 'removeSelf'
        }
      }
    });

    commit('SET_SHOW_LONGER_THAN_USUAL_PUBLISHER_MESSAGE', true);
  },

  publishStream: async ({ state, getters, commit, dispatch }, payload) => {
    logger.log('publish-stream', LOG_CATEGORIES.TOKBOX, {
      message: 'Try to publish stream'
    });

    if (state.publisherState === PUBLISHER_STATE.INITIALIZING) {
      return;
    }
    commit('SET_PUBLISHER_STATE', PUBLISHER_STATE.INITIALIZING);
    const canPublish = await dispatch('validatePublishLimitations');
    if (state.isVideoEnabled && !canPublish.video) {
      dispatch('addFlashMessage', {
        type: 'warning',
        text: i18n.t('store_actions.publish_stream_maximum_warn_text', {
          videoStreamsAllowedNumber: NUMBER_OF_VIDEO_STREAMS_ALLOWED
        })
      });

      analytics.trackEvent(ANALYTICS.MAX_VIDEOS_ERROR, {
        'Number of active videos': NUMBER_OF_VIDEO_STREAMS_ALLOWED
      });

      dispatch('setVideoEnabled', false);
    }
    if (
      !state.isVideoEnabled &&
      !state.isMicEnabled &&
      state.optimizePublishers
    ) {
      logger.log('publish-stream', LOG_CATEGORIES.TOKBOX, {
        message: 'Participant joined with no publisher'
      });

      return;
    }
    try {
      await dispatch('logDuration', {
        action: async () => {
          setTimeout(() => {
            dispatch('showLongerThanUsualPublisherMessage');
          }, TIME_TO_WAIT_BEFORE_SHOWING_LONG_TIME_INIT_PUBLISHER_MESSAGE);
          const publisher = await dispatch('createPublisher', payload);
          if (publisher) {
            MeetingManager.setMainPublisher(publisher);
          }
        },
        threshold: 5000,
        logName: 'create-publisher-duration',
        logSource: LOG_CATEGORIES.TOKBOX
      });
      if (!hasDevicesNames()) {
        await dispatch('logDuration', {
          action: () => dispatch('updateUserDevices'),
          threshold: 3000,
          logName: 'update-user-devices',
          logSource: LOG_CATEGORIES.TOKBOX
        });
      }
    } catch (error) {
      await dispatch('updateDevicesPermissions');
      dispatch('handlePublisherError', error);
    }

    if (!MeetingManager.publisher) {
      commit('SET_PUBLISHER_STATE', PUBLISHER_STATE.FAILED);
      dispatch('setVideoEnabled', false);
      dispatch('setMicEnabled', false);
      return;
    }
    dispatch('subscribePublisherEvents');
    await dispatch('logDuration', {
      action: () => dispatch('publishCurrentPublisher'),
      threshold: 5000,
      logName: 'session-publish-duration',
      logSource: LOG_CATEGORIES.TOKBOX
    });
    // If Captions or Live Transcript was enabled before the publisher was created
    // We want to add the self subscription to get our own captions
    if (getters['captions/isCaptionsOrLiveTranscriptionEnabled']) {
      const selfSubscriber = await dispatch('captions/createSelfSubscriber');
      if (selfSubscriber) {
        await dispatch('captions/subscribeToCaptionsForSubscriber', {
          subscriber: selfSubscriber,
          shouldSubscribe: true
        });
      }
    }
  },

  publishCurrentPublisher: ({ state, commit, dispatch }) => {
    if (!MeetingManager.session) {
      logger.log('tb_session-publish-without-session', LOG_CATEGORIES.TOKBOX, {
        tb_call: 'session.publish()'
      });
      return;
    }

    return new Promise((resolve, reject) => {
      // start publishing right after session connects
      MeetingManager.onSessionConnected(async () => {
        logger.log('tb_session-publish-try', LOG_CATEGORIES.TOKBOX, {
          tb_call: 'session.publish()'
        });

        try {
          await MeetingManager.startMainPublishing();
          if (state.isLongPublisherInitialization) {
            dispatch('addFlashMessage', {
              type: 'good',
              title: i18n.t('store_actions.start_publishing_title'),
              text: i18n.t('store_actions.start_publishing_text')
            });
            commit('SET_SHOW_LONGER_THAN_USUAL_PUBLISHER_MESSAGE', false);
          }
        } catch (error) {
          logger.error('tb_session-publish_error', LOG_CATEGORIES.TOKBOX, {
            tb_call: 'session.publish()',
            error
          });

          MeetingManager.stopPublishing();
          commit('SET_PUBLISHER_STATE', PUBLISHER_STATE.FAILED);
          dispatch('handlePublisherError', error);
          dispatch('setVideoEnabled', false);
          dispatch('setMicEnabled', false);
          return reject(error);
        }

        logger.log('tb_session-publish-success', LOG_CATEGORIES.TOKBOX, {
          tb_call: 'session.publish()',
          streamId: MeetingManager.publisherStreamId
        });

        const streamData = utils.extractStreamData(
          MeetingManager.publisher.stream,
          true
        );

        dispatch('updateStream', {
          streamId: MeetingManager.connectionId,
          streamData
        });
        dispatch('publisherStats', {
          showStats: true,
          onAudioPacketLoss: null
        });
        logger.log('publish-stream', LOG_CATEGORIES.TOKBOX, {
          message: 'Stream published',
          isVideoEnabled: state.isVideoEnabled,
          isMicEnabled: state.isMicEnabled,
          connection: MeetingManager.session.connection
        });

        commit('SET_PUBLISHER_STATE', PUBLISHER_STATE.PUBLISHED);
        SplitAudioDetector.resetPublisherAudioLevelTestCounter();
        resolve(MeetingManager.publisherStreamId);
      });
    });
  },

  handlePublisherError: ({ state, commit, dispatch }, error) => {
    const unavailableDevices = [];
    if (!state.hasCameraPermissions) {
      unavailableDevices.push('Camera');
    }
    if (!state.hasMicrophonePermissions) {
      unavailableDevices.push('Microphone');
    }
    // In case it seems we have permissions but still fail to create publisher
    if (state.hasCameraPermissions && state.hasMicrophonePermissions) {
      unavailableDevices.push(...['Camera', 'Microphone']);
    }
    const publisherError = utils.getPublisherError(
      error,
      unavailableDevices,
      state.isBranded
    );
    if (
      publisherError.name === PUBLISHER_ERROR.SYSTEM_PERMISSIONS &&
      isElectron() &&
      OS.name === OS_TYPES.WINDOWS
    ) {
      // We can't detect if we have permissions for the native app in windows
      commit('SET_HAS_CAMERA_PERMISSIONS', false);
      commit('SET_HAS_MICROPHONE_PERMISSIONS', false);
    } else if (
      publisherError.name === PUBLISHER_ERROR.CHROME_MICROPHONE_ERROR
    ) {
      // Chrome error - need to restart app/browser.
      analytics.trackEvent(ANALYTICS.CHROME_ERROR);
    }
    commit('SET_PUBLISHER_ERROR', publisherError);
    dispatch('showMissingDeviceAccess');
    logger.error('publisher-error', LOG_CATEGORIES.TOKBOX, {
      error,
      publisherError,
      microphones: state.microphoneDevices,
      cameras: state.cameraDevices,
      selectedCameraId: state.selectedCameraId,
      selectedMicrophoneId: state.selectedMicrophoneId
    });
  },

  validatePublishLimitations: ({ state, getters }) => {
    const result = { audio: true, video: true };
    if (window.TEST_MODE) {
      return result;
    }
    const numberOfVideoStreamsInSession = getters.numberOfVideoStreamsInSession;
    const numberOfPublishersInSession = getters.numberOfPublishersInSession;
    if (numberOfVideoStreamsInSession >= NUMBER_OF_VIDEO_STREAMS_ALLOWED) {
      logger.error('max-video-streams', LOG_CATEGORIES.TOKBOX, {
        videoStreams: numberOfVideoStreamsInSession,
        publishers: numberOfPublishersInSession,
        isVideoEnabled: state.isVideoEnabled,
        isMicEnabled: state.isMicEnabled
      });

      result.video = false;
    }
    if (numberOfPublishersInSession >= NUMBER_OF_PUBLISHERS_ALLOWED) {
      logger.error('max-publishers', LOG_CATEGORIES.TOKBOX, {
        videoStreams: numberOfVideoStreamsInSession,
        publishers: numberOfPublishersInSession,
        isVideoEnabled: state.isVideoEnabled,
        isMicEnabled: state.isMicEnabled
      });
    }
    return result;
  },

  publisherStatsHandler: (
    { getters, commit },
    { error, stats, showStats, onAudioPacketLoss, shouldReportStats }
  ) => {
    if (error) {
      return;
    }
    if (!stats) {
      return;
    }

    let totalAudioPacketsSent = 0;
    let totalAudioPacketsLost = -1;
    let timestamp = -1;
    let properties;

    properties = TelemetriesHandler.getProperties(stats, showStats, true);

    if (shouldReportStats) {
      if (showStats) {
        totalAudioPacketsSent = properties.totalAudioPacketsSent;
        totalAudioPacketsLost = properties.totalAudioPacketsLost;
        timestamp = properties.timestamp;
      }
    } else {
      const statsObj = stats[0];
      totalAudioPacketsSent = statsObj?.stats?.audio?.packetsSent || 0;
      timestamp = statsObj?.stats?.timestamp;
    }

    if (
      !timestamp ||
      totalAudioPacketsSent === 0 ||
      totalAudioPacketsLost === -1
    ) {
      return;
    }

    const frameAudioPacketsSent =
      totalAudioPacketsSent - getters.publisherStats?.totalAudioPacketsSent ||
      0;
    const frameAudioPacketsLost =
      totalAudioPacketsLost - getters.publisherStats?.totalAudioPacketsLost ||
      0;
    const frameAudioPacketLoss = frameAudioPacketsLost / frameAudioPacketsSent;

    const newPublisherStats = {
      timestamp,
      totalAudioPacketsLost,
      totalAudioPacketsSent,
      frameAudioPacketsSent,
      frameAudioPacketsLost,
      frameAudioPacketLoss
    };
    commit('UPDATE_PUBLISHER_STATS', newPublisherStats);

    // When more than MAX_TIME_BETWEEN_PACKET_LOSS_FRAMES passed, we store the current frame and wait for the next one.
    if (
      timestamp - getters.publisherStats.timestamp >
      MAX_TIME_BETWEEN_AUDIO_PACKET_LOSS_FRAMES
    ) {
      commit('UPDATE_PUBLISHER_STATS', {
        numConsecutiveAudioPacketLossFrames: 0,
        frameAudioPacketLoss: 0,
        frameAudioPacketsSent: 0,
        frameAudioPacketsLost: 0
      });
      return;
    }

    if (frameAudioPacketLoss < PUBLISHER_AUDIO_PACKET_LOSS_THRESHOLD) {
      commit('UPDATE_PUBLISHER_STATS', {
        numConsecutiveAudioPacketLossFrames: 0
      });
    } else if (
      getters.publisherStats?.numConsecutiveAudioPacketLossFrames ||
      0 >= MAX_AUDIO_PACKET_LOSS_FRAMES
    ) {
      commit('UPDATE_PUBLISHER_STATS', {
        numConsecutiveAudioPacketLossFrames: 0
      });
      if (onAudioPacketLoss) {
        onAudioPacketLoss();
      }
    } else {
      commit('UPDATE_PUBLISHER_STATS', {
        numConsecutiveAudioPacketLossFrames:
          getters.publisherStats.numConsecutiveAudioPacketLossFrames + 1
      });
    }
  },

  publisherStats: ({ dispatch }, { showStats, onAudioPacketLoss }) => {
    if (!publisherStatsInterval) {
      let publisherStatsCounter = 0;
      // Start an interval that calls the getStats API while video is enabled.
      publisherStatsInterval = setInterval(async () => {
        if (MeetingManager.publisher) {
          const shouldReportStats =
            publisherStatsCounter % SEND_STATS_INTERVAL === 0;
          if (MeetingManager.publisher.stream?.hasAudio || shouldReportStats) {
            publisherStatsCounter = shouldReportStats
              ? 0
              : publisherStatsCounter;

            try {
              const stats = await MeetingManager.publisher.getRtcStatsReport();
              dispatch('publisherStatsHandler', {
                undefined,
                stats,
                showStats,
                onAudioPacketLoss,
                shouldReportStats
              });
            } catch {
              // Ignore
            }
          }
          publisherStatsCounter++;
        }
      }, STATS_FRAME_SIZE_MS);
    }
  },

  unpublishStream: ({ state, commit, dispatch }) => {
    if (MeetingManager.publisher) {
      dispatch('updateStream', {
        streamId: MeetingManager.publisherStreamId,
        streamData: utils.createAvatarStream(
          state.myParticipantId,
          MeetingManager.connectionId,
          true
        )
      });
      MeetingManager.stopPublishing();

      clearInterval(publisherStatsInterval);
      publisherStatsInterval = 0;

      commit('SET_PUBLISHER_STATE', PUBLISHER_STATE.UNPUBLISHED);
      logger.log('unpublish-stream', LOG_CATEGORIES.TOKBOX, {
        message: 'Stream unpublished'
      });
    }
  },

  setNoAudioMode: ({ commit, dispatch }, mode) => {
    if (mode === false) {
      MeetingManager.subscribeToAudio();
      commit('SET_NO_AUDIO_MODE', false);
      SplitAudioDetector.initSplitAudioDetector();
      dispatch('toggleMic');
    }
  },

  toggleMic: ({ state, getters, dispatch }, source = undefined) => {
    if (!getters.hasMicrophoneAccess) {
      return dispatch('showMissingDeviceAccess', DEVICES_TYPES.MICROPHONE);
    }

    // TODO: Maybe we should continue only if the publisherState is PUBLISHED
    if (state.publisherState === PUBLISHER_STATE.INITIALIZING) {
      return;
    }

    const isMicEnabled = !state.isMicEnabled;
    dispatch('setMicEnabled', isMicEnabled);
    logger.log('mic-toggled', LOG_CATEGORIES.CLIENT_LOGIC, {
      isMuted: !isMicEnabled,
      muteIndication: state.loudnessDetector.muteIndication
    });

    if (isMicEnabled) {
      publisherAudioTimer = setTimeout(() => {
        if (!getters.myStream?.hasAudio) {
          logger.warning(
            'mic-is-not-compatible-with-publisher',
            LOG_CATEGORIES.CLIENT_LOGIC,
            {
              message: 'The mic is on but there is no audio'
            }
          );
        }
      }, 5000);
    } else {
      if (publisherAudioTimer) {
        clearTimeout(publisherAudioTimer);
      }
    }

    const eventProps = {
      'New State': isMicEnabled ? 'On' : 'Off',
      'Num of Participants': getters.activeParticipants.length,
      'Join Type': getters.isGuest ? 'Guest' : 'Application',
      Source: source
    };

    if (isMicEnabled && state.loudnessDetector.muteIndication) {
      eventProps.Note = 'Mute indication helper';
    }
    analytics.trackEvent(ANALYTICS.MIC_TOGGLED, eventProps);

    if (MeetingManager.publisher) {
      MeetingManager.publisher.publishAudio(state.isMicEnabled);
      dispatch('toggleLoudnessDetector');
    }
  },

  togglePushToTalkOn: ({ state, getters, dispatch }) => {
    if (
      getters.isInitializingPublisher ||
      state.isMicEnabled ||
      state.isNoAudioMode
    ) {
      return;
    }
    // Don't allow PTT for large meetings when publisher is not published
    if (
      state.optimizePublishers &&
      state.publisherState !== PUBLISHER_STATE.PUBLISHED
    ) {
      dispatch('showPushToTalkFlashMessage');
      return;
    }
    dispatch('toggleMic', TOGGLE_MIC_SOURCES.PTT);
  },

  showPushToTalkFlashMessage: debounce(({ dispatch }) => {
    dispatch('addFlashMessage', {
      type: 'warning',
      text: i18n.t('store_actions.ptt_disabled_text')
    });
  }, 2000),

  togglePushToTalkOff: ({ state, getters, dispatch }) => {
    if (getters.isInitializingPublisher || !state.isMicEnabled) {
      return;
    }
    // Don't allow PTT for large meetings when publisher is not published
    if (
      state.optimizePublishers &&
      state.publisherState !== PUBLISHER_STATE.PUBLISHED
    ) {
      //show popup: ptt disabled in large meetings
      return;
    }
    dispatch('toggleMic');
  },

  toggleLoudnessDetector: ({ state, dispatch }) => {
    if (state.isMicEnabled) {
      dispatch('loudnessDetector/turnLoudnessDetectorOff');
      dispatch('subscribePublisherAudioLevelUpdated');
    } else {
      dispatch('loudnessDetector/turnLoudnessDetectorOn', {
        selectedMicrophoneId: state.selectedMicrophoneId,
        isMicEnabled: state.isMicEnabled
      });
      if (MeetingManager.publisher) {
        MeetingManager.publisher.off('audioLevelUpdated');
      }
    }
  },

  toggleVideo: ({ state, getters, dispatch }, source = undefined) => {
    if (!getters.hasCameraAccess) {
      return dispatch('showMissingDeviceAccess', DEVICES_TYPES.CAMERA);
    }

    // TODO: Maybe we should continue only if the publisherState is PUBLISHED
    if (state.publisherState === PUBLISHER_STATE.INITIALIZING) {
      return;
    }
    const isVideoEnabled = !state.isVideoEnabled;
    const numberOfVideoStreamsInSession = getters.numberOfVideoStreamsInSession;
    // Check number of video streams in session before we publish video.
    if (
      isVideoEnabled &&
      numberOfVideoStreamsInSession >= NUMBER_OF_VIDEO_STREAMS_ALLOWED
    ) {
      logger.error('max-video-streams', LOG_CATEGORIES.TOKBOX, {
        videoStreams: numberOfVideoStreamsInSession
      });

      dispatch('addFlashMessage', {
        type: 'warning',
        text: i18n.t('store_actions.publish_stream_maximum_warn_text', {
          videoStreamsAllowedNumber: NUMBER_OF_VIDEO_STREAMS_ALLOWED
        })
      });

      analytics.trackEvent(ANALYTICS.MAX_VIDEOS_ERROR, {
        'Number of active videos': numberOfVideoStreamsInSession
      });

      return;
    }
    dispatch('setVideoEnabled', isVideoEnabled);

    logger.log('video-toggled', LOG_CATEGORIES.CLIENT_LOGIC, {
      isMuted: !isVideoEnabled
    });

    const eventProps = {
      'New State': isVideoEnabled ? 'On' : 'Off',
      'Num of Participants': getters.activeParticipants.length,
      'Join Type': getters.isGuest ? 'Guest' : 'Application',
      Source: source
    };
    analytics.trackEvent(ANALYTICS.VIDEO_TOGGLED, eventProps);

    if (MeetingManager.publisher) {
      MeetingManager.publisher.publishVideo(state.isVideoEnabled);
    }
  },

  setIsVirtualBackgroundModalVisible: ({ commit }, isVisible) => {
    commit('SET_IS_VIRTUAL_BACKGROUND_MODAL_VISIBLE', isVisible);
  },

  saveVirtualBackground: (
    { state, dispatch, getters },
    { source, virtualBackground }
  ) => {
    if (
      virtualBackground.type === state.virtualBackground.type &&
      virtualBackground.background === state.virtualBackground.background
    ) {
      return;
    }

    logger.log('save-virtual-background', LOG_CATEGORIES.UI_INTERACTION, {
      type: virtualBackground.type
    });
    dispatch('setVirtualBackground', virtualBackground);
    dispatch('settings/saveSettings', {
      initialSettings: {
        virtualBackground
      }
    });

    analytics.trackEvent(ANALYTICS.VIRTUAL_BACKGROUND, {
      Source: source,
      Status:
        virtualBackground.type === VIRTUAL_BACKGROUND_TYPE.NONE ? 'Off' : 'On',
      Type: virtualBackground.type,
      'Join Type': getters.isGuest ? 'Guest' : 'Application'
    });
  },

  selectVirtualBackground: async (
    { dispatch },
    { publisher, currentVirtualBackground, newVirtualBackground }
  ) => {
    logger.log('select-virtual-background', LOG_CATEGORIES.UI_INTERACTION);
    if (
      currentVirtualBackground.type === newVirtualBackground.type &&
      currentVirtualBackground.background === newVirtualBackground.background
    ) {
      return;
    }

    if (!publisher) {
      logger.warning('select-virtual-background', LOG_CATEGORIES.CLIENT_LOGIC, {
        message: 'No publisher'
      });
      return;
    }

    clearTimeout(virtualBackgroundTimeout);
    virtualBackgroundTimeout = setTimeout(() => {
      dispatch('showLongTimeInitVirtualBackgroundMessage');
      virtualBackgroundTimeout = null;
    }, TIME_TO_WAIT_BEFORE_SHOWING_LONG_TIME_INIT_VB_MESSAGE);

    dispatch('setActionInProgress', {
      name: 'virtualBackground',
      inProgress: true
    });

    try {
      const videoFilter = utils.getVideoFilter(newVirtualBackground);
      if (videoFilter) {
        await publisher.applyVideoFilter(videoFilter);
      } else {
        await publisher.clearVideoFilter();
      }
    } catch (error) {
      const flashMessageText =
        error.name === OT_ERROR.NOT_SUPPORTED
          ? i18n.t('store_actions.virtual_background_not_supported_error_text')
          : i18n.t('store_actions.virtual_background_general_error_text');
      dispatch('addFlashMessage', {
        type: 'critical',
        text: flashMessageText
      });
      analytics.trackEvent(
        error.name === OT_ERROR.NOT_SUPPORTED
          ? ANALYTICS.VIRTUAL_BACKGROUND_NOT_SUPPORTED_ERROR
          : ANALYTICS.VIRTUAL_BACKGROUND_GENERAL_ERROR
      );
      throw error;
    } finally {
      dispatch('setActionInProgress', {
        name: 'virtualBackground',
        inProgress: false
      });
    }
  },

  showLongTimeInitVirtualBackgroundMessage: ({ getters, dispatch }) => {
    if (!getters.isInitializingVirtualBackground) {
      return;
    }
    dispatch('addFlashMessage', {
      type: 'critical',
      text: i18n.t('store_actions.virtual_background_long_time_error_text')
    });
    dispatch('setActionInProgress', {
      name: 'virtualBackground',
      inProgress: false
    });
    analytics.trackEvent(ANALYTICS.VIRTUAL_BACKGROUND_LONG_TIME_ERROR);
  },

  kickOutParticipant: async (
    { state, dispatch },
    { participantId, participantDisplayName }
  ) => {
    try {
      await roomService.kickOutParticipant(
        vbcGw.getCredentials().externalId,
        state.sessionId,
        participantId
      );
    } catch (error) {
      dispatch('setErrorDialog', {
        text: i18n.t('store_actions.kickout_error_text', {
          participantDisplayName
        }),
        primaryButtonText: i18n.t('error_modal.remove_participant_btn'),
        primaryButtonIcon: 'remove-user-solid',
        buttonCallback: () =>
          dispatch('kickOutParticipant', {
            participantId,
            participantDisplayName
          })
      });
      return;
    }
    dispatch('addFlashMessage', {
      type: 'good',
      text: i18n.t(
        'remove_participant_modal.kickout_successfully_flash_message',
        {
          participantDisplayName
        }
      )
    });
  },

  forceMuteParticipant: async (
    { state, dispatch },
    { streamId, participantDisplayName }
  ) => {
    logger.log(
      'mute-participant-button-clicked',
      LOG_CATEGORIES.UI_INTERACTION,
      { message: participantDisplayName }
    );
    try {
      await roomService.forceMuteParticipant(state.sessionId, streamId);
    } catch (error) {
      dispatch('setErrorDialog', {
        text: i18n.t('store_actions.force_mute_participant_error_text', {
          participantDisplayName
        }),
        primaryButtonText: i18n.t('error_modal.mute_participant_btn'),
        primaryButtonIcon: 'mic-mute-solid',
        buttonCallback: () =>
          dispatch('forceMuteParticipant', { streamId, participantDisplayName })
      });
    }
    logger.log(
      'mute-participant-button-clicked',
      LOG_CATEGORIES.UI_INTERACTION,
      { message: participantDisplayName }
    );
  },

  forceMuteSession: async ({ getters, state, dispatch }) => {
    try {
      await roomService.forceMuteSession(state.sessionId, [
        getters.myStream.streamId
      ]);
    } catch (error) {
      dispatch('setErrorDialog', {
        text: i18n.t('store_actions.force_mute_all_error_message'),
        primaryButtonText: i18n.t('error_modal.mute_all_btn'),
        primaryButtonIcon: 'mic-mute-solid',
        buttonCallback: () => dispatch('forceMuteSession')
      });
    }
    logger.log('mute-all-button-clicked', LOG_CATEGORIES.UI_INTERACTION);
  },

  setLockMeeting: async ({ state, getters, dispatch }, isLocked = false) => {
    try {
      await roomService.lockSession(
        vbcGw.getCredentials().externalId,
        state.sessionId,
        isLocked
      );
    } catch (err) {
      dispatch('addFlashMessage', {
        type: 'critical',
        text: i18n.t('store_actions.lock_meeting_error_text')
      });
    }

    analytics.trackEvent(ANALYTICS.LOCK, {
      'New State': isLocked,
      'Num of Participants': getters.activeParticipants.length
    });
    logger.log('lock-button-clicked', LOG_CATEGORIES.UI_INTERACTION);
  },

  setSessionIsLocked: ({ commit, dispatch }, isLocked) => {
    dispatch('addFlashMessage', {
      type: 'shoutout',
      text: isLocked
        ? i18n.t('store_actions.locked_meeting_text')
        : i18n.t('store_actions.unlocked_meeting_text')
    });
    commit('SET_LOCK_MEETING', isLocked);
  },

  setSessionJoinApprovalLevel: ({ commit }, joinApprovalLevel) => {
    commit('SET_SESSION_JOIN_APPROVAL_LEVEL', joinApprovalLevel);
  },

  leaveSession: async (
    { state, dispatch },
    { endForAll = false, closeWindow = true }
  ) => {
    if (MeetingManager.session) {
      // solves a bug where ending a meeting during fullscreen (native only) causes the window to freeze
      if (
        isElectron() &&
        window.Electron.toggleFullscreen &&
        state.isFullScreen
      ) {
        window.Electron.toggleFullscreen();
        await wait(1000);
      }
      await dispatch('endSession', endForAll);

      clearInterval(subscriberStatsInterval);
      subscriberStatsInterval = 0;
    }

    if (closeWindow) {
      try {
        window.close();
      } catch (err) {
        // ignore
      }
    }
  },

  setIsTurnOnAudioDialogVisible: ({ commit }, isVisible) =>
    commit('SET_IS_TURN_ON_AUDIO_DIALOG_VISIBLE', isVisible),

  setIsEndMeetingDialogVisible: ({ commit }, isVisible) =>
    commit('SET_IS_END_MEETING_DIALOG_VISIBLE', isVisible),

  setIsInviteParticipantsDialogVisible: ({ commit }, isVisible) =>
    commit('SET_IS_INVITE_PARTICIPANTS_DIALOG_VISIBLE', isVisible),

  setIsLockMeetingDialogVisible: ({ commit }, isVisible) =>
    commit('SET_IS_LOCK_MEETING_DIALOG_VISIBLE', isVisible),

  setParticipantToRemove: ({ commit }, participantToRemove) =>
    commit('SET_PARTICIPANT_TO_REMOVE', participantToRemove),

  setIsHijackScreenshareDialogVisible: ({ commit }, isVisible) =>
    commit('SET_IS_HIJACK_SCREENSHARE_DIALOG_VISIBLE', isVisible),

  setIsMuteAllParticipantsDialogVisible: ({ commit }, isVisible) =>
    commit('SET_IS_MUTE_ALL_PARTICIPANTS_DIALOG_VISIBLE', isVisible),

  setIsSettingsModalVisible: ({ commit }, isVisible) =>
    commit('SET_IS_SETTINGS_MODAL_VISIBLE', isVisible),

  setConfirmationDialog: ({ commit }, confirmation) =>
    commit('SET_CONFIRMATION_DIALOG', confirmation),

  setInfoDialog: ({ commit }, info) => commit('SET_INFO_DIALOG', info),

  setErrorDialog: ({ commit }, error) => commit('SET_ERROR_DIALOG', error),

  reportIssue: async ({ getters, dispatch }, { description, email, tags }) => {
    const properties = getters.feedbackProperties;
    properties.description = description;
    if (email) {
      properties.email = email;
    }

    let reportId = '';
    try {
      reportId = await tokbox.reportIssue();
    } catch (error) {
      logger.error('reportAnIssue', LOG_CATEGORIES.TOKBOX, error);
    }
    try {
      properties.report_id = reportId;
      analytics.trackEvent(ANALYTICS.LEAVE_FEEDBACK, {
        'Num Of Participants': getters.activeParticipants.length,
        Subject: tags[0]
      });
      tags.push(LEAVE_FEEDBACK_LOCATIONS.LEAVE_FEEDBACK_MODAL);
      dispatch('sendDoorbell', {
        description,
        properties,
        email,
        tags
      });
      await roomService.reportAnIssue(properties);
    } catch (err) {
      logger.error('reportAnIssue', LOG_CATEGORIES.API_CALL, err);
      dispatch('addFlashMessage', {
        type: 'critical',
        text: i18n.t('store_actions.report_an_issue_error_text'),
        time: 5000
      });
      return;
    }
    dispatch('addFlashMessage', {
      type: 'good',
      title: i18n.t('report_issue_modal.feedback_sent_flash_title'),
      text: i18n.t('report_issue_modal.feedback_sent_flash_message'),
      customIcon: 'Vlt-icon-heart-full'
    });
  },

  reportRating: async (
    { getters, dispatch },
    { action, reasons, descriptionIssue, summary }
  ) => {
    const eventProps = {
      Action: action,
      Reason: reasons,
      Description: descriptionIssue,
      'Num Of Participants': getters.activeParticipants.length,
      'Join Type': getters.isGuest ? 'Guest' : 'Application'
    };
    analytics.trackEvent(ANALYTICS.RATE_US, eventProps);
    const description = descriptionIssue || summary;
    if (description) {
      const properties = getters.feedbackProperties;
      properties.description = description;
      reasons.push(LEAVE_FEEDBACK_LOCATIONS.THANK_YOU_SCREEN);
      dispatch('sendDoorbell', { description, properties, tags: reasons });
    }
  },

  setupUserDevices: async ({ state, dispatch, commit }) => {
    logger.log('setup-user-devices', LOG_CATEGORIES.DEVICES);
    navigator.mediaDevices?.addEventListener?.('devicechange', () => {
      commit('SET_IS_DEVICES_CHANGED_RECENTLY', true);
      dispatch('updateUserDevices');
      // we use this timeout to wait for handleDisabledDevices to check isDevicesChangedRecently value
      // that we set to be true, after this check we set it back to be false
      setTimeout(() => {
        commit('SET_IS_DEVICES_CHANGED_RECENTLY', false);
      }, 3000);
    });
    await dispatch('setSelectedDevices');
    commit('SET_HAS_MICROPHONE_DEVICES', state.microphoneDevices.length > 0);
    commit('SET_HAS_CAMERA_DEVICES', state.cameraDevices.length > 0);

    // In the native app we can check if we have permissions before calling the getUserMedia
    if (isElectron()) {
      await dispatch('updateDevicesPermissions');
      if (!state.hasMicrophonePermissions) {
        dispatch('showMissingDeviceAccess', DEVICES_TYPES.MICROPHONE);
      } else if (!state.hasCameraPermissions) {
        dispatch('showMissingDeviceAccess', DEVICES_TYPES.CAMERA);
      }
    }

    // Sometimes we are not receiving the built-in mac camera from the devices chrome API
    // So we want to handle it (for now only in Electron)
    dispatch('checkForMissingBuiltInCam');

    store.watch(
      (storeState) => storeState.selectedSpeakerId,
      () => {
        dispatch('updateOutputDevice');
      },
      { immediate: true }
    );
  },

  initMicAndCameraState: ({ state, getters, dispatch }) => {
    const micSetting = getters.isMeetingAlreadyInitialized
      ? sessionStorageService.getItem('mic_enabled') === 'true'
      : state.settings.initialSettings.microphoneEnabled;
    const cameraSetting = getters.isMeetingAlreadyInitialized
      ? sessionStorageService.getItem('camera_enabled') === 'true'
      : !!state.settings.initialSettings.cameraEnabled;

    const blurBackground = {
      type: VIRTUAL_BACKGROUND_TYPE.BLUR,
      background: null
    };
    const virtualBackground = getters.isMeetingAlreadyInitialized
      ? JSON.parse(sessionStorageService.getItem('virtual_background'))
      : state.initialJoinConfig.blurOn && state.initialJoinConfig.skipPreJoin
      ? blurBackground
      : state.settings.initialSettings.virtualBackground;

    let shouldEnableMic =
      micSetting && getters.hasMicrophoneAccess && !state.isNoAudioMode;

    if (shouldEnableMic) {
      let audioDisabledReason = null;

      if (state.optimizePublishers && !window.TEST_MODE) {
        shouldEnableMic = false;
        audioDisabledReason = i18n.t(
          'store_actions.auto_disabled_reason_text',
          { largeMeetingSize: LARGE_MEETING_SIZE }
        );
      } else if (getters.activeParticipants.length >= LARGE_MEETING_SIZE) {
        shouldEnableMic = false;
        audioDisabledReason = i18n.t(
          'store_actions.auto_disabled_shorter_reason_text',
          { largeMeetingSize: LARGE_MEETING_SIZE }
        );
      }

      if (audioDisabledReason) {
        MeetingManager.onSessionConnected(() => {
          dispatch('addFlashMessage', {
            type: 'shoutout',
            customIcon: 'Vlt-icon-microphone-mute-full',
            text: audioDisabledReason
          });
        });
      }
    }

    dispatch('setMicEnabled', shouldEnableMic);
    dispatch('toggleLoudnessDetector');

    dispatch(
      'setVideoEnabled',
      cameraSetting &&
        getters.hasCameraAccess &&
        (!state.optimizePublishers || window.TEST_MODE)
    );

    if (getters.isVirtualBackgroundSupported && virtualBackground) {
      dispatch('setVirtualBackground', virtualBackground);
    }
  },

  // we call this action for every device change so we use debounce.
  updateUserDevices: debounce(async ({ state, commit, dispatch }) => {
    const {
      microphoneDevices,
      cameraDevices,
      speakerDevices
    } = await getDevices({ updateDevices: true });
    logger.log('update-user-devices', LOG_CATEGORIES.DEVICES, {
      microphoneDevices,
      cameraDevices,
      speakerDevices
    });

    await dispatch('alertOnSelectedDeviceChange', {
      microphoneDevices,
      cameraDevices,
      speakerDevices
    });

    const isRemovedSelectedMic = isSelectedDeviceRemoved(
      state.selectedMicrophoneId,
      microphoneDevices
    );
    const isRemovedSelectedCam = isSelectedDeviceRemoved(
      state.selectedCameraId,
      cameraDevices
    );
    const isRemovedSelectedSpeaker = isSelectedDeviceRemoved(
      state.selectedSpeakerId,
      speakerDevices
    );

    if (isRemovedSelectedMic) {
      const device = getDefaultDevice(microphoneDevices);
      await dispatch('selectMicrophoneDevice', device);
    } else if (isDefaultDeviceId(state.selectedMicrophoneId)) {
      // On Windows - if default/communications device is selected we need to update the stream
      // that there is a new default device (with 'default' as the device id)
      await dispatch('selectMicrophoneDevice', state.selectedMicrophoneId);
    }
    if (isRemovedSelectedCam) {
      if (cameraDevices.length > 0) {
        const newCameraId = cameraDevices[0].deviceId;
        await dispatch('selectCameraDevice', newCameraId);
      } else {
        commit('SET_SELECTED_CAMERA_ID', '');
        commit('SET_CURRENT_CAMERA_ID', '');
      }
    }
    if (isRemovedSelectedSpeaker) {
      await dispatch('selectSpeakerDevice', getDefaultDevice(speakerDevices));
    }

    commit('SET_MICROPHONE_DEVICES', microphoneDevices);
    commit('SET_CAMERA_DEVICES', cameraDevices);
    commit('SET_SPEAKER_DEVICES', speakerDevices);
  }, 1000),

  checkForMissingBuiltInCam: async ({ getters, dispatch }) => {
    // Check for missing built-in cam only in Mac Electron - Alpha
    if (
      isElectron() &&
      !window.isProduction &&
      OS.name === OS_TYPES.MAC &&
      window.Electron.getCameraDevices &&
      !getters.hasMacBuiltInCam
    ) {
      try {
        const camDevicesStrings = await window.Electron.getCameraDevices();
        const foundBuiltInCam = camDevicesStrings.some((camDeviceStr) =>
          camDeviceStr.includes(MAC_BUILT_IN_CAMERA_NAME)
        );
        if (foundBuiltInCam) {
          logger.warning('missing-mac-built-in-cam', LOG_CATEGORIES.DEVICES);
          dispatch('setConfirmationDialog', {
            title: i18n.t('camera_not_detected_modal.title'),
            text: i18n.t('camera_not_detected_modal.text'),
            okButtonText: i18n.t(
              'camera_not_detected_modal.restart_button_text'
            ),
            actionName: 'restartVBC'
          });
        }
      } catch (err) {
        logger.error('getCameraDevices-Electron', LOG_CATEGORIES.DEVICES, err);
      }
    }
  },

  alertOnSelectedDeviceChange: async (
    { state, dispatch },
    { microphoneDevices, cameraDevices, speakerDevices }
  ) => {
    const microphoneDevicesData = {
      newDevices: microphoneDevices,
      oldDevices: state.microphoneDevices,
      selectedDeviceId: state.selectedMicrophoneId,
      deviceName: i18n.t('store_actions.microphone_device_name')
    };

    const cameraDevicesData = {
      newDevices: cameraDevices,
      oldDevices: state.cameraDevices,
      selectedDeviceId: state.selectedCameraId,
      deviceName: i18n.t('store_actions.camera_device_name')
    };

    const speakerDevicesData = {
      newDevices: speakerDevices,
      oldDevices: state.speakerDevices,
      selectedDeviceId: state.selectedSpeakerId,
      deviceName: i18n.t('store_actions.speaker_device_name')
    };

    const changedSelectedDevicesTypes = getChangedSelectedDevicesTypes([
      microphoneDevicesData,
      cameraDevicesData,
      speakerDevicesData
    ]);

    if (changedSelectedDevicesTypes.length > 0) {
      changedSelectedDevicesTypes.forEach((device) => {
        dispatch('addFlashMessage', {
          type: 'tip',
          text: i18n.t('store_actions.source_was_changed', {
            device
          })
        });
      });

      logger.log(
        'The current device of the user changed (plugged out or system default changed)',
        LOG_CATEGORIES.DEVICES,
        { devicesType: changedSelectedDevicesTypes }
      );

      analytics.trackEvent(ANALYTICS.DEVICE_CHANGED, {
        deviceType: changedSelectedDevicesTypes
      });
    }
  },

  getUserDevices: async ({ commit }) => {
    const {
      microphoneDevices,
      cameraDevices,
      speakerDevices
    } = await getDevices({ updateDevices: true });

    logger.log('get-user-devices (init devices)', LOG_CATEGORIES.DEVICES, {
      microphoneDevices,
      cameraDevices,
      speakerDevices
    });

    commit('SET_MICROPHONE_DEVICES', microphoneDevices);
    commit('SET_CAMERA_DEVICES', cameraDevices);
    commit('SET_SPEAKER_DEVICES', speakerDevices);
  },

  setSelectedDevices: async ({ state, commit, dispatch }) => {
    await dispatch('getUserDevices');

    const defaultCameraId = getDefaultDevice(state.cameraDevices);
    const defaultMicrophoneId = getDefaultDevice(state.microphoneDevices);
    const defaultSpeakerId = getDefaultDevice(state.speakerDevices);

    commit('SET_SELECTED_CAMERA_ID', defaultCameraId);
    commit('SET_CURRENT_CAMERA_ID', defaultCameraId);
    commit('SET_SELECTED_MICROPHONE_ID', defaultMicrophoneId);
    commit('SET_SELECTED_SPEAKER_ID', defaultSpeakerId);

    const selectedMicrophoneId =
      state.settings.initialSettings.selectedMicrophoneId;
    if (selectedMicrophoneId?.length) {
      dispatch('selectMicrophoneDevice', selectedMicrophoneId);
    }

    const selectedCameraId = state.settings.initialSettings.selectedCameraId;
    if (selectedCameraId?.length) {
      dispatch('selectCameraDevice', selectedCameraId);
    }

    const selectedSpeakerId = state.settings.initialSettings.selectedSpeakerId;
    if (selectedSpeakerId?.length) {
      dispatch('selectSpeakerDevice', selectedSpeakerId);
    }

    logger.log('set-selected-devices', LOG_CATEGORIES.CLIENT_LOGIC, {
      microphoneId: selectedMicrophoneId?.length
        ? selectedMicrophoneId
        : defaultMicrophoneId,
      cameraId: selectedCameraId?.length ? selectedCameraId : defaultCameraId,
      speakerId: selectedSpeakerId?.length
        ? selectedSpeakerId?.length
        : defaultSpeakerId
    });
  },

  selectMicrophoneDevice: async ({ state, commit, dispatch }, microphoneId) => {
    const currentMicId = state.selectedMicrophoneId;
    if (
      state.microphoneDevices.some((device) => device.deviceId === microphoneId)
    ) {
      commit('SET_SELECTED_MICROPHONE_ID', microphoneId);
      if (MeetingManager.publisher) {
        if (currentMicId !== microphoneId || isDefaultDeviceId(microphoneId)) {
          try {
            await MeetingManager.publisher.setAudioSource(microphoneId);
          } catch (err) {
            commit('SET_SELECTED_MICROPHONE_ID', currentMicId);
            logger.error('select-microphone', LOG_CATEGORIES.CLIENT_LOGIC, err);
          }
        }
      }

      dispatch('settings/saveSettings', {
        initialSettings: { selectedMicrophoneId: state.selectedMicrophoneId }
      });
    } else {
      dispatch('settings/saveSettings', {
        initialSettings: {
          selectedMicrophoneId: getDefaultDevice(state.microphoneDevices)
        }
      });
    }
  },

  cycleCamera: async ({ commit }) => {
    try {
      const selectedCameraId = await MeetingManager.publisher.cycleVideo();
      commit('SET_SELECTED_CAMERA_ID', selectedCameraId);
      commit('SET_CURRENT_CAMERA_ID', selectedCameraId);
    } catch (err) {
      logger.error('cycle-camera', LOG_CATEGORIES.CLIENT_LOGIC, err);
      // TODO: Consider showing the user a message
    }
  },

  selectCameraDevice: async ({ state, commit, dispatch }, cameraId) => {
    const currentCameraId = state.selectedCameraId;

    if (state.cameraDevices.some((device) => device.deviceId === cameraId)) {
      commit('SET_SELECTED_CAMERA_ID', cameraId);

      if (MeetingManager.publisher && currentCameraId !== cameraId) {
        try {
          await MeetingManager.publisher.setVideoSource(cameraId);
          commit('SET_CURRENT_CAMERA_ID', cameraId);
        } catch (err) {
          commit('SET_SELECTED_CAMERA_ID', currentCameraId);
          logger.error('select-camera', LOG_CATEGORIES.CLIENT_LOGIC, err);
        }
      } else {
        commit('SET_CURRENT_CAMERA_ID', cameraId);
      }

      dispatch('settings/saveSettings', {
        initialSettings: { selectedCameraId: state.selectedCameraId }
      });
    } else {
      dispatch('settings/saveSettings', {
        initialSettings: {
          selectedCameraId: getDefaultDevice(state.cameraDevices)
        }
      });
    }
  },

  selectSpeakerDevice: ({ state, commit, dispatch }, speakerId) => {
    if (state.speakerDevices.some((device) => device.deviceId === speakerId)) {
      // ParticipantsTileList watch this property and update all video elements
      commit('SET_SELECTED_SPEAKER_ID', speakerId);

      dispatch('settings/saveSettings', {
        initialSettings: { selectedSpeakerId: state.selectedSpeakerId }
      });
    } else {
      dispatch('settings/saveSettings', {
        initialSettings: {
          selectedSpeakerId: getDefaultDevice(state.speakerDevices)
        }
      });
    }
  },

  openWebRTCInternals: () => {
    if (window.Electron.openWebRTCInternals) {
      window.Electron.openWebRTCInternals();
    } else if (electronWebapp.sendMessage_openWebRTCInternals) {
      electronWebapp.sendMessage_openWebRTCInternals();
    }
  },

  // Still have some bugs
  // Modal background hides flash messages
  addFlashMessage: ({ commit, getters }, options) => {
    if (getters.isFlashMessagesDisabled) {
      return;
    }
    // Set Default Options
    options.sm = !!options.sm;
    options.type = options.type || 'good';
    options.time = options.time || 4000;
    options.dismiss = options.dismiss || true;
    options.id = options.id || new Date().getTime() + Math.random(); // Using the current time as a unique key for the message

    commit('ADD_FLASH_MESSAGE', options);
  },

  removeFlashMessage: ({ commit }, options) =>
    commit('REMOVE_FLASH_MESSAGE', options),

  setIsChatToggledByUser: ({ commit }, isToggledByUser) => {
    commit('SET_IS_CHAT_TOGGLED_BY_USER', isToggledByUser);
  },

  setIsMeetingInfoOpened: ({ commit }, isOpened) => {
    commit('SET_MEETING_INFO_OPENED', isOpened);
  },

  selectContactToInvite: ({ commit }, { id, selected }) => {
    commit('UPDATE_CONTACTS_INVITE_STATE', {
      invitees: [id],
      inviteState: selected ? 'selected' : ''
    });
  },

  showMissingDeviceAccess: ({ state, getters, dispatch }, deviceType) => {
    if (!state.hasCameraDevices && deviceType === DEVICES_TYPES.CAMERA) {
      dispatch('setInfoDialog', {
        title: i18n.t('store_actions.no_camera_detected_title'),
        text: i18n.t('store_actions.no_camera_detected_text')
      });
    } else if (
      !state.hasMicrophoneDevices &&
      deviceType === DEVICES_TYPES.MICROPHONE
    ) {
      dispatch('setInfoDialog', {
        title: i18n.t('store_actions.no_microphone_detected_title'),
        text: i18n.t('store_actions.no_microphone_detected_text')
      });
    } else if (
      isElectron() &&
      (!state.hasCameraPermissions ||
        !state.hasMicrophonePermissions ||
        getters.hasSystemPermissionsError)
    ) {
      dispatch('openSystemSettings', deviceType);
    } else if (state.publisherError) {
      dispatch('setInfoDialog', {
        title: state.publisherError.title,
        text: state.publisherError.message
      });
    } else {
      dispatch('setInfoDialog', {
        title: i18n.t('store_actions.missing_permissions_title'),
        text:
          deviceType === DEVICES_TYPES.CAMERA
            ? i18n.t('store_actions.enable_camera_permissions_text')
            : deviceType === DEVICES_TYPES.MICROPHONE
            ? i18n.t('store_actions.enable_microphone_permissions_text')
            : i18n.t('store_actions.enable_screen_permissions_text')
      });
    }
  },

  toggleSidebarAnimation: ({ commit }, inProgress) => {
    commit('SET_ACTION_IN_PROGRESS', { name: 'toggleSidebar', inProgress });
  },

  setActionInProgress: ({ commit }, action) => {
    commit('SET_ACTION_IN_PROGRESS', action);
  },

  setSelectedDialInNumbers: ({ commit }, selectedNumbers) =>
    commit('SET_SELECTED_DIAL_IN_NUMBERS', selectedNumbers),

  updateDevicesPermissions: async ({ commit, dispatch }) => {
    let permissions;
    // On MacOS in the native app we can use Electron api to check the permissions instead of using the browser's API.
    if (isElectron() && OS.name === OS_TYPES.MAC) {
      permissions = {
        hasCameraPermissions: await dispatch(
          'askForMediaAccess',
          DEVICES_TYPES.CAMERA
        ),
        hasMicrophonePermissions: await dispatch(
          'askForMediaAccess',
          DEVICES_TYPES.MICROPHONE
        )
      };
    } else if (
      isElectron() &&
      OS.name === OS_TYPES.WINDOWS &&
      isMinimalNativeVersion('2.9.0')
    ) {
      const [camPermissions, micPermissions] = await Promise.all([
        dispatch('getMediaAccessStatus', DEVICES_TYPES.CAMERA),
        dispatch('getMediaAccessStatus', DEVICES_TYPES.MICROPHONE)
      ]);
      permissions = {
        hasCameraPermissions: camPermissions === MEDIA_ACCESS_STATUS.GRANTED,
        hasMicrophonePermissions: micPermissions === MEDIA_ACCESS_STATUS.GRANTED
      };
    } else {
      permissions = await getPermissions({ updateDevices: true });
    }
    commit('SET_HAS_CAMERA_PERMISSIONS', permissions.hasCameraPermissions);
    commit(
      'SET_HAS_MICROPHONE_PERMISSIONS',
      permissions.hasMicrophonePermissions
    );
  },

  logDuration: async (_, { action, logName, logSource, threshold = 0 }) => {
    const start = performance.now();
    const result = await action();
    const duration = performance.now() - start;
    if (duration >= threshold) {
      logger.log(logName, logSource, {
        duration
      });
    }
    return result;
  },

  initPublisherOptimizer: ({ state, getters, commit }) => {
    store.watch(
      (_, storeGetters) => storeGetters.activeParticipants.length,
      () => {
        // When optimizePublishers turn to true, it won't change back to false event if the number of active participants will decrease.
        if (
          !state.optimizePublishers &&
          getters.activeParticipants.length >=
            NUMBER_OF_PARTICIPANTS_FOR_PUBLISHERS_OPTIMIZATION &&
          !window.TEST_MODE
        ) {
          commit('SET_OPTIMIZE_PUBLISHERS', true);
        }
      },
      { immediate: true }
    );
  },

  setStreamSubscriberStatus: ({ commit }, streamAndStatus) => {
    logger.log('stream-subscriber-status', LOG_CATEGORIES.TOKBOX, {
      streamAndStatus
    });
    commit('SET_STREAM_SUBSCRIBER_STATUS', streamAndStatus);
  },

  pinVideoStream: ({ commit, dispatch }, streamId) => {
    let streamIdToPin = '';

    if (streamId) {
      dispatch('layout/changeLayoutOnPin');
      streamIdToPin = streamId;
      logger.log('pin-video-stream', LOG_CATEGORIES.CLIENT_LOGIC, {
        streamId
      });
    }
    commit('PIN_STREAM', streamIdToPin);
  },

  initTour: ({ commit }) => {
    const lastFinishedTourVersion = localStorageService.getItem(
      'lastFinishedTour'
    );
    const lastAvailableTour = versionSteps[versionSteps.length - 1];

    if (
      lastAvailableTour &&
      compareVersions(lastAvailableTour.version, lastFinishedTourVersion) > 0
    ) {
      commit('SET_TOUR_STEPS', lastAvailableTour.steps);
      commit('SET_NUM_OF_TOUR_STEPS', lastAvailableTour.steps.length);
    }
  },

  removeTourStep: ({ state, commit }) => {
    commit('REMOVE_TOUR_STEP');

    if (!state.tourSteps.length) {
      localStorageService.setItem(
        'lastFinishedTour',
        process.env.VUE_APP_VERSION
      );
    }
  },

  changeKeepActionsBarUp({ commit }, keep) {
    commit('KEEP_ACTIONS_BAR_UP', keep);
  },

  changeKeepParticipantOptionsMenuShown({ commit }, keep) {
    commit('KEEP_PARTICIPANT_OPTIONS_MENU_SHOWN', keep);
  },

  // This action is called only for the native app and not for the browser.
  openSystemSettings: async ({ dispatch }, deviceType) => {
    const deiceTypeKey = deviceType || 'all';
    const DEVICE_TYPE_TEXT = {
      [DEVICES_TYPES.CAMERA]: 'Camera',
      [DEVICES_TYPES.MICROPHONE]: 'Microphone',
      [DEVICES_TYPES.SCREEN]: 'Screen share',
      all: 'Camera/Microphone'
    };
    const SETTINGS_DEVICE_PATH_TEXT = {
      [DEVICES_TYPES.CAMERA]: `${SYSTEM_PRIVACY_PATH[OS.name]} > Camera`,
      [DEVICES_TYPES.MICROPHONE]: `${
        SYSTEM_PRIVACY_PATH[OS.name]
      } > Microphone`,
      [DEVICES_TYPES.SCREEN]: `${
        SYSTEM_PRIVACY_PATH[OS.name]
      } > Screen Recording`,
      all: `${SYSTEM_PRIVACY_PATH[OS.name]} > Camera/Microphone`
    };
    let message = `To allow ${DEVICE_TYPE_TEXT[deiceTypeKey]} access for Vonage Business go to "${SETTINGS_DEVICE_PATH_TEXT[deiceTypeKey]}" and check the box next to Vonage Business. Once it is checked, relaunch Vonage Business app.`;
    const isOpened = await dispatch('openPermissionsDialog', {
      options: {
        type: 'warning',
        buttons: ['Got it', 'Allow Access'],
        defaultId: 1,
        title: `Missing permissions`,
        message
      },
      deviceType
    });

    if (!isOpened) {
      dispatch('setInfoDialog', {
        title: 'Missing permissions',
        text: message
      });
    }
  },

  setActiveSidebar: ({ commit }, sidebar) => {
    commit('SET_ACTIVE_SIDEBAR', sidebar);
  },

  endSession: async ({ state, dispatch }, endForAll = false) => {
    if (endForAll) {
      try {
        await roomService.endSession(
          vbcGw.getCredentials().externalId,
          state.sessionId
        );
      } catch (err) {
        dispatch('addFlashMessage', {
          type: 'critical',
          text: i18n.t('store_actions.end_session_error_text')
        });
        throw err;
      }
    } else {
      try {
        await MeetingManager.disconnectFromSession();
      } catch (error) {
        logger.error('tb_session-disconnect_error', LOG_CATEGORIES.TOKBOX, {
          tb_call: 'session.connect()',
          error
        });
      }
    }

    sessionStorageService.clear();
  },

  readContacts: async ({ commit }) => {
    const lastModified = await cachingService.getContactsLastModified();

    // TODO: handle error, show some UI that we could not read contacts? add option to retry?
    const response = await usersApi.readContacts(
      vbcGw.getCredentials().externalId,
      lastModified
    );
    const newContacts = response.contacts
      .filter((contact) => {
        const isOffnet =
          contact.labels.indexOf('CAB') < 0 ||
          contact.labels.indexOf('MAB') >= 0;
        const isBot = contact.labels.indexOf('BOT') >= 0; // filter Vee
        return !isOffnet && !isBot;
      })
      .map((contact) => {
        const extension = contact.addresses.find((ext) => ext.type === 'EXT');

        return {
          displayName: contact.displayName,
          id: contact.cuid,
          firstName: contact.firstName,
          lastName: contact.lastName,
          extensionNumber: extension?.value,
          profilePicture: contact?.profile?.profilePicture || '',
          inviteState: ''
        };
      });

    let contacts = await cachingService.getContacts();
    if (contacts) {
      const contactsIndexesById = contacts.reduce((acc, contact, i) => {
        acc[contact.id] = i;
        return acc;
      }, {});

      // update contacts
      newContacts.forEach((contact) => {
        if (contactsIndexesById[contact.id]) {
          contacts[contactsIndexesById[contact.id]] = contact;
        } else {
          contacts.push(contact);
        }
      });

      // delete contacts
      response.deletions?.forEach((id) => {
        const index = contacts.findIndex((contact) => contact.id === id);
        if (index >= 0) {
          contacts.splice(index, 1);
        }
      });
    } else {
      contacts = newContacts;
    }
    sort(contacts, (contactA, contactB) =>
      contactA.displayName.localeCompare(contactB.displayName)
    );

    const latestModification =
      Math.max(
        ...response.contacts.map((contact) => contact.revision || 0),
        response.lastModified
      ) + 1;

    commit('SET_CONTACTS', contacts);
    await cachingService.setContacts(contacts, latestModification);
  },

  clearContacts: async ({ commit }) => {
    await cachingService.clearContactsCache();
    commit('RESET_CONTACTS');
  },

  inviteContacts: async ({ state, commit, dispatch, getters }, invitees) => {
    if (
      invitees.length === 1 &&
      getters.activeParticipants.find(
        (participant) => participant.participantId === invitees[0]
      )
    ) {
      dispatch('addFlashMessage', {
        type: 'good',
        text: i18n.t('store_actions.contact_already_in_text')
      });
      return;
    }
    commit('UPDATE_CONTACTS_INVITE_STATE', {
      invitees,
      inviteState: 'pending'
    });
    try {
      await roomService.inviteContacts(
        vbcGw.getCredentials().externalId,
        state.sessionId,
        invitees
      );
      dispatch('addFlashMessage', {
        type: 'good',
        text: i18n.tc('store_actions.invite_contacts_text', invitees.length)
      });
      commit('UPDATE_CONTACTS_INVITE_STATE', { invitees, inviteState: '' });
    } catch (err) {
      dispatch('addFlashMessage', {
        type: 'critical',
        text: i18n.t('store_actions.invite_contacts_error_text')
      });
      commit('UPDATE_CONTACTS_INVITE_STATE', {
        invitees,
        inviteState: 'failed'
      });
    }
  },

  inviteOffnetParticipant: async ({ state, getters, dispatch }) => {
    const message = `Join my video call on the following link:\n${getters.roomUrl}\nMeeting ID: ${getters.roomToken}`;
    try {
      await Promise.all(
        state.offnetInviteData.to.map(async (toNumber) => {
          return dispatch('messaging/sendOffNetMessage', {
            to: toNumber,
            from: state.offnetInviteData.from,
            body: message,
            isBusinessInbox: state.offnetInviteData.is_bi
          });
        })
      );

      dispatch('addFlashMessage', {
        type: 'good',
        text: i18n.t('store_actions.invite_guests_text')
      });
    } catch {
      dispatch('addFlashMessage', {
        type: 'critical',
        text: i18n.t('store_actions.invite_guests_error_text')
      });
    }
  },

  getMediaAccessStatus: (_, deviceType) => {
    if (window.Electron.getMediaAccessStatus) {
      return window.Electron.getMediaAccessStatus(deviceType);
    } else if (electronWebapp.sendMessage_getMediaAccessStatus) {
      return electronWebapp.sendMessage_getMediaAccessStatus(deviceType);
    }
    return MEDIA_ACCESS_STATUS.GRANTED;
  },

  askForMediaAccess: (_, deviceType) => {
    // In case the user did not update his VBC and does not have the askForMediaAccess API
    if (window.Electron.askForMediaAccess) {
      return window.Electron.askForMediaAccess(deviceType);
    } else if (electronWebapp.sendMessage_askForMediaAccess) {
      return electronWebapp.sendMessage_askForMediaAccess(deviceType);
    }
    return true;
  },

  openPermissionsDialog: async (_, { options, deviceType }) => {
    try {
      const BUTTONS = { CANCEL: 0, SETTINGS: 1 };
      if (window.Electron.openDialogMessage) {
        const { response } = await window.Electron.openDialogMessage(options);
        if (response === BUTTONS.SETTINGS) {
          window.Electron.openSettings(deviceType);
        }
        return true;
      } else if (electronWebapp.sendMessage_openDialog) {
        const { response } = await electronWebapp.sendMessage_openDialog(
          options
        );
        if (response === BUTTONS.SETTINGS) {
          electronWebapp.sendMessage_openSettings(deviceType);
        }
        return true;
      }
    } catch (error) {
      logger.error('openPermissionsDialog', LOG_CATEGORIES.DEVICES, error);
    }
    return false;
  },

  initAnalytics: ({ state, getters }) => {
    const groupProps = [
      { key: 'Account ID', value: `${state.userInfo.accountId}` }
    ];
    const userProps = [
      { key: 'User Role', value: state.userInfo.userType },
      { key: 'VBC User ID', value: state.userInfo.userId }
    ];

    const analyticsUserId = getters.isGuest ? null : state.userInfo.loginName;

    try {
      analytics.init(analyticsUserId, userProps, groupProps);
    } catch (e) {
      // do nothing
    }
  },

  setSessionAnalytics: ({ state, getters }) => {
    const meetingId = state.sessionId;
    const roomName = getters.roomDisplayName;
    const domain = state.roomDetails.domain;
    try {
      analytics.updateSessionProps(meetingId, roomName, domain);
    } catch (e) {
      // do nothing
    }
  },

  initAppcues: ({ state, getters }) => {
    if (!getters.isGuest && !getters.isMobileWebMode) {
      const appcuesUserProps = {
        accountId: `${state.userInfo.accountId}`,
        firstName: state.userInfo.firstName,
        lastName: state.userInfo.lastName,
        loginName: state.userInfo.loginName,
        email: state.userInfo.email,
        userType: state.userInfo.userType,
        isGuest: getters.isGuest,
        isOwner: getters.isSessionOwner,
        application: 'Meetings',
        meetingsVersion: process.env.VUE_APP_VERSION,
        gitRevision: process.env.VUE_APP_GIT_REVISION
      };
      window.Appcues?.identify(state.userInfo.userId, appcuesUserProps);

      store.watch(
        (_, storeGetters) => storeGetters.isSessionOwner,
        () =>
          window.Appcues?.identify(state.userInfo.userId, {
            isOwner: getters.isSessionOwner
          })
      );

      analytics.enableAppcues();
    }
  },

  // Max 1 sound indication per second
  playParticipantStateSoundIndication: throttle(
    ({ state, getters }, update) => {
      if (
        state.settings.playSoundOnJoinOrLeave &&
        !getters['settings/disableJoinOrLeaveSound'] &&
        update.participantId !== state.myParticipantId &&
        !state.isNoAudioMode
      ) {
        if (update.state === PARTICIPANT_STATE_TYPES.JOINED) {
          SoundsPlayer.playParticipantJoinedSound(state.selectedSpeakerId);
        } else if (update.state === PARTICIPANT_STATE_TYPES.LEFT) {
          SoundsPlayer.playParticipantLeftSound(state.selectedSpeakerId);
        }
      }
    },
    1000,
    { trailing: false }
  ),

  // Max 1 sound indication per 10 seconds
  playWaitingRoomSoundIndication: throttle(
    ({ state }) => {
      SoundsPlayer.playWaitingRoomNotification(state.selectedSpeakerId);
    },
    10 * 1000,
    { trailing: false }
  ),

  sendDoorbell: ({ state, getters }, payload = {}) => {
    if (
      window.doorbell &&
      window.doorbell.send &&
      payload.description &&
      process.env.NODE_ENV === 'production'
    ) {
      const credentials = vbcGw.getCredentials();
      let moreProperties;
      let environmentTag = window.isProduction ? 'Prod' : 'Alpha';
      let platformTag = isElectron() ? 'Native' : 'Web';
      const tags = ['Meetings', platformTag, environmentTag, OS.name];
      if (payload.tags) {
        tags.push(...payload.tags);
      }

      if (!getters.isGuest) {
        moreProperties = {
          Username: state.userInfo.loginName,
          FullName: getters.userDisplayName,
          AccountId: state.userInfo.accountId,
          GitRevision: process.env.VUE_APP_GIT_REVISION,
          AppVersion: process.env.VUE_APP_VERSION,
          Extension: credentials.extension,
          IsGuest: getters.isGuest
        };
      } else {
        moreProperties = {
          GitRevision: process.env.VUE_APP_GIT_REVISION,
          AppVersion: process.env.VUE_APP_VERSION,
          IsGuest: getters.isGuest,
          GuestId: credentials.externalId,
          GuestName: getters.participantDisplayName
        };
      }
      window.doorbell.setOption('tags', tags);
      window.doorbell.setOption('properties', {
        ...moreProperties,
        ...payload.properties
      });
      window.doorbell.refresh();
      window.doorbell.send(
        payload.description,
        state.userInfo.email || payload.email || 'guest@guest.co.il'
      );
    }
  },

  handleNetworkDisconnectedEvent: async ({ state, dispatch }) => {
    let session;
    let isActive;
    try {
      session = await roomService.getSessionById(
        state.sessionId,
        TIMEOUTS.GET_SESSION_REQUEST_TIMEOUT
      );
      isActive = !!(session.start_time && !session.end_time);
      dispatch('updateSession', session);
      logger.log(
        'handleNetworkDisconnectedEvent',
        LOG_CATEGORIES.CLIENT_LOGIC,
        {
          is_session_active: isActive
        }
      );
    } catch (err) {
      logger.log(
        'handleNetworkDisconnectedEvent',
        LOG_CATEGORIES.CLIENT_LOGIC,
        {
          reason: 'Network issue'
        }
      );
    }

    // If the session is not active on networkDisconnected event, keep showing the ended meeting screen.
    // Otherwise - switch to disconnected message screen.
    if (!session || isActive) {
      dispatch('showDisconnectedMessage');
    }
  },

  // TODO: move global messages object param to a const file
  showDisconnectedMessage: ({ dispatch }) => {
    return dispatch('showNetworkIssueMessage', {
      title: { i18nKey: 'store_actions.show_network_issue_title' },
      message: { i18nKey: 'store_actions.show_network_issue_message' },
      buttonText: { i18nKey: 'store_actions.show_network_issue_button_text' },
      buttonCallback: () => dispatch('reconnectToSession'),
      errorText: { i18nKey: 'store_actions.show_network_issue_error_text' },
      image: MESSAGE_SCREEN_ILLUSTRATIONS.NETWORK_ISSUES,
      brandedIcon: 'wifi-line',
      showVonageLogo: false
    });
  },

  showJoinBeforeHostErrorMessage: (
    { getters, dispatch },
    { retryCallback }
  ) => {
    return dispatch('showGlobalMessage', {
      title: { i18nKey: 'store_actions.join_before_host_error_title' },
      message: {
        i18nKey: 'store_actions.join_before_host_error_message',
        params: {
          roomDisplayName: getters.roomDisplayName
        }
      },
      buttonText: {
        i18nKey: 'store_actions.join_before_host_error_button_text'
      },
      buttonCallback: retryCallback,
      minimumLoadingTime: 1000,
      reason: MESSAGE_SCREEN_REASON.CANNOT_JOIN_BEFORE_HOST,
      image: MESSAGE_SCREEN_ILLUSTRATIONS.JOIN_BEFORE_HOST,
      brandedIcon: 'clock-line',
      showVonageLogo: false
    });
  },

  showLockedMessage: ({ dispatch }) => {
    return dispatch('showGlobalMessage', {
      title: { i18nKey: 'store_actions.show_locked_meeting_title' },
      message: { i18nKey: 'store_actions.show_locked_meeting_message' },
      reason: MESSAGE_SCREEN_REASON.LOCKED_SESSION,
      image: MESSAGE_SCREEN_ILLUSTRATIONS.MEETING_IS_LOCKED,
      brandedIcon: 'lock-line',
      showVonageLogo: false
    });
  },

  showSessionIsFullMessage: ({ dispatch }) => {
    return dispatch('showGlobalMessage', {
      title: { i18nKey: 'store_actions.show_session_is_full_title' },
      message: { i18nKey: 'store_actions.show_session_is_full_message' },
      reason: MESSAGE_SCREEN_REASON.FULL_SESSION,
      image: MESSAGE_SCREEN_ILLUSTRATIONS.MAX_CAPACITY_REACHED,
      brandedIcon: 'group-3-line',
      showVonageLogo: false
    });
  },

  showParticipantIsKickedMessage: ({ state, dispatch }) => {
    sessionStorageService.clear();
    return dispatch('showGlobalMessage', {
      title: { i18nKey: 'store_actions.show_participant_is_kicked_title' },
      message: state.isBranded
        ? {
            i18nKey: 'store_actions.show_participant_is_kicked_branded_message'
          }
        : { i18nKey: 'store_actions.show_participant_is_kicked_message' },
      reason: MESSAGE_SCREEN_REASON.PARTICIPANT_KICKED,
      image: MESSAGE_SCREEN_ILLUSTRATIONS.HOST_REMOVED_YOU,
      brandedIcon: 'remove-user-line',
      showVonageLogo: false
    });
  },

  showInitMeetingFailedMessage: ({ dispatch }, { retryCallback }) => {
    return dispatch('showNetworkIssueMessage', {
      title: { i18nKey: 'store_actions.show_init_meeting_failed_title' },
      message: { i18nKey: 'store_actions.show_init_meeting_failed_text' },
      buttonText: {
        i18nKey: 'store_actions.show_init_meeting_failed_button_text'
      },
      buttonCallback: retryCallback,
      errorText: {
        i18nKey: 'store_actions.show_init_meeting_failed_error_text'
      },
      image: MESSAGE_SCREEN_ILLUSTRATIONS.TECHNICAL_DIFFICULTIES,
      brandedIcon: 'wrench-tool-line',
      showVonageLogo: false
    });
  },

  showMeetingHasAlreadyEndedMessage: ({ state, dispatch }) => {
    return dispatch('showGlobalMessage', {
      title: { i18nKey: 'store_actions.show_meeting_has_already_ended_title' },
      message: state.isBranded
        ? {
            i18nKey:
              'store_actions.show_meeting_has_already_ended_branded_message'
          }
        : { i18nKey: 'store_actions.show_meeting_has_already_ended_message' },
      reason: MESSAGE_SCREEN_REASON.SESSION_ALREADY_ENDED,
      image: MESSAGE_SCREEN_ILLUSTRATIONS.MEETING_ENDED,
      brandedIcon: 'sofa-line',
      showVonageLogo: false
    });
  },

  showLeftMeetingMessage: ({ state, commit, getters, dispatch }) => {
    analytics.trackEvent(ANALYTICS.THANK_YOU_SCREEN, {
      'Meeting ID': state.sessionId,
      'Join Type': getters.isGuest ? 'Guest' : 'Application'
    });

    dispatch('loudnessDetector/turnMuteIndicationOff');
    dispatch('loudnessDetector/turnLoudnessDetectorOff');

    commit('SET_SHOW_THANK_YOU_SCREEN', true);

    if (router.currentRoute.name !== 'ThankYouScreen') {
      dispatch('replaceRoute', { name: 'ThankYouScreen' });
    }

    // Whenever showing a global message we should stop preventing the screen from dimming/locking
    if (getters.isMobileWebMode) {
      stopPreventingMobileLock();
    }

    sessionStorageService.clear();
  },

  showOpenedInDesktopMessage: ({ dispatch }, isCsp) => {
    if (isCsp) {
      return dispatch('showGlobalMessage', {
        title: { i18nKey: 'store_actions.show_opened_in_desktop_title' },
        message: {
          i18nKey: 'store_actions.show_opened_in_desktop_message_csp'
        },
        reason: MESSAGE_SCREEN_REASON.OPEN_SESSION_IN_NATIVE_APP,
        image: MESSAGE_SCREEN_ILLUSTRATIONS.OPEN_IN_APP_CSP,
        showVonageLogo: false
      });
    } else {
      return dispatch('showGlobalMessage', {
        title: { i18nKey: 'store_actions.show_opened_in_desktop_title' },
        message: { i18nKey: 'store_actions.show_opened_in_desktop_message' },
        reason: MESSAGE_SCREEN_REASON.OPEN_SESSION_IN_NATIVE_APP,
        image: MESSAGE_SCREEN_ILLUSTRATIONS.OPEN_IN_APP,
        showVonageLogo: true
      });
    }
  },

  showGlobalMessage: ({ commit, getters, dispatch }, message) => {
    // message: {header: String, icon: String, title: String, message: String}
    dispatch('loudnessDetector/turnMuteIndicationOff');
    dispatch('loudnessDetector/turnLoudnessDetectorOff');

    commit('SET_GLOBAL_MESSAGE', message);
    if (router.currentRoute.name !== 'MessageScreen') {
      dispatch('replaceRoute', {
        name: 'MessageScreen',
        params: { reason: message.reason }
      });
    }

    // Whenever showing a global message we should stop preventing the screen from dimming/locking
    if (getters.isMobileWebMode) {
      stopPreventingMobileLock();
    }
  },

  showNetworkIssueMessage: ({ commit, dispatch }, message) => {
    dispatch('loudnessDetector/turnMuteIndicationOff');
    dispatch('loudnessDetector/turnLoudnessDetectorOff');

    commit('SET_GLOBAL_MESSAGE', message);

    if (router.currentRoute.name !== 'NetworkIssueScreen') {
      dispatch('replaceRoute', { name: 'NetworkIssueScreen' });
    }
  },

  resetGlobalMessage: ({ commit, dispatch }) => {
    if (
      [
        'MessageScreen',
        'MessageScreenTheme',
        'NetworkIssueScreen',
        'NetworkIssueScreenTheme'
      ].some((messageRoute) => router.currentRoute.name === messageRoute)
    ) {
      commit('SET_GLOBAL_MESSAGE', { title: '', message: '' });
      dispatch('replaceRoute', { name: 'Home' });
    }
  },

  setShowReportIssueModal: ({ commit }, show) => {
    commit('SET_SHOW_REPORT_ISSUE_MODAL', show);
  },

  setShowSelectLanguageModal: ({ commit }, show) => {
    commit('SET_SHOW_SELECT_LANGUAGE_MODAL', show);
  },

  setIsMaximized: ({ commit }, isMaximized) => {
    commit('SET_IS_MAXIMIZED', isMaximized);
  },

  setMicEnabled: ({ commit, state }, isEnabled) => {
    if (isElectron()) {
      if (window.Electron.notifyAudioStatusUpdate) {
        window.Electron.notifyAudioStatusUpdate(isEnabled);
      } else if (electronWebapp.sendMessage_audioStatusUpdate) {
        electronWebapp.sendMessage_audioStatusUpdate(isEnabled);
      }
    }
    if (!isEnabled && state.isMicrophonePausedSafari && window.isSafari) {
      commit('SET_IS_MICROPHONE_PAUSED_SAFARI', false);
    }
    commit('SET_MIC_ENABLED', isEnabled);
    sessionStorageService.setItem('mic_enabled', isEnabled);
  },

  setVideoEnabled: ({ commit, state }, isEnabled) => {
    if (isElectron()) {
      if (window.Electron.notifyVideoStatusUpdate) {
        window.Electron.notifyVideoStatusUpdate(isEnabled);
      } else if (electronWebapp.sendMessage_videoStatusUpdate) {
        electronWebapp.sendMessage_videoStatusUpdate(isEnabled);
      }
    }
    if (!isEnabled && state.isVideoPausedSafari && window.isSafari) {
      commit('SET_IS_VIDEO_PAUSED_SAFARI', false);
    }
    commit('SET_VIDEO_ENABLED', isEnabled);
    sessionStorageService.setItem('camera_enabled', isEnabled);
  },

  setVirtualBackground: ({ commit }, virtualBackground) => {
    commit('SET_VIRTUAL_BACKGROUND', virtualBackground);
    sessionStorageService.setItem(
      'virtual_background',
      JSON.stringify(virtualBackground)
    );
  },

  trackMeetingLaunchedAnalyticEvent({ getters }) {
    analytics.trackEvent(ANALYTICS.MEETING_LAUNCHED, {
      'Join Type': getters.isGuest ? 'Guest' : 'Application'
    });
  },

  // At the moment we don't have a distinction when the owner mutes the whole session as opposed to muting only you.
  // We are using the session and publisher mute events as a way of knowing. The cases:
  // 1. Owner mutes the whole session: we should get publisher mute event and session mute event.
  // 2. Owner mutes only me: we should get only publisher mute event.
  // We are waiting 1 second for the mute session event to arrive and if it doesn't, we assume the owner muted only me.
  // As we don't control the order which the event will be arrived - so we are gathering those events for 1 seconds,
  // and then decides which result to show
  setCurrentForceMuteEventState: (
    { commit, state, dispatch, getters },
    muteForcedType
  ) => {
    // the owner should just get notified that his mute all request was received
    if (getters.isSessionOwner) {
      if (muteForcedType === MUTE_FORCED_TYPE.SESSION_MUTE) {
        dispatch('addFlashMessage', {
          text: i18n.t('store_actions.mute_all_participants_text')
        });
      }
      return;
    }

    if (muteForcedType === MUTE_FORCED_TYPE.SESSION_MUTE) {
      commit('SET_CURRENT_FORCE_MUTE_EVENT', MUTE_FORCED_TYPE.SESSION_MUTE);
    } else if (
      // PUBLISHER_MUTE should be the result only if it's the only event
      muteForcedType === MUTE_FORCED_TYPE.PUBLISHER_MUTE &&
      state.currentForceMuteEvent === null
    ) {
      // we want to disable audio immediately when getting this event
      dispatch('setMicEnabled', false);

      commit('SET_CURRENT_FORCE_MUTE_EVENT', MUTE_FORCED_TYPE.PUBLISHER_MUTE);
    }

    // handle the result after 1 second from the first event arrival (the waiting is done by throttle function)
    dispatch('handleMuteForcedEvent');
  },

  handleMuteForcedEvent: throttle(
    ({ commit, state, dispatch, getters }) => {
      if (state.currentForceMuteEvent === MUTE_FORCED_TYPE.SESSION_MUTE) {
        if (getters.isMobileWebMode) {
          dispatch('mobile/displayToast', {
            message: i18n.t('store_actions.host_muted_all_participants_text'),
            time: LONG_TOAST_DURATION,
            type: TOAST_TYPE.WARNING
          });
        } else {
          dispatch('addFlashMessage', {
            type: 'shoutout',
            customIcon: 'Vlt-icon-microphone-mute-full',
            text: i18n.t('store_actions.host_muted_all_participants_text')
          });
        }
      } else if (
        state.currentForceMuteEvent === MUTE_FORCED_TYPE.PUBLISHER_MUTE
      ) {
        if (getters.isMobileWebMode) {
          dispatch('mobile/displayToast', {
            message: i18n.t('you_have_been_muted_modal.mobile_flash_message'),
            time: LONG_TOAST_DURATION,
            type: TOAST_TYPE.WARNING
          });
        } else {
          dispatch('addFlashMessage', {
            type: 'shoutout',
            time: 6000,
            customIcon: 'Vlt-icon-microphone-mute-full',
            title: i18n.t('you_have_been_muted_modal.title'),
            text: i18n.t('you_have_been_muted_modal.content'),
            button: {
              text: i18n.t('you_have_been_muted_modal.got_it_button'),
              actions: {
                name: 'removeSelf'
              }
            }
          });
        }
      }

      // clean previous force mute state
      commit('SET_CURRENT_FORCE_MUTE_EVENT', null);
    },
    1000,
    { leading: false }
  ),

  toggleUnsubscribedVideoStream: ({ commit }, streamId) => {
    commit('TOGGLE_UNSUBSCRIBED_VIDEO_STREAM', streamId);
  },

  initLayout: ({ dispatch, getters }) => {
    if (getters.isMobileWebMode) {
      dispatch('layout/setLayoutMode', {
        layoutMode: LAYOUT_MODE_TYPES.SPEAKER
      });
    }

    dispatch('layout/toggleSidebar', {
      skipAnimation: true,
      shouldCollapse: true
    });
  },

  initParticipantsLoadTimeAlertTimer: ({ getters }) => {
    setTimeout(() => {
      if (getters.activeParticipants.length === 0) {
        const errorMessage = `participants tab is loading for more than ${
          MAX_TIME_PARTICIPANTS_LOADING / 1000
        }sec`;
        logger.error(
          'participants-loading-time-error',
          LOG_CATEGORIES.CLIENT_LOGIC,
          {
            errorMessage
          }
        );
      }
    }, MAX_TIME_PARTICIPANTS_LOADING);
  },

  hijackScreenshare: ({ state, commit, dispatch }) => {
    const screenShareStream = state.streams.find((stream) =>
      utils.isScreenshareStream(stream)
    );

    if (!screenShareStream) {
      return dispatch('screenshare/toggleScreenshare');
    }

    const hijackSignal = {
      type: APP_SIGNALS.HIJACK_SCREEN_SHARE,
      to: screenShareStream.connection
    };
    commit('SET_DID_SEND_HIJACK_SCREENSHARE', true);
    analytics.trackEvent(ANALYTICS.SEND_HIJACK_SCREENSHARE_SIGNAL, {
      displayName: state.participants[state.myParticipantId].displayName
    });
    logger.log(
      'hijack-share-screen-button-clicked',
      LOG_CATEGORIES.UI_INTERACTION
    );
    return MeetingManager.sendSignal(hijackSignal);
  },

  handleScreenshareHijackedSignal: (
    { dispatch, rootGetters, state },
    originalEvent
  ) => {
    if (!rootGetters.isScreenShared) {
      return;
    }

    dispatch('screenshare/stopScreenshare');

    const ackHijackedSignal = {
      type: APP_SIGNALS.ACK_HIJACK_SCREEN_SHARE,
      to: originalEvent.from
    };
    MeetingManager.sendSignal(ackHijackedSignal);
    analytics.trackEvent(ANALYTICS.SEND_ACK_ON_HIJACK_SCREENSHARE_SIGNAL, {
      displayName: state.participants[state.myParticipantId].displayName
    });

    const participantId = state.streams.find(
      (stream) => stream.connectionId === originalEvent.from.connectionId
    )?.participantId;
    const displayName = state.participants[participantId]?.displayName;

    return dispatch('addFlashMessage', {
      type: 'shoutout',
      text: i18n.t('store_actions.share_screen_owner_text', { displayName })
    });
  },

  setMyScreenStreamId({ commit }, stream) {
    commit('SET_MY_SCREEN_STREAM_ID', stream);
  },

  updateOutputDevice: async ({ state }) => {
    try {
      await tokbox.setAudioOutputDevice(state.selectedSpeakerId);
    } catch (error) {
      logger.error('setAudioOutputDevice failed', LOG_CATEGORIES.TOKBOX, error);
    }
  },

  applyInitialRoomJoinSettings: ({ state, commit }) => {
    if (state.roomDetails.isInitialJoinOptionsMicrophoneState === 'on') {
      commit('settings/UPDATE_SETTINGS', {
        initialSettings: { microphoneEnabled: true }
      });
    } else if (
      state.roomDetails.isInitialJoinOptionsMicrophoneState === 'off'
    ) {
      commit('settings/UPDATE_SETTINGS', {
        initialSettings: { microphoneEnabled: false }
      });
    }
    const backgroundDisabled = sessionStorageService.getItem(
      'background_disabled'
    );
    if (backgroundDisabled) {
      commit('UPDATE_INITIAL_JOIN_CONFIG', { backgroundDisabled: true });
    }
  },

  replaceRoute: async ({ state }, { name, params = {}, query = {} }) => {
    if (state.whitelabel.shortCompanyUrl) {
      params = {
        ...params,
        themeUrl: state.whitelabel.shortCompanyUrl
      };
      name = `${name}WithThemeUrl`;
    } else if (params.themeUrl) {
      params.themeUrl = null;
    }
    try {
      await router.replace({
        name,
        params,
        query,
        replace: true
      });
    } catch (err) {
      if (err.name === 'NavigationDuplicated') {
        // We want to ignore the duplicated navigation error
        return;
      }
      throw err;
    }
  },

  setGuestUserInfo: (
    { commit },
    {
      displayName,
      participantToken = null,
      accountId = null,
      guestId = null,
      email = undefined
    }
  ) => {
    const guestDisplayName = displayName.trim();
    localStorageService.setItem('guestDisplayName', guestDisplayName);
    const names = displayName.split(' ');

    commit('SET_VBC_USER_INFO', {
      accountId,
      userType: 'Guest',
      userId: `Guest-${guestId}`,
      loginName: guestDisplayName,
      firstName: names[0],
      lastName: names.length > 1 ? names[1] : null,
      email
    });

    analytics.setAccountId(accountId);
    if (participantToken) {
      commit('SET_PARTICIPANT_TOKEN', participantToken);
    }
  },

  adobeTrack: ({ state, getters }, payload) => {
    if (getters.isAdobeAnalyticsDisabled) {
      return;
    }

    const pageName = payload.pageName;
    const userType = state.userInfo.userType;
    if (!pageName) return;
    if (userType === 'Guest') return;

    window.digData = window.digData || [];
    const accountId = state.userInfo.accountId;
    const userId = state.userInfo.userId;
    const roomToken = state.roomDetails.roomToken;
    const myParticipantId = state.myParticipantId;
    let accountStatus = '';
    let loginName = '';
    let roomType = '';
    let visitorType = '';

    if (state.userInfo.accountId) {
      visitorType = 'essentials customer';
      loginName = createHash('sha256')
        .update(state.userInfo.loginName)
        .digest('hex');
      accountStatus = 'Active';
    }

    window.digData.push({
      event: 'Page Load',
      page: {
        pageInfo: {
          pageName: 'biz:essentials app:meetings:room',
          prodDetailPage: '',
          primaryCategory: 'biz:essentials app:meetings',
          subCategory1: 'biz:essentials app:meetings',
          subCategory2: 'biz:essentials app:meetings:room',
          subCategory3: 'biz:essentials app:meetings:room',
          siteIdentifier: 'vbc meetings',
          appVersion: 'desktop app-2022',
          lob: 'biz',
          functionDept: 'essentials app',
          internalCampaign: '',
          merchandisingInternalCampaign: ''
        }
      },
      user: {
        profile: {
          profileInfo: {
            visitorType: visitorType,
            roomType: roomType,
            roomID: roomToken,
            participantId: myParticipantId,
            extensionExternalId: '',
            accountNumber: accountId,
            accountStatus: accountStatus,
            userType: userType,
            userId: userId,
            userName: loginName
          }
        }
      }
    });
  },

  updateCurrentRoomSettings: async (
    { state, dispatch },
    { joinApprovalLevel = undefined }
  ) => {
    await dispatch('_updateRoomSettings', {
      roomName: state.roomDetails.name,
      joinApprovalLevel
    });
    dispatch('setRoomDetails', {
      joinApprovalLevel
    });
  },

  updateMainRoomSettings: async (
    { dispatch },
    { joinApprovalLevel = undefined }
  ) => {
    await dispatch('_updateRoomSettings', {
      roomName: vbcGw.getCredentials().externalId,
      joinApprovalLevel
    });
  },

  _updateRoomSettings: async (
    _,
    { roomName, joinApprovalLevel = undefined }
  ) => {
    await roomService.updateRoom(roomName, vbcGw.getCredentials().externalId, {
      joinApprovalLevel
    });
  },

  updateSessionJoinApprovalLevel: async (
    { commit },
    { sessionId, joinApprovalLevel }
  ) => {
    await roomService.setSessionJoinApprovalLevel(
      vbcGw.getCredentials().externalId,
      sessionId,
      joinApprovalLevel
    );
    commit('SET_SESSION_JOIN_APPROVAL_LEVEL', joinApprovalLevel);
  },

  setShouldToggleBlurHaveFocusAnimation: (
    { commit },
    shouldToggleBlurHaveFocusAnimation
  ) => {
    commit(
      'SET_SHOULD_TOGGLE_BLUR_HAVE_FOCUS_ANIMATION',
      shouldToggleBlurHaveFocusAnimation
    );
  },

  showConnectionRestoredFlashMessage: throttle(
    ({ dispatch }) => {
      dispatch('addFlashMessage', {
        type: 'good',
        text: i18n.t(
          'store_actions.connection_has_been_restored_flash_message'
        ),
        customIcon: 'Vlt-icon-wifi-full'
      });
    },
    5 * 1000,
    { trailing: false }
  ),

  toggleHideMyStream: ({ commit, state }) => {
    commit('SET_HIDE_MY_STREAM', !state.isHideMyStreamEnabled);
  },

  restartVBC: () => {
    if (isElectron() && window.Electron.restartVBC) {
      return window.Electron.restartVBC();
    }
  },

  setShouldAppcuesBeVisible: (_, shouldBeVisible) => {
    const appcuesIframe = document.getElementsByClassName('appcues')[0];
    if (!appcuesIframe) {
      return;
    }

    const appcuesVisibility = shouldBeVisible ? 'visible' : 'hidden';
    appcuesIframe.style.visibility = appcuesVisibility;
  },

  // Rebind all video elements whenever the document regains visibility in order to
  // overcome audio/video issues that can arise in he background (e.g. playing media on other apps)
  rebindAllVideos: ({ dispatch, getters }) => {
    if (!MeetingManager.subscribers) {
      return;
    }

    Object.keys(MeetingManager.subscribers).forEach((streamId) => {
      const subscriber = MeetingManager.getSubscriberByStreamId(streamId);
      utils.rebindVideoElement(subscriber);
    });

    dispatch('rebindPublisherVideo');

    const logCategory = getters.isMobileWebMode
      ? LOG_CATEGORIES.MOBILE
      : LOG_CATEGORIES.CLIENT_LOGIC;

    logger.log('rebind-all-video-streams', logCategory, {
      message:
        'Rebinding all videos to fix black streams / missing audio streams'
    });
  },

  rebindPublisherVideo: ({ state }) => {
    if (state.isVideoEnabled) {
      utils.rebindVideoElement(MeetingManager.publisher);
    }
  }
};
