<template>
  <div class="content">
    <div class="content-div">
      <div>
        <div v-if="!isBranded" class="vonage-logo-content">
          <img
            class="vonage-logo"
            data-cy="company-logo"
            :src="entranceScreenLogoPath"
          />
        </div>
        <div class="unselectable">
          <h2 class="entrance-title" data-cy="entrance-title">
            {{ entranceTitle }}
          </h2>
          <p class="entrance-subtitle">{{ entranceSubtitle }}</p>
        </div>

        <div class="form">
          <div>
            <p class="meeting-id">
              {{ $t('entrance_screen.meeting_id_label') }}
            </p>
            <VTextfield
              ref="meetingIdTextField"
              v-model="roomToken"
              data-cy="meetingIdInput"
              icon="id-line"
              class="input-box"
              :maxLength="roomTokenMaxLength"
              :disabled="disableRoomToken"
              pattern="[0-9]+"
              :validationMessage="roomTokenErrorMessage"
              :helper="roomTokenErrorMessage"
              outlined
              autoValidate
              :placeholder="$t('entrance_screen.meeting_id_placeholder')"
              dense
              :focus="!shouldFocusDirectlyOnName && !shouldFocusDirectlyOnEmail"
              @input="updateRoomTokenErrorMessage"
              @submit="onJoin"
            />
          </div>

          <div v-if="isGuest" class="name-content">
            <p class="name">{{ $t('entrance_screen.name_label') }}</p>
            <VTextfield
              v-model="displayName"
              data-cy="nameInput"
              :placeholder="$t('entrance_screen.name_placeholder')"
              icon="user"
              class="input-box"
              :maxLength="displayNameMaxLength"
              :disabled="inProgress"
              :validationMessage="displayNameErrorMessage"
              outlined
              dense
              :focus="shouldFocusDirectlyOnName"
              @submit="onJoin"
            />
          </div>

          <transition name="fade">
            <div v-if="isGuestEmailRequired">
              <p class="name">{{ $t('entrance_screen.email_label') }}</p>
              <VTextfield
                v-model="email"
                data-cy="emailInput"
                :placeholder="$t('entrance_screen.email_placeholder')"
                icon="envelope-line"
                class="input-box"
                :maxLength="emailMaxLength"
                :disabled="inProgress"
                outlined
                dense
                :focus="shouldFocusDirectlyOnEmail"
                @submit="onJoin"
              />
            </div>
          </transition>

          <vwc-button
            :label="$t('entrance_screen.join_meeting_button')"
            layout="filled"
            data-cy="join-meeting-button"
            class="join-button"
            :class="{ csp: domainsConfigurations.isCsp }"
            connotation="cta"
            :disabled="disableJoin"
            @click="onJoin"
          />
        </div>
      </div>
    </div>

    <div
      v-if="isBranded"
      class="brand-logo-container"
      data-cy="brand-logo-container"
    >
      <img v-if="brandImageWhite" :src="brandImageWhite" class="brand-logo" />
      <div v-else class="brand-text" data-cy="brand-text">{{ brandText }}</div>
      <div
        v-if="!hidePoweredByVonage"
        class="powered-by-vonage-container"
        data-cy="powered-by-vonage"
      >
        <div class="powered-by-vonage-text">Powered by</div>
        <v-icon class="powered-by-vonage-logo" icon-name="vonage-logo" />
      </div>
    </div>

    <div
      v-else-if="shouldShowReferralProgram"
      class="referral-program-entrance-screen-img-container"
    >
      <transition name="fade">
        <div>
          <img
            v-show="imageLoaded"
            :src="imageSrc"
            class="referral-program-entrance-screen-img unselectable"
            draggable="false"
            @load="imageLoaded = true"
          />
          <div class="referral-program-message-container vvd-scheme-alternate">
            <img
              v-show="imageLoaded"
              src="/entrance-screen/referral-program-message.svg"
              class="referral-program-message unselectable"
              draggable="false"
            />
            <vwc-button
              class="read-more-btn"
              connotation="primary"
              layout="filled"
              shape="rounded"
              label="Read more"
              @click="openReferralProgramPage"
            />
            <a
              class="terms-apply"
              href="https://share.vonage.com/zone/terms?journey.campaign_id=6880158589424312383"
            >
              *Terms Apply
            </a>
          </div>
        </div>
      </transition>
    </div>

    <div v-else class="entrance-screen-img-container">
      <transition name="fade">
        <img
          v-show="imageLoaded"
          data-cy="entrance-screen-img"
          :src="imageSrc"
          class="entrance-screen-img unselectable"
          draggable="false"
          @load="imageLoaded = true"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import {
  ROOM_SERVICE_ERROR_MESSAGES,
  DISPLAY_GUEST_NAME_MAX_LENGTH,
  ROOM_TOKEN_MAX_LENGTH,
  ANALYTICS,
  EMAIL_MAX_LENGTH
} from '@/consts/global-consts';
import analytics from '@/services/analytics-service';
import * as utils from '@/helpers/meeting-helpers';
import { localStorageService } from '@/services/storage-service';
import { validate as validateEmail } from 'email-validator';

export default {
  name: 'EntranceScreen',

  data() {
    return {
      roomToken: '',
      roomTokenErrorMessage: '',
      roomTokenMaxLength: ROOM_TOKEN_MAX_LENGTH,
      displayName: '',
      displayNameErrorMessage: '',
      displayNameMaxLength: DISPLAY_GUEST_NAME_MAX_LENGTH,
      email: '',
      emailMaxLength: EMAIL_MAX_LENGTH,
      shouldFocusDirectlyOnEmail: false,
      preEnteredRoomToken: this.$store.state.roomDetails.roomToken,
      inProgress: false,
      imageSrc: '',
      isSpecialDate: utils.isSpecialDate(),
      shouldShowReferralProgram: false,
      imageLoaded: false,
      errorStyle: { color: 'white' },
      imageSrcOptions: [
        this.$store.state.domainsConfigurations.entranceScreenImageSrc
      ],
      entranceScreenLogoPath: this.$store.state.domainsConfigurations
        .entranceScreenLogoPath
    };
  },

  computed: {
    ...mapState('whitelabel', [
      'brandImageWhite',
      'brandText',
      'hidePoweredByVonage'
    ]),
    ...mapState([
      'roomDetails',
      'isBranded',
      'initialJoinConfig',
      'hasValidRoomDetails',
      'isEmailRequired'
    ]),
    ...mapGetters([
      'isGuest',
      'roomDisplayName',
      'userDisplayName',
      'domainsConfigurations'
    ]),

    shouldFocusDirectlyOnName() {
      return !!this.roomDetails.roomToken && !this.shouldFocusDirectlyOnEmail;
    },

    isGuestEmailRequired() {
      return this.isGuest && this.isEmailRequired;
    },

    disableJoin() {
      return (
        !this.isRoomTokenValid ||
        !this.isGuestNameValid ||
        (!this.isEmailValid && this.isGuestEmailRequired) ||
        this.inProgress
      );
    },

    disableRoomToken() {
      return (
        this.inProgress ||
        (this.isPreEnteredRoomTokenMode && this.hasValidRoomDetails)
      );
    },

    isRoomTokenValid() {
      return (
        utils.validateRoomToken(this.roomToken) || this.roomDetails.roomToken
      );
    },

    isGuestNameValid() {
      // at least one non-space char
      return (
        (this.displayName.length && !/^\s+$/.test(this.displayName)) ||
        !this.isGuest
      );
    },

    isEmailValid() {
      return validateEmail(this.email);
    },

    isPreEnteredRoomTokenMode() {
      return !!this.preEnteredRoomToken.length;
    },

    entranceTitle() {
      const title = this.domainsConfigurations.entranceScreenTitle;
      return this.isBranded
        ? this.$t('entrance_screen.branded_title')
        : this.$t(`entrance_screen.${title}`);
    },

    entranceSubtitle() {
      return this.roomDisplayName && this.isPreEnteredRoomTokenMode
        ? this.$t('entrance_screen.entrance_subtitle_with_room', {
            roomDisplayName: this.roomDisplayName
          })
        : this.$t('entrance_screen.entrance_subtitle');
    }
  },

  async beforeMount() {
    if (
      await utils.shouldShowReferralProgram(
        this.$store.getters.isGuest,
        this.$store.getters.isVCPRoom,
        this.domainsConfigurations.isCsp
      )
    ) {
      this.shouldShowReferralProgram = true;
      this.imageSrc = '/entrance-screen/referral-program-img-entrance.jpg';
      analytics.trackEvent(ANALYTICS.REFERRAL_PROGRAM_PAGE_OPENED);
    } else {
      this.imageSrc = this.imageSrcOptions[
        Math.floor(Math.random() * this.imageSrcOptions.length)
      ];
    }
  },

  mounted() {
    const displayName =
      localStorageService.getItem('guestDisplayName') ||
      this.initialJoinConfig.displayName;
    if (displayName) {
      this.displayName = displayName;
    }

    this.roomToken = this.preEnteredRoomToken;

    if (this.isPreEnteredRoomTokenMode && !this.hasValidRoomDetails) {
      this.showError(ROOM_SERVICE_ERROR_MESSAGES.INVALID_TOKEN);
    }
  },

  methods: {
    ...mapActions([
      'setRoomToken',
      'getRoomDetails',
      'showPreJoinScreen',
      'resetGlobalMessage',
      'initGuestMode',
      'handleRoomServiceError',
      'setGuestUserInfo'
    ]),

    async onJoin() {
      if (this.disableJoin) {
        return;
      }
      this.inProgress = true;

      try {
        await this.setRoomToken(this.roomToken);

        if (this.isGuest) {
          this.setGuestUserInfo({
            displayName: this.displayName.trim(),
            email: this.email ? this.email.trim() : undefined
          });
        }

        await this.getRoomDetails();
        // After fetching room details, display email text input for relevant users
        if (this.isGuestEmailRequired && !this.email) {
          this.inProgress = false;
          this.shouldFocusDirectlyOnEmail = true;
          return;
        }

        await this.resetGlobalMessage();

        this.showPreJoinScreen();
      } catch (error) {
        this.handleRoomServiceError({
          meetingError: error.message,
          retryCallback: this.onJoin,
          defaultCallback: this.errorDefaultCallback
        });
        if (
          error.message === ROOM_SERVICE_ERROR_MESSAGES.DIFFERENT_ACCOUNT_ROOM
        ) {
          this.preEnteredRoomToken = this.roomToken;
          this.displayName = this.userDisplayName;
        } else if (
          error.message === ROOM_SERVICE_ERROR_MESSAGES.INVALID_TOKEN
        ) {
          this.errorDefaultCallback(error.message);
        }

        this.inProgress = false;
        // TODO: focus problematic field
      }
    },

    showError(roomServiceError) {
      switch (roomServiceError) {
        case ROOM_SERVICE_ERROR_MESSAGES.INVALID_TOKEN:
          this.roomTokenErrorMessage = this.$t(
            'entrance_screen.invalid_token_error_text'
          );
          break;
        default:
          this.roomTokenErrorMessage = this.$t(
            'entrance_screen.default_error_text'
          );
      }
    },

    updateRoomTokenErrorMessage() {
      this.roomTokenErrorMessage =
        this.isRoomTokenValid || !this.roomToken.length
          ? ''
          : this.$t('entrance_screen.invalid_room_token_text');
    },

    async errorDefaultCallback(errorMessage) {
      this.preEnteredRoomToken = '';
      await this.setRoomToken('');
      this.showError(errorMessage);
    },

    openReferralProgramPage() {
      analytics.trackEvent(ANALYTICS.REFERRAL_PROGRAM_READ_MORE_BUTTON_CLICKED);
      window.location.assign(
        'https://share.vonage.com/meetings-welcome-screen-us'
      );
    }
  }
};
</script>

<style scoped>
.content {
  display: flex;
  width: 100vw;
  height: 100vh;
  background: #0d0d0d;
  justify-content: space-evenly;
  overflow-y: auto;
  overflow-x: hidden;
}

.branded .content {
  background: white;
  overflow-y: hidden;
}

:not(.branded).content .content-div {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 380px;
}

.branded .content-div {
  margin: 20vh auto auto auto;
  flex: 1;
}

.content-div .form {
  width: 400px;
}

.content-div .entrance-title {
  color: white;
  font-weight: bold;
  font-size: 29px;
  line-height: 52px;
  letter-spacing: -1.5px;
  margin-bottom: 8px;
}

.content-div .entrance-subtitle {
  color: white;
  font-size: 20px;
  line-height: 36px;
  font-weight: 500;
  margin-bottom: 16px;
  margin-top: 20px;
}

.meeting-id .name {
  font-size: 14px;
  line-height: 36px;
  font-weight: 500;
}

:not(.branded) .name {
  color: white;
}

.branded .name {
  color: #0d0d0d;
}

:not(.branded) .meeting-id {
  color: white;
}

.branded .meeting-id {
  color: #0d0d0d;
}

.branded .content-div .entrance-title {
  color: #0d0d0d;
  font-size: 30px;
  line-height: 68px;
  max-width: 318px;
}

.branded .content-div .entrance-subtitle {
  color: #0d0d0d;
  margin-top: 32px;
}

.content-div .input-box {
  margin-bottom: 16px;
  margin-top: -4px;
  display: flex;
  justify-content: flex-end;
}

.content-div vwc-button {
  margin-top: 20px;
  width: 400px;
}

.entrance-screen-img-container,
.referral-program-entrance-screen-img-container {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  flex-shrink: 1;
  background: #0d0d0d;
}

.referral-program-entrance-screen-img-container {
  position: relative;
}

.entrance-screen-img-container-new-year {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: black;
}

.entrance-screen-img,
.referral-program-entrance-screen-img {
  object-fit: cover;
}

.referral-program-entrance-screen-img {
  display: block;
  height: 100%;
}

.referral-program-message {
  width: 100%;
}

.referral-program-message-container {
  position: absolute;
  margin-left: 32px;
  top: 32px;
  width: 37.5%;
  display: flex;
}

.read-more-btn {
  position: absolute;
  top: 70%;
  left: 15%;
  width: 75%;
}

.terms-apply {
  position: absolute;
  top: 86%;
  left: 15%;
  font-size: 12px;
  text-decoration-line: underline;
  color: black;
}

.fade-enter-active {
  transition: opacity 1s ease-in-out;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter {
  opacity: 0;
}

.brand-logo-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: var(--entrance-screen-logo-bg);
}

vwc-button.csp {
  --vvd-color-cta: #6a95c4;
}

.brand-logo {
  max-width: 50%;
  max-height: 50%;
  fill: var(--entrance-screen-logo-color);
}

.brand-text {
  font-size: 90px;
  font-weight: 600;
  text-align: center;
  line-height: initial;
  color: var(--entrance-screen-logo-color);
}

.powered-by-vonage-container {
  width: 190px;
  position: fixed;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.powered-by-vonage-text {
  flex: 0.5;
  color: white;
  font-size: 16px;
}

.powered-by-vonage-logo {
  margin-left: 7px;
  fill: white;
  flex: 0.5;
}

.vonage-logo {
  fill: white;
  height: 30px;
  position: relative;
  margin-top: -40%;
}

.vonage-logo-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1000px) {
  .referral-program-entrance-screen-img-container,
  .entrance-screen-img-container,
  .brand-logo-container {
    display: none;
  }

  .branded .content-div {
    margin: unset;
  }

  .branded .content {
    overflow-y: auto;
  }
}
</style>
