var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "content-div" }, [
      _c("div", [
        !_vm.isBranded
          ? _c("div", { staticClass: "vonage-logo-content" }, [
              _c("img", {
                staticClass: "vonage-logo",
                attrs: {
                  "data-cy": "company-logo",
                  src: _vm.entranceScreenLogoPath,
                },
              }),
            ])
          : _vm._e(),
        _c("div", { staticClass: "unselectable" }, [
          _c(
            "h2",
            {
              staticClass: "entrance-title",
              attrs: { "data-cy": "entrance-title" },
            },
            [_vm._v(" " + _vm._s(_vm.entranceTitle) + " ")]
          ),
          _c("p", { staticClass: "entrance-subtitle" }, [
            _vm._v(_vm._s(_vm.entranceSubtitle)),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "form" },
          [
            _c(
              "div",
              [
                _c("p", { staticClass: "meeting-id" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("entrance_screen.meeting_id_label")) +
                      " "
                  ),
                ]),
                _c("VTextfield", {
                  ref: "meetingIdTextField",
                  staticClass: "input-box",
                  attrs: {
                    "data-cy": "meetingIdInput",
                    icon: "id-line",
                    maxLength: _vm.roomTokenMaxLength,
                    disabled: _vm.disableRoomToken,
                    pattern: "[0-9]+",
                    validationMessage: _vm.roomTokenErrorMessage,
                    helper: _vm.roomTokenErrorMessage,
                    outlined: "",
                    autoValidate: "",
                    placeholder: _vm.$t(
                      "entrance_screen.meeting_id_placeholder"
                    ),
                    dense: "",
                    focus:
                      !_vm.shouldFocusDirectlyOnName &&
                      !_vm.shouldFocusDirectlyOnEmail,
                  },
                  on: {
                    input: _vm.updateRoomTokenErrorMessage,
                    submit: _vm.onJoin,
                  },
                  model: {
                    value: _vm.roomToken,
                    callback: function ($$v) {
                      _vm.roomToken = $$v
                    },
                    expression: "roomToken",
                  },
                }),
              ],
              1
            ),
            _vm.isGuest
              ? _c(
                  "div",
                  { staticClass: "name-content" },
                  [
                    _c("p", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.$t("entrance_screen.name_label"))),
                    ]),
                    _c("VTextfield", {
                      staticClass: "input-box",
                      attrs: {
                        "data-cy": "nameInput",
                        placeholder: _vm.$t("entrance_screen.name_placeholder"),
                        icon: "user",
                        maxLength: _vm.displayNameMaxLength,
                        disabled: _vm.inProgress,
                        validationMessage: _vm.displayNameErrorMessage,
                        outlined: "",
                        dense: "",
                        focus: _vm.shouldFocusDirectlyOnName,
                      },
                      on: { submit: _vm.onJoin },
                      model: {
                        value: _vm.displayName,
                        callback: function ($$v) {
                          _vm.displayName = $$v
                        },
                        expression: "displayName",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.isGuestEmailRequired
                ? _c(
                    "div",
                    [
                      _c("p", { staticClass: "name" }, [
                        _vm._v(_vm._s(_vm.$t("entrance_screen.email_label"))),
                      ]),
                      _c("VTextfield", {
                        staticClass: "input-box",
                        attrs: {
                          "data-cy": "emailInput",
                          placeholder: _vm.$t(
                            "entrance_screen.email_placeholder"
                          ),
                          icon: "envelope-line",
                          maxLength: _vm.emailMaxLength,
                          disabled: _vm.inProgress,
                          outlined: "",
                          dense: "",
                          focus: _vm.shouldFocusDirectlyOnEmail,
                        },
                        on: { submit: _vm.onJoin },
                        model: {
                          value: _vm.email,
                          callback: function ($$v) {
                            _vm.email = $$v
                          },
                          expression: "email",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c("vwc-button", {
              staticClass: "join-button",
              class: { csp: _vm.domainsConfigurations.isCsp },
              attrs: {
                label: _vm.$t("entrance_screen.join_meeting_button"),
                layout: "filled",
                "data-cy": "join-meeting-button",
                connotation: "cta",
                disabled: _vm.disableJoin,
              },
              on: { click: _vm.onJoin },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm.isBranded
      ? _c(
          "div",
          {
            staticClass: "brand-logo-container",
            attrs: { "data-cy": "brand-logo-container" },
          },
          [
            _vm.brandImageWhite
              ? _c("img", {
                  staticClass: "brand-logo",
                  attrs: { src: _vm.brandImageWhite },
                })
              : _c(
                  "div",
                  {
                    staticClass: "brand-text",
                    attrs: { "data-cy": "brand-text" },
                  },
                  [_vm._v(_vm._s(_vm.brandText))]
                ),
            !_vm.hidePoweredByVonage
              ? _c(
                  "div",
                  {
                    staticClass: "powered-by-vonage-container",
                    attrs: { "data-cy": "powered-by-vonage" },
                  },
                  [
                    _c("div", { staticClass: "powered-by-vonage-text" }, [
                      _vm._v("Powered by"),
                    ]),
                    _c("v-icon", {
                      staticClass: "powered-by-vonage-logo",
                      attrs: { "icon-name": "vonage-logo" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        )
      : _vm.shouldShowReferralProgram
      ? _c(
          "div",
          { staticClass: "referral-program-entrance-screen-img-container" },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _c("div", [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.imageLoaded,
                      expression: "imageLoaded",
                    },
                  ],
                  staticClass:
                    "referral-program-entrance-screen-img unselectable",
                  attrs: { src: _vm.imageSrc, draggable: "false" },
                  on: {
                    load: function ($event) {
                      _vm.imageLoaded = true
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "referral-program-message-container vvd-scheme-alternate",
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.imageLoaded,
                          expression: "imageLoaded",
                        },
                      ],
                      staticClass: "referral-program-message unselectable",
                      attrs: {
                        src: "/entrance-screen/referral-program-message.svg",
                        draggable: "false",
                      },
                    }),
                    _c("vwc-button", {
                      staticClass: "read-more-btn",
                      attrs: {
                        connotation: "primary",
                        layout: "filled",
                        shape: "rounded",
                        label: "Read more",
                      },
                      on: { click: _vm.openReferralProgramPage },
                    }),
                    _c(
                      "a",
                      {
                        staticClass: "terms-apply",
                        attrs: {
                          href:
                            "https://share.vonage.com/zone/terms?journey.campaign_id=6880158589424312383",
                        },
                      },
                      [_vm._v(" *Terms Apply ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "entrance-screen-img-container" },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.imageLoaded,
                    expression: "imageLoaded",
                  },
                ],
                staticClass: "entrance-screen-img unselectable",
                attrs: {
                  "data-cy": "entrance-screen-img",
                  src: _vm.imageSrc,
                  draggable: "false",
                },
                on: {
                  load: function ($event) {
                    _vm.imageLoaded = true
                  },
                },
              }),
            ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }