var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _vm.isBranded
        ? _c("div", { staticClass: "brand-logo-container" }, [
            _vm.brandImageColored
              ? _c("img", {
                  staticClass: "brand-logo",
                  attrs: { src: _vm.brandImageColored },
                })
              : _c(
                  "div",
                  {
                    staticClass: "brand-text",
                    attrs: { "data-cy": "brand-text" },
                  },
                  [_vm._v(_vm._s(_vm.brandText))]
                ),
          ])
        : _c("v-icon", {
            staticClass: "vonage-icon",
            attrs: { iconName: "Brand-icon-vonage" },
          }),
      _c("h3", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      _c("span", { staticClass: "text" }, [
        _vm._v(_vm._s(_vm.$t("mobile_entrance_screen.text"))),
      ]),
      _c("VTextfield", {
        staticClass: "input-box",
        attrs: {
          "data-cy": "meetingIdInput",
          placeholder: _vm.$t("mobile_entrance_screen.meeting_id_text"),
          maxLength: _vm.roomTokenMaxLength,
          disabled: _vm.inProgress || _vm.isPreEnteredRoomTokenMode,
          pattern: "[0-9]+",
          validationMessage: _vm.roomTokenErrorMessage,
          helper: _vm.roomTokenErrorMessage,
          outlined: "",
          focus: "",
          autoValidate: "",
        },
        on: { input: _vm.updateRoomTokenErrorMessage, submit: _vm.onJoin },
        model: {
          value: _vm.roomToken,
          callback: function ($$v) {
            _vm.roomToken = $$v
          },
          expression: "roomToken",
        },
      }),
      _vm.isGuest && _vm.isPreEnteredRoomTokenMode
        ? _c("VTextfield", {
            staticClass: "input-box",
            attrs: {
              "data-cy": "nameInput",
              placeholder: _vm.$t("mobile_entrance_screen.name_text"),
              maxLength: _vm.displayNameMaxLength,
              disabled: _vm.inProgress,
              validationMessage: _vm.displayNameErrorMessage,
              focus:
                !!_vm.roomDetails.roomToken && !_vm.shouldFocusDirectlyOnEmail,
              outlined: "",
            },
            on: { submit: _vm.onJoin },
            model: {
              value: _vm.displayName,
              callback: function ($$v) {
                _vm.displayName = $$v
              },
              expression: "displayName",
            },
          })
        : _vm._e(),
      _vm.isPreEnteredRoomTokenMode && _vm.isGuestEmailRequired
        ? _c("VTextfield", {
            staticClass: "input-box",
            attrs: {
              "data-cy": "emailInput",
              placeholder: _vm.$t("mobile_entrance_screen.email_text"),
              maxLength: _vm.emailMaxLength,
              disabled: _vm.inProgress,
              focus: _vm.shouldFocusDirectlyOnEmail,
              outlined: "",
            },
            on: { submit: _vm.onJoin },
            model: {
              value: _vm.email,
              callback: function ($$v) {
                _vm.email = $$v
              },
              expression: "email",
            },
          })
        : _vm._e(),
      _c("vwc-button", {
        staticClass: "action-button",
        attrs: {
          label: _vm.$t("mobile_entrance_screen.join_meeting_button"),
          layout: "filled",
          "data-cy": "join-meeting-button",
          connotation: "cta",
          fullwidth: "",
          disabled: _vm.disableJoin,
        },
        on: { click: _vm.onJoin },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }