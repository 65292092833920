import {
  PUBLISHER_STATE,
  USER_MODES,
  SIDEBARS,
  VIRTUAL_BACKGROUND_TYPE,
  CONNECTION_STATUS
} from '@/consts/global-consts';
import { getDefaultCapabilities } from '@/consts/capabilities';
import domainConfiguration from '@/helpers/domain-detection';

function getInitialPublisherStats() {
  return {
    timestamp: 0,
    frameAudioPacketLoss: 0,
    frameAudioPacketsSent: 0,
    frameAudioPacketsLost: 0,
    totalAudioPacketsSent: 0,
    totalAudioPacketsLost: 0,
    numConsecutiveAudioPacketLossFrames: 0
  };
}

export default function getInitialState() {
  return {
    streams: [],
    participants: {},
    myParticipantId: '',
    userMode: USER_MODES.UNSET,
    isAboutToGetKicked: false,
    sessionId: '',
    owner: null,
    extensionLocale: '',
    offnetInviteData: null,
    participantToken: null,
    sessionJoinApprovalLevel: null,
    sessionAccountId: '',

    userInfo: {
      accountId: '',
      userId: '',
      userType: '',
      loginName: '',
      firstName: '',
      lastName: '',
      email: ''
    },

    myScreenStreamId: '',
    participantsVolumes: {},
    publisherStats: getInitialPublisherStats(),
    subscriberStats: {},
    unsubscribedVideoStreamIds: {},

    contacts: [],

    mainStreamId: '',
    pinnedStreamId: '',

    actionsInProgress: {},
    publisherState: PUBLISHER_STATE.NOT_INITIALIZED,
    isPublisherInitialized: false,
    optimizePublishers: false,
    showSubscriberStats: false,
    publisherError: null,
    streamsSubscribersStatus: {},
    keepActionsBarUp: false,
    keepParticipantOptionsMenuShown: false,

    isMaximized: false, // App window maximized (only relevant on Windows)
    isFullScreen: false, // Browser full screen
    isLocked: false,
    isMicEnabled: false,
    isVideoEnabled: false,
    minimizedMode: false,
    isNoAudioMode: false,
    virtualBackground: {
      type: VIRTUAL_BACKGROUND_TYPE.NONE,
      background: null
    },
    isHideMyStreamEnabled: false,

    isAppInitialized: false, // makes sure that when we switch between routes (eg. global-message to home) we won't init the app again
    isAppRunning: false, // equals to (isAppReady && route==='Home')
    initUserSettingsCompleted: false,
    isJoiningASession: false,
    isSessionInitialized: false,
    initialInvitees: [],
    initialJoinConfig: {},

    hasSessionEverConnected: false,
    isSessionConnected: false,
    capabilities: getDefaultCapabilities(),

    microphoneDevices: [],
    cameraDevices: [],
    speakerDevices: [],
    selectedMicrophoneId: '',
    selectedCameraId: '', // ID of the camera selected by the user in the settings UI
    currentCameraId: '', // ID of the camera that is currently being used for the publisher stream
    selectedSpeakerId: '',
    hasMicrophonePermissions: true,
    hasCameraPermissions: true,
    hasMicrophoneDevices: false,
    hasCameraDevices: false,
    isTurnOnAudioDialogVisible: false,
    isEndMeetingDialogVisible: false,
    isInviteParticipantsDialogVisible: false,
    isLockMeetingDialogVisible: false,
    participantToRemove: null,
    isHijackScreenshareDialogVisible: false,
    isMuteAllParticipantsModalVisible: false,
    isSettingsModalVisible: false,
    confirmationDialog: null,
    selectedDialInNumbers: [],

    isPTTOn: false,
    roomDetails: {
      displayName: '',
      roomToken: '',
      pinCode: '',
      domain: '',
      dialInNumbers: [],
      roomOwner: null,
      isMobileWebSupported: false,
      isAutoRecorded: false,
      isInitialJoinOptionsMicrophoneState: '',
      allowUsersToRecord: false,
      joinApprovalLevel: 'none',
      type: '',
      autoRecord: false,
      recordOnlyOwner: false,
      availableFeatures: {
        isRecordingAvailable: true,
        isChatAvailable: true,
        isWhiteboardAvailable: true,
        isLocaleSwitcherAvailable: false,
        isCaptionsAvailable: false
      }
    },

    flashMessages: [],
    meetingInfoOpened: true,
    activeSidebar: SIDEBARS.PARTICIPANTS,

    isNetworkIssues: false,
    connectionStatus: CONNECTION_STATUS.ONLINE,
    documentVisibilityState: document.visibilityState,

    isMicrophonePausedSafari: false,
    isVideoPausedSafari: false,

    tourSteps: [],
    numOfTourSteps: 0,

    isLoadingPreEntranceData: false,
    showEntranceScreen: false,
    showPreJoinScreen: false,
    showThankYouScreen: false,
    globalMessage: {
      title: '',
      message: ''
    },
    showReportIssueModal: false,
    showSelectLanguageModal: false,
    currentForceMuteEvent: null,
    isVirtualBackgroundModalVisible: false,

    infoDialog: null,
    errorDialog: null,
    isPageVisible: true,

    isAppPreInitialized: false,
    isGuestRoomDetailsFetchNeeded: true,
    isLoadingPreInitData: false,
    hasValidRoomDetails: false,
    isBranded: false,
    isEmailRequired: false,
    domainsConfigurations: domainConfiguration,
    isEmbedded: false,
    didSendHijackScreenShare: false,
    isLongPublisherInitialization: false,
    shouldToggleBlurHaveFocusAnimation: false,

    isDevicesChangedRecently: false
  };
}
